import React, { useContext, useEffect, useState, useRef } from 'react';

import Transaction from '../Transaction';
import { UserContext } from '../../context/userContext';
import endPoints from '../../utils/endPoints';

import { CircularProgress } from '@chakra-ui/react';

import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Container, Balance, History, WrapScroll, Filter, Nothing, Loading } from './styles';
import axios from 'axios';
import { useToast, Divider  } from '@chakra-ui/react';

import noTransactions from '../../assets/no-transactions.png';

type ITransaction = {
  id: number
}

const WalletBox: React.FC = () => {
  //Context
  const toast = useToast();
  const { userData } = useContext(UserContext);
  //States
  const [transactions, setTransactions] = useState<any>([]);
  const [showBalance, setShowBalance] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [showMoreButton, setShowMoreButton] = useState(true);
  const [totalItems, setTotalItems] = useState(1);
  const [time, setTime] = useState<number | null>(30);
  const [type, setType] = useState<string | null>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const weekdays = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
  const months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];
  const days = [ 7, 15, 30 ]
  const types = [{ name: 'Entrada', value: 'entrance' }, { name: 'Saída', value: 'exit' }, { name: 'Pendente', value: 'pending' }]

  const formatDate = (value: string) => {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }

  const formatDateRender = (value: string) => {
    const date = new Date(value);
    const weekday = weekdays[date.getDay()];
    const day = date.getDate();
    const month = months[date.getMonth()];
    return `${weekday}, ${day} de ${month} de ${date.getFullYear()}`;
  }

  const transactionsByDay = transactions.reduce((result : any, transaction : any) => {
    if (!result[formatDate(transaction.date_create)]) {
      result[formatDate(transaction.date_create)] = [];
    }
    result[formatDate(transaction.date_create)].push(transaction);
    return result;
  }, {});

  useEffect(() => {
    getStatement(1);
    handleScrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, time])

  useEffect(() => {
    if (totalItems === transactions.length) {
      setShowMoreButton(false);
    }
  }, [totalItems, transactions])

  const getStatement = async (pageWallet: number) => {
    setIsLoading(true);

    let url = endPoints.walletlog + `?page=${pageWallet}&itemsPerPage=10&time=${time}&movimentation_type=${type}`;

    axios.get(url).then((items) => {
      setTotalItems(items.data.total);
      if (pageWallet === 1) {
        setTransactions(items.data.data);
      } else {
        setTransactions([...transactions, ...items.data.data]);
      }
      setIsLoading(false);
    }).catch((err) => {
      toast({
        title: 'Algo deu errado',
        status: 'error',
        position: 'top',
        isClosable: true,
      })
      setIsLoading(false);
    });
  }

  const getMoreItems = () => {
    setPage(oldProps => oldProps + 1);
    getStatement(page + 1);
  }

  const handleTime = (value: number) => {
    if (value === time && time !== 30) {
      setTime(30);
      return;
    }

    setTime(value);
  }

  const handleType = (value: string) => {
    if (value === type) {
      setType(null);
      return;
    }
    
    setType(value);
  }

  const handleScrollToTop = () => {
    if (divRef.current) {
      divRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Container>
      <Balance>
        <span className="balance-text">Saldo disponível</span>
        <div className="balance-div">
          {showBalance === true ?
            <span className="balance">{userData.balance?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
            :
            <span className="hidden-balance">••••</span>
          }
          {showBalance === true ?
            <FaEyeSlash onClick={() => { setShowBalance(!showBalance) }} />
            :
            <FaEye onClick={() => { setShowBalance(!showBalance) }} />
          }
        </div>
      </Balance>
      <WrapScroll>
        <Filter>
          <h1>
            Movimentações
          </h1>
          {
            days.map(day => (
              <button 
                className={time === day ? 'on' : ''} 
                onClick={() => handleTime(day)}
                key={day}
              >
                Últimos {day} dias
              </button>
            ))
          }
          <h1>
            Lançamentos
          </h1>
          {
            types.map(obj => (
              <button 
                className={obj.value === type ? 'on' : ''} 
                onClick={() => handleType(obj.value)}
                key={obj.value}
              >
                {obj.name}
              </button>
            ))
          }
        </Filter>
        <History ref={divRef}>
          {
            transactions.length !== 0 ? (
              Object.keys(transactionsByDay).map((date_create) => (
                <div className='day-div' key={date_create}>
                  <p className='day-title'>{formatDateRender(date_create)}</p>
                  <Divider mb={7.5}/>
                  {transactionsByDay[date_create].map((transaction : ITransaction) => (
                    <Transaction key={transaction.id} data={transaction} />
                  ))}
                </div>
              ))
            ) : !isLoading ? (
              <Nothing>
                <img src={noTransactions} alt="Nenhuma transação" />
                <p> Você não possui movimentações disponíveis. </p>
              </Nothing>
            ) : (
              <>
              </>
            )
          }
          <div className="show-more-div">
            {showMoreButton && <button onClick={() => getMoreItems()} className="show-more">Ver mais</button>}
          </div>
        </History>
        <Loading className={isLoading ? 'show' : ''}>
          {
            isLoading && (
              <CircularProgress isIndeterminate color='#011032'/>
            )
          }
        </Loading>
      </WrapScroll>
    </Container>
  );
}

export default WalletBox;