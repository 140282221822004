import styled, { css }from 'styled-components';

export const Container = styled.div`
  justify-content: center;
  align-items: center;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 50px 20px;
  width: 30rem;
  min-height: 20rem;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.12);
`

export const InfoTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #080808;
  margin-bottom: 12px;
`

export const InfoText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #080808;
  text-align: center;
`

export const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const buttonStyles = css`
  margin: 5px;
  width: 110px;
  height: 35px;
  text-align: center;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  color: #011032;
`

export const SignButton = styled.button`
  ${buttonStyles}
  background: #FFD60A;
`

export const CloseButton = styled.button`
  ${buttonStyles}
  border: 1px solid #FFD60A;
`
