import styled from 'styled-components';
import { colors } from '../../../styles/tokens/color';

export const Topbar = styled.div`
  width: 100%;
  height: 70px;
  min-height: 70px;
  background-color: ${colors.white.background};
  display: none;
  align-items: center;
  padding: 0 15px;
  position: relative;

  @media (max-width: 1024px) {
    display: flex;
  }

  @media (max-width: 768px) {
    min-height: 52px;
    height: 52px;
  }

  svg {
    color: #FFF;
    font-size: 40px;
    margin-right: 30px;

    @media (max-width: 1024px) {
      color: ${colors.primary.blue};
      width: 27px;
    }

    @media (max-width: 768px) {
      width: 23px;
    }

    @media (max-width: 450px) {
      width: 20px;
    }
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 17px;
    color: #FFFFFF;
  }
`;

export const Container = styled.div`
  width: 0;
  height: 100%;
  padding: 20px 0px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background: #F7F7F7;
  box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 6px 6px 30px rgba(174, 195, 214, 0.29);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: width 0.2s;

  &.expanded {
    width: 292px;
    border-right: 2px solid #EDEFF2;
  }
`;

export const Head = styled.div`
  border-bottom: 2px solid #E6E4E4;
  padding-bottom: 20px;
  margin-bottom: 20px;

  .top-div {
    width: 100%;
    padding: 0 27px;
    display: flex;
    place-content: space-between;
    align-items: center;

    svg {
      width: unset;
      font-size: 30px;
    }

    .logo {
      display: flex;
      align-items: center;

      h1 {
        font-weight: 700;
        font-size: 15px;
        line-height: 30px;
        color: #011032;
      }

      .partners-img {
        object-fit: contain;
        width: 150px;
        margin: 0 5px 0 0;
      }
    }
  }

  .bottom-div {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 27px;
    margin-top: 30px;

    .profile-avatar {
      width: unset;
      margin-right: 20px;

      &:hover {
        cursor: pointer;
      }
    }

    p {
      color: ${colors.primary.blue};
      font-weight: 700;
      font-size: 14px;
      line-height: 25px;
    }
  }
`

export const Buttons = styled.div`
  overflow-y: auto;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  place-content: space-between;
`;

export const BottomButtons = styled.div``;

export const Button = styled.button`
  width: 100%;
  padding: 0 27px;
  display: flex;
  align-items: center;
  margin-bottom: 28px;

  span {
    color: ${colors.primary.blue};
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
  }

  svg {
    min-width: 40px;
    min-height: 40px;
    margin-right: 20px;
    border-radius: 50%;
    padding: 8px;
    font-size: 21px;
    background-color: #FFF;
    color: ${colors.primary.blue};
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  }

  .extract-content {
    width: 40px;
    height: 40px;
    min-height: 40px;
    min-width: 40px;
    margin-right: 22px;
    border-radius: 50%;
    padding: 8px;
    font-size: 21px;
    background-color: #FFF;
    color: ${colors.primary.blue};
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    justify-content: center;
    align-items: center;
    display: flex;

    @media (max-width: 1366px) {
      margin-right: 20px;
    }
  }

  .extract-icon {
    width: 17px;
    object-fit: contain;
  }

  &.active { 
    svg {
      border: 2px solid ${colors.primary.yellow};
      color: ${colors.primary.blue};
    }

    .submenu-toggle {
      border: none;
    }

    .extract-content {
      border: 2px solid ${colors.primary.yellow};
      color: ${colors.primary.blue};
    }

    .extract-icon {
      width: 15px
    }
  }

  .submenu-button {
    width: 100%;
    display: flex;
    align-items: center;
    place-content: space-between;

    //ÍCONE DE ABRIR SUBMENU
    .submenu-toggle {
      background-color: transparent;
      box-shadow: none;
      min-width: 35px;
      min-height: 35px;
      margin: 0;
      transition: transform 0.2s;
      transform: rotate(180deg);

      &.active {
        transform: rotate(360deg);
      }
    }
  }
`;

export const SubMenu = styled.div`
  height: 0;
  margin-top: -10px;
  overflow: hidden;
  transition: all 0.4s;

  &.active {
    height: 49px;
    margin-bottom: 10px;
  }

  button {
    width: 100%;
    height: 49px;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    padding-left: 86px;
    text-align: start;
    color: ${colors.primary.blue};

    &.active {
      background: rgba(9, 30, 78, 0.09);
    }
  }
`;
