import styled from 'styled-components';
import { colors } from '../../../styles/tokens/color';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 390px;
  height: fit-content;
  background-color: #FFFFFF;

  &.mb {
    padding: 10px 10px 20px 10px;
    border-radius: 5px;
  }

  .all:hover,
  .pending:hover,
  .approved:hover,
  .reproved:hover,
  .disabled:hover {
    color: #FFF;
    font-weight: bold;
    cursor: pointer;
  }

  .all:hover {
    background: ${colors.primary.blue};
  }

  .pending:hover {
    background: #EFBE24;
  }

  .approved:hover {
    background: #2E7D32;
  }

  .reproved:hover {
    background: #DE5E5E;
  }

  .disabled:hover {
    background: #AAAAAA;
  }
`

export const FlexBt = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Option = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;

`