import React, { ReactNode } from 'react';

import { FormGroup } from './styles';

import { starTemporaryLogo } from 'assets/Partners/starTemporaryLogo';

interface AuthBoxProps {
  title: string,
  content: ReactNode,
  button: ReactNode,
  auth0Button?: ReactNode,
  linksText?: ReactNode,
  secondStep?: ReactNode,
  submit: (e: any) => void
}

const AuthBox: React.FC<AuthBoxProps> = ({ title, content, linksText, button, submit }) => {
  return (
    <FormGroup onSubmit={submit}>
      {starTemporaryLogo}
      <h1>{title}</h1>
      {content}
      {button}
      {linksText}
    </FormGroup>
  );
}

export default AuthBox;