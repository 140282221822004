import React, { ReactNode, useEffect, useState } from 'react';
import { InputContainer, InputDiv } from '../styles';

interface InputProps {
    placeholder: string;
    label?: string;
    required?: boolean;
    iconContent?: ReactNode;
    value: string;
    isError:  boolean;
    change: (e: string) => void;
    type?: string;
}

const Input: React.FC<InputProps> = ({ placeholder, label, required, iconContent, value, isError, change, type }) => {

    function handleChange(value: string) {
        if (type === 'cep') {
            let formattedCep = value
              .replace(/\D/g, '') // Remove todos os caracteres não numéricos
              .slice(0, 8) // Limita o comprimento do CEP para 8 dígitos
              .replace(/(\d{5})(\d{3})/, '$1-$2'); // Adiciona o hífen entre o 5º e o 6º dígitos

            change(formattedCep);
        } else {
            change(value);
        }
    }

    const [classString, setClassString] = useState<string>('');

    const getClassname = (error : boolean, icon : ReactNode | null) => {
        let classString = ''
        if (error) {
            classString += 'error'
        }
        if (icon) {
            classString += ' icon'
        }
        setClassString(classString)
    };

    useEffect(() => {
        getClassname(isError, iconContent);
    }, [isError, iconContent]);

    return (
        <>
            <InputDiv>
                <InputContainer
                    className={classString}
                    placeholder={placeholder}
                    value={value}
                    onChange={e => handleChange(e.target.value)}
                />
                <label className={isError ? 'error' : ''}>{label}<span>{required ? ' *' : ''}</span></label>
                {
                    iconContent ? (
                        iconContent
                    ) : null
                }
            </InputDiv>
        </>
    )
}

export default Input;