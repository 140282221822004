import React, { useContext, useEffect, useState, useCallback } from "react";

import { includes } from "ramda";

import { Switch, Redirect } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { Container } from "./styles";

import Home from "../../pages/Home";
import Withdraw from "../../pages/Withdraws/Withdraw";
import Menu from "../../components/Menus/Menu";
import MobileDrawer from "../../components/Menus/MobileDrawer";
import PrivateRoute from "../../components/PrivateRoute";
import RegisterPixKey from "../../pages/Withdraws/RegisterPixKey";
import WithdrawPix from "../../pages/Withdraws/WithdrawPix";
import WithdrawPJ from "../../pages/Withdraws/WithdrawPJ";
import Scpa from "../../pages/Scpa";
import ApiStelar from "../../pages/ApiStelar";
import Awards from "../../pages/Awards";
import Award from "../../pages/Award";
import Extract from "../../pages/Extract";
import TopBar from "../../components/Menus/TopBar";
import Performance from "../../pages/Reports/Performance";
import Link from "../../pages/Link";
import AffiliatesReport from "pages/Management/AffiliatesReport";
import AffiliatesManager from "pages/Management/AffiliatesManager";
import Integrations from "pages/Integrations";
import TermsBox from "../../components/TermsBox";
import { useAuth0 } from "@auth0/auth0-react";
import { WarningContext } from "context/warningContext";
import { affiliateIds } from "../../models/affiliate-ids";

const PrivateRoutes: React.FC = () => {
  const { setShowWarning } = useContext(WarningContext);
  const { userData, getUser } = useContext(UserContext);
  const { isAuthenticated, logout } = useAuth0();
  const [showTerms, setShowTerms] = useState(false);

  const callFn = useCallback(async () => {
    await getUser();
  }, [getUser]);

  useEffect(() => {
    if (isAuthenticated && userData.id) {
      setShowTerms(!userData.user_terms);
      setShowWarning(true);
    }
  }, [isAuthenticated, setShowWarning, userData]);

  // const needToShowTerms = !userData.user_terms;

  const handleLogout = () =>
    logout({ logoutParams: { returnTo: window.location.origin } });

  const allowedManagerMenu = (smarticoId: number) => {
    const allowedIds = [
      40282,
      38762,
      100397,
      285898,
      58490,
      1,
      ...Object.values(affiliateIds).map((affId) => affId.smarticoId),
    ];
    return includes(smarticoId, allowedIds);
  };

  function routeContent() {
    return (
      <>
        <Menu />
        <MobileDrawer logout={handleLogout} />

        <Switch>
          <PrivateRoute children={<Redirect to="/home" />} exact path="/" />
          <PrivateRoute children={<Home />} exact path="/home" />
          <PrivateRoute children={<Withdraw />} exact path="/withdraw" />
          <PrivateRoute
            children={<RegisterPixKey />}
            exact
            path="/register-pix-key"
          />
          <PrivateRoute children={<WithdrawPix />} exact path="/withdraw-pix" />
          <PrivateRoute children={<WithdrawPJ />} exact path="/withdraw-pj" />
          <PrivateRoute children={<Scpa />} exact path="/scpa" />
          <PrivateRoute children={<ApiStelar />} exact path="/api-stelar" />
          <PrivateRoute children={<Extract />} exact path="/extract" />
          <PrivateRoute children={<Performance />} exact path="/performance" />
          {allowedManagerMenu(userData.smartico_id) && (
            <PrivateRoute
              children={<AffiliatesManager />}
              exact
              path="/manager/management"
            />
          )}
          {(userData.type === 3 || userData.type === 5) && (
            <PrivateRoute
              children={<AffiliatesReport />}
              exact
              path="/manager/performance"
            />
          )}
          <PrivateRoute children={<Link />} exact path="/link" />
          <PrivateRoute children={<Awards />} exact path="/awards" />
          <PrivateRoute children={<Award />} exact path="/award" />
          <PrivateRoute
            children={<Integrations />}
            exact
            path="/integrations"
          />

          <Redirect to="/" />
        </Switch>
      </>
    );
  }

  return (
    <>
      {!showTerms && <TopBar logout={handleLogout} />}
      <Container>
        {showTerms ? (
          <TermsBox
            showTerms={showTerms}
            token={userData.auth0_token}
            userId={userData.id}
          />
        ) : (
          routeContent()
        )}
      </Container>
    </>
  );
};

export default PrivateRoutes;
