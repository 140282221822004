import styled from 'styled-components';
import { colors } from 'styles/tokens/color';

export const Container = styled.div`
  padding: 19px 0px 0px 0px;
  margin-top: 5px;
  background: #FFFFFF;
  border-radius: 16px 16px 0px 0px;
  display: flex;
  box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.12);
  height: auto;
  overflow-y: auto;

  @media (max-width: 1700px) {
    overflow-x: auto;
    overflow-y: auto;
  }

  @media (max-width: 575px) {
    display: none;
  }

  table {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    tbody {
      flex: 1 1 auto;
    }

    tr {
      display: flex;
      /* padding: 0 37px; */
      padding: 0 28px;

      @media (max-width: 1400px) {
        padding: 0 20px;
      }

      @media (max-width: 1300px) {
        width: fit-content;
      }

      &.headers {
        border-bottom: none;
        margin-bottom: 31px;

        &:hover {
          background: white;
        }
      }

      &:hover {
        transition: 0.1s ease-in-out;
        background: #EEF1F2;
      }
    }

    th {
      /* font-size: 18px; */
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      text-align: start;
      color: ${colors.primary.blue};

      @media (max-width: 1280px) {
        font-size: 13px;
      }
    }

    td {
      /* padding: 9px 0px; */
      padding: 7px 0;
      
      display: flex;
      align-items: center;
      /* font-size: 14px; */
      font-size: 12px;
      font-weight: 400;
      /* line-height: 17px; */
      line-height: 15px;
      color: #716B6B;

      .status-card {
        /* width: 85px; */
        /* height: 16px; */
        width: 64px;
        height: 16px;
        border-radius: 8px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        font-style: normal;
      }
      
      .pending {
        background-color: #EFBE24;
      }

      .accepted {
        background-color: #2E7D32;
      }

      .refused {
        background-color: #DE5E5E;
      }

      @media (max-width: 1280px) {
        font-size: 12px;
      }

      &.attachment {
        place-content: center;

        svg {
          cursor: pointer;
          /* font-size: 20px; */
          font-size: 15px;
        }
      }
    }

    th, td {
      /* margin-right: 20px; */
      margin-right: 15px;

      &.date {
        /* width: 196px; */
        width: 12.5%;

        @media (max-width: 1700px) {
          min-width: 140px;
          width: 140px;
        }
      }

      &.type {
        /* width: 156px; */
        width: 10%;
        min-width: 10%;

        @media (max-width: 1700px) {
          min-width: 140px;
          width: 140px;
        }
      }

      &.bank-data {
        /* width: 247px; */
        width: 15.7%;
        min-width: 15.7%;

        @media (max-width: 1700px) {
          min-width: 150px;
          width: 150px;
        }
      }

      &.value {
        /* width: 156px; */
        width: 10%;

        @media (max-width: 1700px) {
          min-width: 110px;
          width: 110px;
        }
      }

      &.nf {
        /* width: 176px; */
        width: 11.2%;

        @media (max-width: 1700px) {
          min-width: 120px;
        }
      }

      &.status {
        /* width: 117px; */
        width: 7.5%;

        @media (max-width: 1700px) {
          min-width: 100px;
          width: 100px;
        }
      }

      &.proof {
        /* width: 356px; */
        min-width: 100px;
        width: 200px;

        svg {
          color: #F26666;
          font-size: 17px;
          position: relative;
          margin-left: 5px;
          top: -5px;
        }

        @media (max-width: 1300px) {
          width: 180px;
          min-width: 180px;
        }
      }

      &.attachment {
        text-align: center;
        /* min-width: 60px; */
        min-width: 45px;

        @media (max-width: 1400px) {
          width: 65px;
          min-width: 65px;
        }
      }
    }
  }
`;

export const ButtonDownload = styled.div`
  place-content: center;
  width: 22px;
  height: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 4px;
  color: ${colors.primary.blue};

  &:hover {
    background-color: #E0DDDD;
  }
`