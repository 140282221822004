import React, { useEffect } from 'react';

import { Container } from './styles';
import Auth0Box from 'components/Auth0Box';
import Auth0LoginButton from 'components/Auth0LoginButton';

const Auth0Login: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  return (
    <Container translate="no">
      <Auth0Box
        title='Acesse sua conta'
        button={<Auth0LoginButton />}
      />
    </Container>
  );
}

export default Auth0Login;