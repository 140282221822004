import React, { ReactNode } from 'react';
import { CircularC } from '../styles';

interface CircularProps {
    enable: boolean;
    color: string;
    content: ReactNode;
}

const Circular: React.FC<CircularProps> = ({ enable, color, content }) => {
    return (
        <CircularC color={enable ?  color : '#C4BEB6' }>
            {
                content
            }
        </CircularC>
    );
};

export default Circular;