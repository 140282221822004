import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { 
    Container, 
    CardVideo,
    Video, 
    Achievements,
    LgCircularSm,
    LgCircular,
    LgCircularLg,
    PlateTitle,
    PlateText,
    ThumbnailCanvas,
    SubContainer
} from './styles';
import HeaderComponent from '../../components/HeaderComponent';
import PlateKit from '../../components/PlateKit';
import Plate from '../../components/Plate';
import MyAchievements from '../../components/MyAchievements';

import sonImg from '../../assets/star-plate-son.png';
import siriusImg from '../../assets/star-plate-sirius.png';
import polluxImg from '../../assets/star-plate-pollux.png';
import video from '../../assets/video-plates.mov';

import { FaPlay, FaPause } from "react-icons/fa";

import axios from 'axios';
import endPoints from '../../utils/endPoints';

interface AchievementProps {
    id: number;
    type: string;
    amount: number;
    status: string;
    commission_reached: number;
}

interface AchievementResponse {
    affiliate_id: number;
    nick: string;
    achievements: AchievementProps[];
    last_week_commission: number;
}

const Awards: React.FC = () => {
    const [kit, setKit] = useState(false)
    const [son, setSon] = useState(false)
    const [sirius, setSirius] = useState(false)
    const [pollux, setPollux] = useState(false)
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [wasPlaying, setWasPlaying] = useState(false);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [thumbnailUrl, setThumbnailUrl] = useState<string>('');
    const [achievement, setAchievement] = useState<AchievementResponse>({} as AchievementResponse);
    const { achievements } = endPoints;

    const achievementTypes = [
        { name: "Kit Estrela", stateSetter: setKit },
        { name: "Placa Sol", stateSetter: setSon },
        { name: "Placa Sirius", stateSetter: setSirius },
        { name: "Placa Pollux", stateSetter: setPollux },
    ];

    const history = useHistory();

    const getAchievements = async () => {
        const url = `${achievements}`;

        axios.get(url).then(response => {
            setAchievement(response.data)
        })
    }

    useEffect(() => {
        getAchievements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!achievement.achievements) {
            return;
        }
      
        achievementTypes.forEach(({ name, stateSetter }) => {
            const achievementItem = achievement.achievements.find(a => a.type === name);
            if (achievementItem?.status !== 'NOT_ACHIEVED') {
                stateSetter(true);
            } 
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [achievement]);

    useEffect(() => {
        const captureThumbnail = () => {
            const video = videoRef.current;
            const canvas = canvasRef.current;
            if (video && canvas) {
                video.currentTime = 9.7; // set the time to capture the thumbnail
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const context = canvas.getContext('2d');
                if (context) {
                    context.drawImage(video, 0, 0, canvas.width, canvas.height);
                    const thumbnail = canvas.toDataURL('image/jpeg');
                    setThumbnailUrl(thumbnail);
                }
            }
        };
        captureThumbnail();
    }, []);

    function togglePlay() {
        if (videoRef.current) {
          if (isPlaying) {
            videoRef.current.pause();
          } else {
            if (!wasPlaying) {
                videoRef.current.currentTime = 0;
                setWasPlaying(true);
            }
            videoRef.current.play();
          }
          setIsPlaying(!isPlaying);
        }
    }

    async function goDetails(type : string) {
        history.push(`/award?plate=${type}`);
    }

    return (
        <Container>
            <SubContainer>
                <div className='header'>
                    <HeaderComponent
                        title="PREMIAÇÕES - ESTRELABET"
                    />
                </div>
                <MyAchievements 
                    kit={kit}
                    son={son}
                    sirius={sirius}
                    pollux={pollux}
                />
                <CardVideo>
                    <h1>
                        Premiações da EstrelaBet
                    </h1>
                    <div className='content'>
                        <div className='text'>
                            <p>
                                Ganhar uma das placas da EstrelaBet é o sonho de muitos Afiliados que ganham dinheiro na nossa plataforma de apostas. As placas fazem parte da premiação para Afiliados e são entregues assim que batem as metas definidos pela plataforma. 
                            </p>
                            <p>
                                Atualmente, a EstrelaBet tem como premiação o Kit EstrelaBet, a Placa Sol, a Placa Sírius e a Placa Pollux. Vale lembrar que os nome das placas são inspiradas em nomes de estrelas.
                            </p>
                            <p>
                                Os Afiliados que já alcançaram os prêmios afirmam que as placas de premiação são realmente incríveis e que ficaram perfeitas na parede de seus escritórios, quartos e salas.
                            </p>
                            <p>
                                E então, ja pensou em conquistar a sua placa? Assista ao vídeo e veja as nossas placas!  
                            </p>
                        </div>
                        <div className={isPlaying ? 'video playing' : 'video'}>
                            <Video src={video} ref={videoRef} onClick={togglePlay} poster={thumbnailUrl}/>
                            <ThumbnailCanvas ref={canvasRef} />
                            <button onClick={togglePlay}>
                                {isPlaying ? <FaPause/> : <FaPlay/>}
                            </button>
                        </div>
                    </div>    
                </CardVideo>
                <Achievements>
                    <h1>
                        Conheça o Kit Estrela e as Placas da EstrelaBet
                    </h1>
                    <div className='plates'>
                        <PlateKit 
                            size='lg'
                            content = {
                                <>
                                    <h4>
                                        Kit Estrela
                                    </h4>
                                    <p>
                                        Para ganhar o Kit Estrelabet é preciso bater a meta de <span>R$ 2.000,00</span> em comissão semanal.
                                    </p>
                                    <button onClick={() => goDetails('kit')}>
                                        EU QUERO O KIT
                                    </button>
                                </>
                            }    
                        />
                        <Plate 
                            size='lg'
                            content = {
                                <>
                                    <LgCircularSm color='#E89D58'>
                                        <img src={sonImg} alt="sonLg" className={'star'}/>
                                    </LgCircularSm>
                                    <PlateTitle color='#E89D58'>
                                        PLACA SOL
                                    </PlateTitle>
                                    <PlateText>
                                        A Placa Sol é para os Afiliados que alcançam o mínimo de <span>R$ 10.000,00</span> em comissão semanal.
                                    </PlateText>
                                    <button onClick={() => goDetails('son')}>
                                        EU QUERO A PLACA
                                    </button>
                                </>
                            }
                            color='#E89D58'
                        />
                        <Plate 
                            size='lg' 
                            content = {
                                <>
                                    <LgCircular color='#409CEF'>
                                        <LgCircularSm color='#409CEF'>
                                            <img src={siriusImg} alt="sirius" className='star'/>
                                        </LgCircularSm>
                                    </LgCircular>
                                    <PlateTitle color='#409CEF'>
                                        PLACA SÍRIUS
                                    </PlateTitle>
                                    <PlateText>
                                        A Placa Sírius é para os Afiliados que alcançam o mínimo de <span>R$ 50.000,00</span> em comissão semanal.
                                    </PlateText>
                                    <button onClick={() => goDetails('sirius')}>
                                        EU QUERO A PLACA
                                    </button>
                                </>
                            }
                            color='#409CEF'
                        />
                        <Plate 
                            size='lg'
                            content = {
                                <>
                                    <LgCircularLg color='#FFD60A'>
                                        <LgCircular color='#FFD60A'>
                                            <LgCircularSm color='#FFD60A'>
                                                <img src={polluxImg} alt="pollux" className='star'/>
                                            </LgCircularSm>
                                        </LgCircular>
                                    </LgCircularLg>
                                    <PlateTitle color='#FFD60A'>
                                        PLACA POLLUX
                                    </PlateTitle>
                                    <PlateText>
                                        A mais desejada pelos Afiliados, a Placa Pollux, corresponde a comissão semanal de, no mínimo, <span>R$ 100.000,00.</span>
                                    </PlateText>
                                    <button onClick={() => goDetails('pollux')}>
                                        EU QUERO A PLACA
                                    </button>
                                </>
                            }
                            color='#FFD60A'
                        />
                    </div>
                </Achievements>
            </SubContainer>
        </Container>
    );
}
  
export default Awards;