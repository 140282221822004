import styled from 'styled-components';

export const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #42474C;
    box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    &.disabled {
        background-color: #FFFFFF;
        border: 1px solid #F6F5F5;
    }

    &.sm {
        width: 82px;
        height: 119px;
        margin-right: 13px;

        @media (max-width: 461px) {
            margin-bottom: 10px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &.lg {
        width: 275px;
        min-width: 275px;
        height: 388px;
        margin-right: 15px;

        @media (max-width: 768px) {
            width: 206px;
            min-width: 206px;
            height: 372px;
            margin-right: 15px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &.award {
        width: 275px;
        min-width: 275px;
        height: 388px;
        margin-right: 15px;

        @media (max-width: 1170px) {
            width: 197px;
            min-width: 197px;
            height: 278px;
            padding: 0px 20px;
            margin-bottom: 15px;
            margin-right: 0;
        }
    }

    button {
        background: #FFD60A;
        border: 1px solid #FFD60A;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 17px;
        text-align: center;
        color: #011032;
        padding: 11px 0;
        width: 100%;
        margin-top: 23px;
    }
`

interface ContentProps {
    color: string;
}

export const Content = styled.div<ContentProps>`
    background: linear-gradient(206.46deg, rgba(255, 255, 255, 0.32) -66.91%, rgba(255, 255, 255, 0) 97.94%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    order: 0;
    flex-grow: 0;
    justify-content: center;
    align-items: center;

    &.sm {
        width: 76px;
        height: 115px;

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 8.5px;
            line-height: 15px;
            text-align: center;
            color: ${props => props.color};
        }
    }

    &.lg {
        width: 262px;
        min-width: 262px;
        height: 375px;
        padding: 0px 27px;
        padding-bottom: 42px;
        justify-content: end;

        @media (max-width: 768px) {
            width: 189px;
            min-width: 189px;
            height: 357px;
            padding: 0px 15px;
            padding-bottom: 39px;
        }
    }

    &.award {
        width: 262px;
        min-width: 262px;
        height: 375px;
        padding: 0px 27px;
        justify-content: center;

        @media (max-width: 1170px) {
            width: 189px;
            min-width: 189px;
            height: 270px;
            padding: 0px 20px;
        }
    }

    h2 {
        margin-top: 15px;
        
        @media (max-width: 1170px) {
            font-size: 25px;
        }
    }
`