import React, { ReactNode } from 'react';

import { Container, Content } from './styles';

interface PlateProps {
    size: string,
    content: ReactNode
    color: string,
}

const Plate: React.FC<PlateProps> = ({ size, content, color }) => {
    return (
        <Container className={size}>
            <Content className={size} color={color}>
                {
                    content
                }
            </Content>
        </Container>
    )
}

export default Plate;