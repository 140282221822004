import styled from "styled-components";
import { colors } from "../../../styles/tokens/color";

export const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  margin-right: 10px;
  padding: 16px 22px 16px 24px;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1200px) {
    margin-right: 0;
  }
  svg {
    width: 1.5em;
    vertical-align: middle;
  }

  h3 {
    margin-bottom: 10px;
  }

  .text-div {
    padding-right: 100px;
    @media (max-width: 768px) {
      padding-right: 0px;
    }
    @media (max-width: 500px) {
      margin-left: 11px;
    }
  }
  .button-div {
    justify-content: center;
    display: flex;
    margin-top: 12px;
    @media (max-width: 600px) {
      margin-top: 20px;
    }
  }
  button {
    width: 336px;
    height: 43px;
    background: #ffd60a;
    border-radius: 4px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    &:hover {
      background: ${colors.dark.yellow};
      transition: 0.2s all;
    }
  }
  .fix-svg {
    margin-bottom: 10px;
    margin-top: 10px;
    svg {
      margin-right: 4px;
    }
    p {
      align-items: center;
      margin-bottom: 0;
    }
  }
`;
