import React, { ReactNode } from 'react';
import {TextAreaContainer, TextAreaDiv } from '../styles';

interface InputProps {
    placeholder: string;
    label?: string;
    required?: boolean;
    iconContent?: ReactNode;
    isError?: boolean;
    value: string;
    change: (e: string) => void;
}

const TextArea: React.FC<InputProps> = ({ placeholder, label, required, iconContent, isError, value, change }) => {

    return (
        <TextAreaDiv>
            <TextAreaContainer
                className={isError ? 'error' : ''}
                placeholder={placeholder}
                cols={30}
                rows={5}
                value={value}
                onChange={(e) => change(e.target.value)}
            />
            <label className={isError ? 'error' : ''}>{label}<span>{required ? ' *' : ''}</span></label>
            {
                iconContent ? (
                    iconContent
                ) : null
            }
        </TextAreaDiv>
    )
}

export default TextArea;