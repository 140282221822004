import React from 'react';

import { Container, FalseInput } from './styles';

import { IoMdAttach } from 'react-icons/io';
import { toBase64 } from '../../../utils/tobase64';
import { useToast } from '@chakra-ui/react';

interface CustomFileProps {
  id: string,
  placeholder: string,
  change: (e: string) => void,
  isError?: boolean,
  msgError?: string,
  fileName?: string,
  readonly?: boolean,
  changeName: (e: any) => void
}

const CustomFile: React.FC<CustomFileProps> = ({ id, placeholder, change, isError = false, msgError, fileName, readonly = false, changeName }) => {
  const toast = useToast();

  const handleInputFile = async (file: File[]) => {
    if (file) {
      if (file[0].type === 'application/pdf') {
        if (file[0].size < 15000000) {
          changeName(file[0].name);
          const base64_file = await toBase64(file[0])
          if (typeof (base64_file) === 'string') {
            change(base64_file);
          }
        } else {
          toast({
            title: 'Erro. Tamanho do arquivo máximo permitido é de 15mb.',
            status: 'error',
            position: 'top',
            isClosable: true,
          });
        }
      } else {
        toast({
          title: 'Tipo de arquivo não suportado.',
          status: 'error',
          position: 'top',
          isClosable: true,
        });
      }
    }
  }

  return (
    <Container>
      <FalseInput className={readonly ? 'readonly-false-input' : ''}>
        {fileName ? <span className='file'>{fileName}</span> : <span className='no-file'>{placeholder}</span>}
        <IoMdAttach size={23} />
        <input
          id={id}
          onChange={(e: any) => handleInputFile(e.target.files)}
          placeholder={placeholder}
          disabled={readonly}
          type="file"
          accept="application/pdf"
          className={readonly ? 'custom-file-input-block' : 'custom-file-input'}
        />
      </FalseInput>
      {isError && <p className="error-msg">{msgError}</p>}
    </Container>
  );
}

export default CustomFile;