import styled from 'styled-components';
import { colors } from 'styles/tokens/color';

export const CancelButton = styled.button`
  margin-top: 15px;
  width: 100px;
  height: 43px;
  background: #FFF;
  border-radius: 8px;
  border: 2px solid ${colors.primary.yellow};
  color: ${colors.primary.blue};
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;

  &:hover {
    background: ${colors.primary.yellow};
  }
`;

export const ConfirmButton = styled.button`
  margin-top: 15px;
  margin-left: 10px;
  width: 100px;
  height: 43px;
  background: ${colors.primary.yellow};
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.primary.blue};

  &:hover {
      background: ${colors.dark.yellow};
  }
`;
