import styled from 'styled-components';
import { colors } from '../../../styles/tokens/color';

export const Container = styled.div`
  width: 100%;
  height: 75px;
  background-color: ${colors.white.icon};
  border-bottom: 2px solid #EDEFF2;
  display: flex;
  padding: 0px 14.5px;
  justify-content: space-between;
  align-items: center;
  z-index: -1;

  @media (max-width: 1024px) {
    display: none;
  }

  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 19.2px;
    line-height: 24px;
    margin-top: 11px;
    color: #011032;
  }

  .partners-img {
    object-fit: contain;
    width: 250px;
    margin-right: 8px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: flex-start;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: -2px;

  .profile-avatar {
    &:hover {
      cursor: pointer;
    }

    margin: 0 -5px 0 20px;
  }

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  place-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: white;
  margin-left: 12px;

  svg {
    color: ${colors.primary.blue}
  }

  &:hover { 
    background-color: ${colors.primary.blue};

    svg {
      color: ${colors.white.background}
    }
  }
`
