import React, { ReactNode } from 'react';

import { FormGroup } from './styles';

interface WithdrawBoxFormProps {
  title: string,
  textContent: ReactNode,
  content: ReactNode,
  textButton: string,
  submit: (e: any) => void,
  block?: boolean
}

const WithdrawBoxForm: React.FC<WithdrawBoxFormProps> = ({ title, content, textButton, submit, textContent, block }) => {
  return (
    <FormGroup onSubmit={submit} >
      <h1 className={ block ? 'block' : '' } >{title}</h1>
      {textContent}
      {content}
      <button className={ block ? 'block-button' : 'submit-button' } type="submit" disabled={ block ? true : false } >{textButton}</button>
    </FormGroup>
  );
}

export default WithdrawBoxForm;