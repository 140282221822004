import { ReactNode } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

interface PrivateRouteProps extends RouteProps {
  children: ReactNode
  path: string
}

const PrivateRoute = ({ children, path, ...rest }: PrivateRouteProps) => {
  const { isAuthenticated, isLoading } = useAuth0();

  return (
    <>
      {!isLoading &&
        <Route
          {...rest}
          render={() => {
            return isAuthenticated ? (
              <>
                {children}
              </>
            ) : (
              <Redirect to="/login" />
            );
          }}
        />
      }
    </>
  );
};

export default PrivateRoute;