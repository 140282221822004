import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../../context/userContext';

// Components
import WithdrawBoxForm from '../../../components/Withdraws/WithdrawBoxForm';
import WithdrawBoxInfo from '../../../components/Withdraws/WithdrawBoxInfo';
import HeaderComponent from '../../../components/HeaderComponent';

//Úteis
import {withdrawPixSchema} from '../../../utils/validateSchemas';
import endPoints from '../../../utils/endPoints';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import {useToast} from '@chakra-ui/react';

// Estilização
import {Container, SubContainer} from './styles';
import LoadingScreen from '../../../components/LoadingScreen';
import {nfIcon} from '../../../assets/nfIcon';

// Mixpanel
import mixPanelTrack from '../../../mixpanel';
import CustomInput from "../../../components/Inputs/CustomInput";

// Interfaces
interface IOptions {
  text: string;
  value: string;
}

const WithdrawPix: React.FC = () => {
  // Úteis
  const history = useHistory();
  const toast = useToast();
  const { affiliate_accounts, withdrawal, users } = endPoints;

  // UseStates
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<IOptions[]>([]);
  const [pixKey, setPixKey] = useState('');
  const [pixKeyError, setPixKeyError] = useState(false);
  const [pixKeyMsgError, setPixKeyMsgError] = useState('');

  const { userData } = useContext(UserContext);

  //UseEffects
  useEffect(() => { setPixKeyError(false) }, [pixKey]);

  useEffect(() => {
    if (userData.person_type) {
      history.push('/withdraw');
    }
  }, [userData]);

  useEffect(() => {
    setPixKey(userData.cpf);
    getBalance();
  }, [userData]);

  const getPixKeys = async () => {
    axios.get(affiliate_accounts).then((response) => {
      mapOptions(response.data.data);
    }).catch((e) => {
      toast({
        title: 'Erro ao buscar chave pix',
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    });
  }

  const getBalance = async () => {
    const url = `${users}/user`;

    axios.get(url).then((response) => {
      setBalance(response.data.balance);
    }).catch((e) => {
      toast({
        title: 'Erro ao buscar saldo',
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    })
  };

  const createWithdrawRequest = async () => {
    const customVal = customValidation();
    if (!customVal) {
      return;
    }

    setLoading(true);
    
    axios.post(withdrawal, { value: balance, account: pixKey, id_affiliate: userData.id }).then(() => {
      // Zerar values
      toast({
        title: 'Solicitação de saque realizada com sucesso!',
        status: 'success',
        position: 'top',
        isClosable: true,
      });
      mixPanelTrack('Saque - Solicitação de Saque', { type: 'Pix', user_smartico: userData.smartico_id, value: balance });
      setLoading(false);
      getBalance();
      setPixKey('');
    }).catch((e) => {
      toast({
        title: e.response.data.message,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
      setLoading(false);
    });
  }

  // Methods
  const mapOptions = (data: any) => {
    let options: IOptions[];

    options = data.map((item: any) => {
      return { id: item.id, text: item.pix_key, value: item.id };
    });

    setOptions(options);
  }

  const submitForm = async (e: any) => {
    e.preventDefault();

    if (await validationForm()) {
      createWithdrawRequest();
    }
  }

  const validationForm = async () => {
    const withdrawPixData = { pixKey };
    let errors: any = [];

    await withdrawPixSchema.validate(withdrawPixData, { abortEarly: false }).catch((err) => {
      err.inner.forEach((e: any) => {
        errors.push({ path: e.path, message: e.message });
      });
      return err;
    });

    const isValid = await withdrawPixSchema.isValid(withdrawPixData);

    if (isValid === false) {
      errors.forEach((error: any) => {
        if (error.path === 'pixKey') {
          setPixKeyError(true);
          setPixKeyMsgError(error.message);
        }
      })
      return false;
    } else {
      return true;
    }
  }

  const customValidation = () => {
    if (userData.person_type) {
      toast({
        title: 'Você deve realizar a requisição de saque por envio de nota fiscal (PJ)',
        status: 'error',
        position: 'top',
        isClosable: true,
      })
      return false;
    } else if (balance === 0) {
      toast({
        title: 'Você não possui saldo para realizar a requisição de saque',
        status: 'error',
        position: 'top',
        isClosable: true,
      })
      return false;
    } else if (balance > 49990) {
      toast({
        title: 'Saldo acima de R$ 4.999,00, será necessário realizar Saque NF',
        status: 'error',
        position: 'top',
        isClosable: true,
      })
      return false;
    } else if (userData.cpf != pixKey) {
    toast({
      title: 'Saque só pode ser realizado para a conta do titular',
      status: 'error',
      position: 'top',
      isClosable: true,
    })
    return false;
  }else {
      return true;
    }
  }

  const goPage = (page: string) => {
    history.push(page);
    mixPanelTrack('Saque - PIX', { option: 'register-pix-key', user_smartico: userData.smartico_id });
  }

  return (
    <Container translate="no">
      <LoadingScreen show={loading} />
      <SubContainer>
        <div className='header'>
          <HeaderComponent
            title="SAQUE PIX"
            buttons={
              <>

              </>
            }
          />
        </div>
        <div className='content'>
          <WithdrawBoxForm
            title='Solicitação de Saque'
            textContent={
              <h2>
                Saldo total do saque: {balance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </h2>
            }
            submit={submitForm}
            content={
              <>
                { userData.cpf && <CustomInput readonly={true} change={() => {}} placeholder="Chave PIX" type="text" isError={pixKeyError} msgError={pixKeyMsgError} value={pixKey} /> }
              </>
            }
            textButton='Confirmar Solicitação'
          />
          <div className='conditional-display'>
            <WithdrawBoxInfo
              title="Regras de Saque PIX"
              content={
                <>
                  <p><span className='point'>•</span> Limite de
                    transação: <b><span>Mínimo BRL: 1,00</span>, <span>Máximo BRL: 4.999,00</span></b>
                  </p>
                  <p><span className='point'>•</span> Permitido apenas <b>um
                    saque por dia.</b></p>
                  <p><span className='point'>•</span> Permitido apenas saques
                    para a conta do titular.</p>
                  <p><span className='point'>•</span> Só é permitido CPF como chave PIX.</p>
                </>
              }
            />
            <WithdrawBoxInfo
                title="Saldo Acima de R$ 4.999,00"
              content={
                <>
                  <p><span className='point'>•</span> Para Saldo acima de R$ 4.999,00, será necessário realizar <b>Saque NF</b>.</p>
                  <div className='center'>
                    <button className='btn-pj'
                      onClick={() => history.push('/withdraw-pj')}
                    >
                      {nfIcon}
                      <span>Saque NF</span>
                    </button>
                  </div>
                </>
              } />
          </div>
        </div>
      </SubContainer>
    </Container>
  );
}

export default WithdrawPix;