import styled from 'styled-components'

interface BarProps {
    width: string;
    margin?: string;
    color?: string;
}

export const Container = styled.div`
    &.web {
        @media (max-width: 768px) {
            display: none;
        }
    }

    &.mobile {
        display: none;

        @media (max-width: 768px) {
            display: block;

            background: #FFFFFF;
            box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 6px 6px 30px rgba(174, 195, 214, 0.29);
            padding: 20px 20px 30px 20px;
            margin-left: 6px;
            margin-top: 16px;
            border-radius: 14px;
        }
    }

    .web-title-result {
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
            display: none;
        }
    }

    .mobile-title-result {
        display: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;

        @media (max-width: 768px) {
            display: block;
        }
    }

    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        color: #011032;
        margin-bottom: 24px;
        text-align: center;
    }
`

interface StatusProps {
    color: string;
}

export const StatusContainer = styled.div<StatusProps>`
    display: flex;

    .status-content {
        height: 18.75px;
        background: ${props => props.color};
        border-radius: 6px;
        padding: 0 8px;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-left: 5px;

        h3 {
            color: #FFFFFF;
            font-weight: 500;
        }
    }
`

export const Order = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 15px;

    h1 {
        font-size: 15px;

        @media (max-width: 768px) {
            margin-bottom: 35px;
        }

        &.failed {
            margin-bottom: 6px;
            line-height: 20px;

            span {
                font-weight: 400;
            }
        }
    }

    button {
        width: 340px;
        height: 43px;
        background: #FFD60A;
        border-radius: 4px;
        box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 1px 4px 6px rgba(180, 195, 214, 0.7);

        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        color: #011032;
        margin-top: 5px;

        &:hover {
            background: #f5cd07;
        }

        &.disabled {
            background: #C1C1C1;
            box-shadow: none;

            &:hover {
                background: #C1C1C1;
            }
        }

        @media (max-width: 500px) {
            width: 90%;
        }
    }

    .award {
        width: 260px;

        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }

    .awardHit {
        width: 192px;
        margin-bottom: 4px;

        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }

    .conquered {
        width: 260px;
        margin-bottom: 8px;
    }
`

export const Result = styled.div`
    background: #F7F7F7;
    border-radius: 16px;
    display: grid;
    padding: 15px 19px 10px 19px;
    margin-top: 17px;

    h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
    }

    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 11.5px;
        line-height: 18px;
        margin-bottom: 5px;

        @media (max-width: 768px) {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`

export const ResultBar = styled.div<BarProps>`
    width: 100%;
    margin-top: 4px;

    p {
        width: ${props => props.width};
        min-width: fit-content;
        padding-bottom: 10px;
        font-size: 10px;
        line-height: 15px;
        color: ${props => props.color};
        text-align: end;
        padding-right: 10px;
    }

    .goal {
        color: #011032;
        width: 100%;
        min-width: fit-content;
        padding-bottom: 0;
    }

    img {
        width: 17px;
        max-width: 17px;
        margin-top: -18px;
        margin-left: ${props => props.margin};
    }
`

export const Bar = styled.div`
    width: 100%;
    height: 7px;
    margin-top: -4px;
    background: #C6C6C6;
    border-radius: 31px;
`

export const FilledBar = styled.div<BarProps>`
    width: ${props => props.width};
    height: 7px;
    margin-top: -7px;
    background: ${props => props.color};
    border-radius: 31px;
`
