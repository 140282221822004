import styled from 'styled-components';
import { colors } from '../../styles/tokens/color';

export const Container = styled.div`
  display: flex;
  width: calc(100vw - 252px);
  justify-content: center;
  overflow-y: auto;

  @media (max-width: 1024px) {
    width: 100%;
  }

  .title-home {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 12px;
    color: ${colors.primary.blue};

    @media (max-width: 810px) {
      display: none;
    }
  }

  .btn-home {
    display: none;
    border: 1px solid #000000;
    border-radius: 5px;
    width: 100px;
    height: 35px;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    box-shadow: none;

    @media (max-width: 810px) {
      display: flex;
    }
  }

  .btn-hidden {
    display: none;
  }
`;

export const SubContainer = styled.div`
  max-width: 1320px;
  width: 100%;
  padding: 22px 14px 0px 14px;

  .header {
    @media (max-width: 1024px) {
      margin: -22px -14px 0px -14px;
    }
  }
`;

interface IShowProps {
  show: boolean;
}

export const Statistics = styled.div<IShowProps>`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;

  @media (max-width: 810px) {
    padding: 0 15px;
    margin-left: 0;
    margin-top: 20px;
    position: absolute;
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: opacity 0.8s ease-in-out;
    height: ${(props) => (props.show ? '100vh' : '')}
  }
`

export const Wallet = styled.div`
  display: flex;
  flex: 1 1;
  height: fit-content;
  margin-bottom: 50px;

  @media (max-width: 1024px) {
    flex: unset;
  }

  @media (max-width: 810px) {
    flex-direction: column;
    align-items: center;
  }
`

export const WrapContent = styled.div`
  display: flex;
  flex: 1 1;
`

export const ExtractContent = styled.div<IShowProps>`
  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    width: calc(100% - 40px);
  }

  @media (max-width: 810px) {
    margin: 10px 0px;
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: opacity 0.8s ease-in-out;
    z-index: ${(props) => (props.show ? 1 : 0)}
  }
`

export const Card = styled.div`
  width: 242px;
  height: 120px;
  box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 6px 6px 30px rgba(174, 195, 214, 0.29);
  border-radius: 16px;
  background: #FFFFFF;
  padding: 17px 12px 17px 17px;

  @media (max-width: 1150px) {
    width: 190px;
  }

  @media (max-width: 810px) {
    width: 100%;
    height: fit-content;
    padding-bottom: 10px;
  }

  @media (max-width: 500px) {
    padding: 15px;
    width: 100%;
  }

  &:last-child {
    margin-left: 10px;

    @media (max-width: 810px) {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 21px;
    line-height: 29px;
    color: #011032;

    @media (max-width: 1150px) {
      font-size: 16px;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #011032;
  }

  p {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 5px;
    margin-top: 4px;
    color: #BABCBF;
  }
`

export const CardHeader = styled.div`
  display: flex;

  @media (max-width: 810px) {
    display: grid;
  }

  @media (max-width: 475px) {
    display: block;
  }
`

export const Divider = styled.div`
  width: 90px;
  height: 0px;
  border-bottom: 1px solid #FDD528;

  @media (max-width: 810px) {
    width: 100px;
  }
`

export const MoneyInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 14px;
`

export const CircleGreen = styled.div`
  background: #41BE06;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  @media (max-width: 1150px) {
    width: 28px;
    height: 28px;
  }

  svg {
    color: #FFFFFF;
    font-size: 19px;
  }
`

export const CircleRed = styled.div`
  background: #BE0606;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  @media (max-width: 1150px) {
    width: 28px;
    height: 28px;
  }

  svg {
    color: #FFFFFF;
    font-size: 19px;
  }
`