import React, { useEffect, useState } from 'react';

import { buildWalletlog } from '../../utils/buildWalletlog';
import { monthByNumber } from '../../utils/monthByNumber';

import { Container, Checkbox, Content } from './styles';
import { BsCheck, BsClock, BsX } from "react-icons/bs";
import { BiChevronDown } from "react-icons/bi";

interface TransactionProps {
  data: any
}

interface InfoTransaction {
  title: string,
  subtitle: string,
  colorText: string,
  colorIcon: string,
  status: number
}

const Transaction: React.FC<TransactionProps> = ({ data }) => {
  const [expanded, setExpanded] = useState(false);
  const [colorText, setColorText] = useState('');
  const [colorIcon, setColorIcon] = useState('');
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [value, setValue] = useState('');
  const [status, setStatus] = useState(1);
  const [cpa, setCpa] = useState(0);
  const [revShare, setRevShare] = useState(0);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const [commission, setCommission] = useState(0);

  useEffect(() => {
    let info: InfoTransaction = buildWalletlog(data) || {} as InfoTransaction;
    setColorText(info.colorText);
    setTitle(info.title);
    setSubTitle(info.subtitle);
    setColorIcon(info.colorIcon);
    setStatus(info.status);
    getSign(info.colorText);
    haveCommission();
    // eslint-disable-next-line
  }, []);

  const getSign = (color: string) => {
    let text = '';
    let tValue = Math.abs(data.value);
    if (color === 'green' && tValue !== 0) {
      text = '+' + tValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    } else if (color === 'red' && tValue !== 0) {
      text = '-' + tValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    } else {
      text = tValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }
    setValue(text);
  }

  const haveCommission = () => {
    if ((data.cpa !== null && data.rev_share !== null) && (data.cpa !== undefined && data.rev_share !== undefined) && (data.cpa !== 0 && data.rev_share !== 0)) {
      setShowExpandButton(true);
      setCpa(data.cpa);
      setRevShare(data.rev_share);
      setCommission(data.cpa + data.rev_share);
    }
  }

  const convertData = (date: Date) => {
    let newDate = new Date(date);
    let convertedDate = newDate.toLocaleDateString().split('/')
    const day = convertedDate[0];
    const month = monthByNumber(convertedDate[1]);

    return `${day} ${month}`
  }

  const getMoney = (value: number) => {
    return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  return (
    <Container className={expanded === true ? 'expanded' : ''}>
      <div className="displayed-content">
        <Checkbox className={colorIcon}>
          <div className={`dark-background ${colorIcon}`}>
            {status === 0 && <BsX />}
            {status === 1 && <BsCheck />}
            {status === 2 && <BsClock className="clock" />}
          </div>
        </Checkbox>
        <Content>
          <div className="transaction-info">
            <h1>{title}</h1>
            <p>{subTitle}</p>
            <p>
              <span className={colorText}>
                {value}
              </span>
            </p>
          </div>
          <div className="transaction-date">
            <span>{convertData(data.date_create)}</span>
            {showExpandButton && <BiChevronDown onClick={() => setExpanded(!expanded)} />}
          </div>
        </Content>
      </div>
      <div className="hidden-content">
        <div className="content">
          <h2>CPA</h2>
          <span>{isNaN(cpa) ? ' 0,00' : getMoney(cpa)}</span>
        </div>
        <div className="content">
          <h2>RevShare</h2>
          <span>{isNaN(revShare) ? ' 0,00' : getMoney(revShare)}</span>
        </div>
        <div className="content">
          <h2>Comissão</h2>
          <span>{isNaN(commission) ? ' 0,00' : getMoney(commission)}</span>
        </div>
      </div>
    </Container>
  );
}

export default Transaction;