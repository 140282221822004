import styled from 'styled-components';

export const Container = styled.div`
    max-width: 100%;
    width: 100%;
    margin-top: 10px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 6px 6px 30px rgba(174, 195, 214, 0.29);
    border-radius: 16px;
    padding: 22px 30px 22px 17px;

    @media (max-width: 810px) {
        width: 100%;
    }

    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 14px;
        color: #011032;

        @media (max-width: 800px) {
           padding: 0 10px;
        }
    }

    .chart {
        width: 100%;
        max-width: 100%;
        margin-left: 13px;
        margin-top: 37px;

        @media (max-width: 800px) {
            margin-left: 0;
            margin-top: 15px;
        }
    }
`;