import styled from "styled-components";
import { colors } from "../../../styles/tokens/color";

export const Container = styled.button`
  width: 100%;
  padding: 0px 14px;
  display: flex;
  align-items: center;
  margin-bottom: 3px;

  @media (max-width: 1024px) {
    margin-bottom: 28px;
    padding: 0 27px;
  }

  &.active {
    svg {
      border: 2px solid ${colors.primary.yellow};
      color: ${colors.primary.blue};
    }

    .submenu-toggle {
      border: none;
    }

    span {
      font-weight: 700;
    }

    .extract-content {
      border: 2px solid ${colors.primary.yellow};
      color: ${colors.primary.blue};
    }

    .extract-icon {
      width: 15px;
    }
  }

  span {
    color: ${colors.primary.blue};
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  svg {
    min-width: 42px;
    min-height: 42px;
    margin-right: 13px;
    border-radius: 50%;
    padding: 8px;
    font-size: 21px;
    background-color: #fff;
    color: ${colors.primary.blue};
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  }

  .extract-content {
    min-width: 42px;
    min-height: 42px;
    margin-right: 13px;
    border-radius: 50%;
    padding: 8px;
    font-size: 21px;
    background-color: #fff;
    color: ${colors.primary.blue};
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .extract-icon {
    width: 17px;
    object-fit: contain;
  }

  .submenu-button {
    width: 100%;
    display: flex;
    align-items: center;
    place-content: space-between;

    //ÍCONE DE ABRIR SUBMENU
    .submenu-toggle {
      background-color: transparent;
      box-shadow: none;
      padding: 0;
      min-width: 13px;
      min-height: 13px;
      margin: 0;
      transition: transform 0.2s;
      transform: rotate(180deg);

      &.active {
        transform: rotate(360deg);
      }
    }
  }
`;

export const SubContainer = styled.div`
  height: 0;
  overflow: hidden;
  transition: all 0.4s;

  &.active {
    height: 49px;

    &:last-child {
      margin-bottom: 3px;

      @media (max-width: 1024px) {
        margin-bottom: 28px;
      }
    }
  }

  button {
    width: 100%;
    height: 49px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    padding-left: 56px;
    text-align: start;
    color: ${colors.primary.blue};
    margin-left: 14px;

    &.active {
      background: rgba(9, 30, 78, 0.09);
      font-weight: 700;
      margin-bottom: 20px;
    }
  } 
`