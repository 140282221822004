import React, { ReactNode, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import { CancelButton, ConfirmButton } from './styles';

interface ConfirmationModalProps {
  isOpen: boolean;
  title: string;
  description: ReactNode;
  onAccept: () => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = ({
  isOpen,
  title,
  description,
  onAccept,
  onCancel
}) => {
  const cancelRef = useRef<HTMLInputElement>(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            {description}
          </AlertDialogBody>
          <AlertDialogFooter>
            <CancelButton onClick={onCancel}>
              Cancel
            </CancelButton>
            <ConfirmButton onClick={onAccept}>
              Confirmar
            </ConfirmButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default ConfirmationModal;