import React from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const Interceptors: React.FC = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const scope = process.env.REACT_APP_AUTH0_SCOPE;

  const addAccessTokenToHeaders = async (config: any) => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: audience,
          scope: scope,
        },
      });

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  axios.interceptors.request.use(
    (config) => isAuthenticated ? addAccessTokenToHeaders(config) : config,
    (err) => Promise.reject(err)
  );

  return <></>;
}

export default Interceptors;