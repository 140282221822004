import React, { useState, useEffect } from 'react';
import { PhoneDiv } from '../styles';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input'
import phoneImg from '../../../assets/phone.png';

interface PhoneInputProps {
    placeholder: string;
    label?: string;
    required?: boolean;
    isError: boolean;
    setValueI: (value: string) => void;
}

const PhoneCustomInput: React.FC<PhoneInputProps> = ({ placeholder, label, required, isError, setValueI }) => {

    const [value, setValue] = useState('')
    const [country, setCountry] = useState('+55')

    const handleCountry = (value : any) => {
        if (value !== undefined) {
            setCountry(`+${getCountryCallingCode(value)}`)
        }
    }

    useEffect (() => {
        setValueI(value)
    }, [value, setValueI])

    return (
        <PhoneDiv isError={isError}>
            <PhoneInput
                placeholder={placeholder}
                value={value}
                onChange={setValue}
                defaultCountry="BR"
                onCountryChange={country => handleCountry(country)}
                className={isError ? 'error' : ''}
            />
            <label className={isError ? 'error' : ''}>{label}<span>{required ? ' *' : ''}</span></label>
            <p className='country'>{country}</p>
            <img src={phoneImg} alt='phone' className='phone'/>
        </PhoneDiv>
    )
}

export default PhoneCustomInput;