import styled from 'styled-components';

export const Container = styled.div`
  height: 70px;
  background: #F7F7F7;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: 0.2s;

  @media (max-width: 375px) {
    padding: 5px 10px;
  }

  .displayed-content {
    display: flex;
  }

  .hidden-content {
    margin-top: 20px;
    display: flex;
    place-content: center;

    .content {
      margin: 0 12px;

      @media (max-width: 375px) {
        margin: 0 6px;
      }

      h2 {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #000000;

        @media (max-width: 475px) {
          font-size: 12px;
        }
      }

      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #5D5C5D;

        @media (max-width: 475px) {
          font-size: 10px;
        }
      }
    }
  }

  &.expanded {
    height: 160px;

    .transaction-date {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

export const Checkbox = styled.div`
  min-width: 33px;
  min-height: 33px;
  max-height: 33px;
  margin-right: 14px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  place-content: center;

  @media (max-width: 375px) {
    min-width: 35px;
    min-height: 35px;
    max-height: 35px;
  }

  &.green {
    background: #C8E6C9;
  }

  &.yellow {
    background: #FFF9C4;
  }

  &.red {
    background: #FFE0E0;
  }

  .dark-background {
    min-width: 15px;
    min-height: 15px;
    max-height: 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    place-content: center;

    @media (max-width: 375px) {
      min-width: 18px;
      min-height: 18px;
      max-height: 18px;
    }


    &.green {
      background: #41BE06;
    }

    &.yellow {
      background: #EFBE24;
    }

    &.red {
      background: #BE0606;
    }

    svg {
      font-size: 15px;
      color: #FFF;

      &.clock {
        font-size: 9px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1 0 auto;
  place-content: space-between;
  position: relative;

  .transaction-info {
    @media (max-width: 375px) {
      width: 80%;
    }

    h1 {
      font-weight: 700;
      font-size: 13px;
      line-height: 14px;
      color: #000;
    }

    p {
      margin-top: 4px;
      font-weight: 400;
      font-size: 10px;
      line-height: 11px;
      color: #5D5C5D;
    }

    span {
      font-weight: 700;
      font-size: 10px;
      line-height: 15px;
      
     &.red {
      color: #BE0606;
     }

     &.green {
      color: #41BE06;
     }

     &.black {
      color: #747574;
     }
    }
  }

  .transaction-date {
    display: flex;
    flex-direction: column;
    place-content: space-between;
    align-items: center;

    @media (max-width: 375px) {
      position: absolute;
      right: 0;
      top: 4px;

      span {
        margin-bottom: 20px;
      }
    }

    span {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #5D5C5D;
    }

    svg {
      font-size: 36px;
      color: #8E73DC;
      transition: all 0.5s;
      cursor: pointer;

      @media (max-width: 375px) {
        font-size: 30px;
      }
    }
  }
`;
