import React, { useState } from 'react';

import { Container, Title, Number, Divider, Infos, Group, Info, Text, Point } from './styles';
import { BsArrowDownCircle, BsArrowUpCircle } from 'react-icons/bs';
import { Tooltip } from '@chakra-ui/react'
import { AiOutlineInfoCircle } from 'react-icons/ai'

interface SimpleCardProps {
  title: string;
  number: number;
  info_number: number;
  tooltipText?: string;
  percentage?: string;
  type?: string;
  lastText: string;
  isSecond?: boolean;
}

const SimpleCard: React.FC<SimpleCardProps> = ({ title, number, info_number, percentage, tooltipText, lastText, isSecond }) => {

  const getMoney = (value : number) => {
    if (title === 'RevShare') {
      return value.toLocaleString('pt-BR', { currency: 'BRL' })
    }
    return value
  }

  const [tool, setTool] = useState(false);

  return (
    <Container isSecond={isSecond}>
      <Title>
        { title }
        <Tooltip
          isOpen={tool}
          hasArrow
          placement='top-start'
          bg="#011032"
          px="12px"
          mb="2px"
          ml="-7px"
          mr='-7px'
          borderRadius='4px'
          maxW='150px'
          label={
            <p
              style={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight: '400', fontSize: '10px', lineHeight: '16px', textAlign: 'center' }}
            >
              { tooltipText }
            </p>
          }
        >
          <span onClick={() => setTool(!tool)} onMouseEnter={() => setTool(true)} onMouseLeave={() => setTool(false)}><AiOutlineInfoCircle color='#011032' size={15} /></span>
        </Tooltip>
      </Title>
      <Number>
        { getMoney(number) }
      </Number>
      <Divider />
      <Infos>
        <Point>
          •
        </Point>
        <Text>
          <Info>
            <span>
              {lastText}
            </span>
            <p>
              { info_number === 0 ? 'Sem dados' : getMoney(info_number) }
            </p>
          </Info>
        </Text>
        {
          (info_number !== 0) && (
            <Group color={number < info_number ? '#E14040' : '#119664'}>
              {
                number < info_number ? (
                  <BsArrowDownCircle />
                )
                :
                (
                  <BsArrowUpCircle />
                )
              }
            <p>{percentage}</p>
            </Group>
          )
        }
      </Infos>
    </Container>
    )
}

export default SimpleCard;