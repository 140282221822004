import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-x: auto;

  h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    color: #011032;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 22px;
    }
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #011032;;

    span {
      font-weight: 700;
    }
  } 
`;

export const SubContainer = styled.div`
  z-index: 2;
  flex: 1 1;
  padding: 20px 14px 0px 14px;
  max-width: 1320px;

  @media (max-width: 1024px) {
    .header-comp {
      margin: -20px -14px 0 -14px;
    }
  }
`

export const Pack = styled.div``

export const WebOrganization = styled.div`
  margin-top: 15px;

  @media (max-width: 1200px) {
    display: none;
  }
`

export const MobileOrganization = styled.div`
  display: none;
  margin-top: 15px;

  @media (max-width: 1200px) {
    display: grid;
    justify-content: center;
  }
`