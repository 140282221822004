import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: block;
  margin: 0 auto;
  justify-content: center;
  overflow-x: auto;
  max-width: 1292px;
`;

export const SubContainer = styled.div`
  z-index: 2;
  flex: 1 1;
  padding: 0 14px 0px 14px;
  max-width: 1320px;

  .header-comp {
    margin: 0 -14px;
  }
`

export const Content = styled.div`
  section {
    display: flex;
    height: 100%;
    align-items: center;

    &.actions {
      padding: 0;
      padding-bottom: 55px;

      @media (max-width: 1320px) { 
        align-items: flex-start;
      }

      @media (max-width: 565px) { 
        flex-direction: column;
        align-items: center;
      }
    }

    &.welcome {
      justify-content: space-between;
      padding-bottom: 40px;
      align-items: flex-end;
      margin-top: 20px;
    }

    .home-img-container {
      height: 100%;
      display: flex;
      align-self: center;
    }

    .home-img {
      width: 625px;
      
      @media (max-width: 735px) {
        display: none;
      }
    }
  }
`

export const Welcome = styled.div`
  padding: 20px 10px 0 0px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;

  @media (max-width: 565px) {
    padding-top: 10px;
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #011032;
    margin: 30px 0 20px 0;
    max-width: 595px;

    @media (max-width: 735px) {
      width: 100%;
      margin-bottom: 25px;
    }

    @media (max-width: 565px) {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
    }

    span {
      font-weight: 600;
    }
  }

  .home-img-mobile-container {
    margin: 0 -10px 25px -10px;
    display: none;

    @media (max-width: 735px) {
      display: block;
    }
  }

  .home-img-mobile {
    display: none;

    @media (max-width: 735px) {
      display: block;
    }
  }
`

export const WelcomeTitle = styled.div`
  position: relative;
  padding-left: 20px;

  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 38.4px;
    line-height: 40px;
    color: #011032;

    @media (max-width: 565px) {
      font-size: 28px;
      line-height: 32px;
    }
  }

  img {
    z-index: -1;
    position: absolute;
    top: -20px;
    left: 0;
    width: 220px;

    @media (max-width: 565px) {
      width: 165px;
      top: -13px;
      left: 2px;
    }
  }
`

export const Buttons = styled.div`
  display: flex;
  margin: 0;

  @media (max-width: 735px) {
    justify-content: center;
  }

  @media (max-width: 440px) {
    flex-direction: column;
    align-items: center;
    margin: 0 -10px 0 0px;
  }

  button {
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #011032;
    height: 44px;

    @media (max-width: 1150px) {
      font-size: 12px;
      line-height: 14px;
      height: 38px;
    }

    @media (max-width: 440px) {
      width: 100%;
    }

    &.extract-btn {
      border: 1px solid #FFD60A;
      margin-right: 13.5px;
      min-width: 208px;

      @media (max-width: 1150px) {
        min-width: 140px;
      }

      @media (max-width: 440px) {
        margin: 0 0 10px 0;
      }

      &:hover {
        border: 1px solid #011032;
      }
    }

    &.start-btn {
      background: #FFD60A;
      box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.4);
      font-weight: 700;
      min-width: 239px;

      @media (max-width: 1150px) {
        min-width: 220px;
      }

      &:hover {
        background: #f5c600;
      }
    }
  }
`

export const CardsActions = styled.div`
  @media (max-width: 565px) {
    width: 100%; 
  }

  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    color: #011032;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 12.4px;
    white-space: nowrap;

    &.condition-title {
      @media (max-width: 1320px) {
        margin-bottom: -25px;
        margin-left: 7px;
      }

      @media (max-width: 730px) {
        white-space: unset;
      }

      @media (max-width: 565px) {
        margin-bottom: 16px;
      }
    }

    &.balance-title {
      @media (max-width: 730px) {
        margin-bottom: 30px;
      }

      @media (max-width: 565px) {
        display: none;
      }
    }
  }

  .cards {
    display: flex;

    @media (max-width: 1320px) {
      display: none;
    }

    @media (max-width: 565px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

export const CardHomeContainer = styled.div`
  width: 239px;
  height: 155px;
  background: #FFFFFF;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
  border-radius: 14px;
  margin-right: 14px;
  padding: 10px 15px 13px 20px;

  @media (max-width: 1320px) {
    margin: 0px;
  }

  @media (max-width: 565px) {
    width: 100%; 
    flex-direction: row;
    height: fit-content;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
    padding: 12px 21px 13px 21px;
  }

  &:hover {
    cursor: pointer;
    background: #fafafa;
  }

  .icon-container {
    width: 35px;
    height: 35px;
    margin-left: -2px;
    margin-bottom: 10px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    background: #e6e8eb;

    @media (max-width: 565px) {
      width: 41px;
      height: 41px;
      margin-bottom: 10px;
    }
  }

  h1 {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #011032;
    margin-bottom: 10px;

    @media (max-width: 1600px) {
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 6px;
    }
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #011032;

    @media (max-width: 565px) {
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 6px;
    }
  }
`

export const CardBalance = styled.div`
  width: 239px;
  height: 155px;
  background: #010f37;
  box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 6px 6px 30px rgba(174, 195, 214, 0.29);
  border-radius: 14px;
  margin-right: 14px;
  padding: 10px 15px 16px 20px;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  position: relative;

  @media (max-width: 1320px) {
    margin-right: 7px;
  }

  @media (max-width: 565px) {
    width: 100%; 
    flex-direction: row;
    height: fit-content;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 27px;
    padding: 15px 21px 15px 21px;
  }

  .icon-container {
    width: 35px;
    height: 35px;
    margin-left: -2px;
    margin-bottom: 9px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    background: #1a274b;

    @media (max-width: 565px) {
      margin: 0px 19px 0px 0px;
      width: 41px;
      height: 41px;
    }
  }

  h1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-bottom: 3px;
    margin-right: 22.5px;

    @media (max-width: 565px) {
      margin-bottom: 8px;
      font-size: 12px;
      line-height: 15px;
    }
  }

  h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;

    @media (max-width: 565px) {
      font-size: 14px;
      line-height: 15px;
    }
  }

  .icon-mob {
    display: none;

    svg {
      &:hover {
        cursor: pointer;
      }
    }

    @media (max-width: 565px) {
      display: block;
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }

  .title-balance {
    position: relative;
    display: flex;
    
    svg {
      @media (max-width: 565px) {
        display: none;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
`

export const FooterCard = styled.div`
  display: flex;
  background: linear-gradient(180deg, #011032 0%, #2895F6 100%);
  border-radius: 16px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 65px;
  margin: 40px 0 70px 0;

  @media (max-width: 1300px) {
    flex-direction: column;
    padding: 30px 21px;
    margin-top: 20px;
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;

    @media (max-width: 1300px) {
      font-size: 23px;
      line-height: 30px;
    }

    @media (max-width: 600px) {
      font-size: 18px;
      line-height: 20px;
    }
  }

  p {
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: 5px;

    @media (max-width: 1300px) {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 40px;
    }

    @media (max-width: 600px) {
      font-size: 12px;
      line-height: 14px;
    }

    @media (max-width: 565px) {
      margin-bottom: 10px;
    }
  }

  button {
    background: #FFD60A;
    box-shadow: 0px 3px 24px 4px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 13px;
    height: fit-content;
    min-width: fit-content;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #011032;

    &:hover {
      background: #f5c600;
    }

    @media (max-width: 1300px) {
      padding: 16px 52px;
      font-size: 12px;
      line-height: 22px;
      min-width: unset;
    }

    @media (max-width: 400px) {
      width: 100%;
      padding: 16px 0px;
      font-size: 11px;
      line-height: 22px;
    }
  }

  .imgs-container {
    position: relative;
    height: 160px;
    min-width: 340px;
    justify-content: flex-start;
    display: flex;
    align-items: center;

    @media (max-width: 1300px) {
      width: calc(100% + 40px) ;
      justify-content: center;
    }

    @media (max-width: 500px) {
      min-width: 100%;
    }

    img {
      position: absolute;

      &.plates {
        z-index: 3;
        max-width: 189.6px;
        bottom: 0;
        left: 38px;

        @media (max-width: 1300px) {
          max-width: 237px;
          left: unset;
        }

        @media (max-width: 800px) {
          max-width: 200px;
        }
      }

      &.elipse {
        bottom: 0;
        right: 0;
        max-width: 400px;

        @media (max-width: 1300px) {
          display: none;
        }
      }

      &.elipse-mob {
        display: none;
        width: 100%;

        @media (max-width: 800px) {
          width: 100%;
          display: block;
        }
      }
    }
  }
`