import React, { useState, useEffect } from "react";

import { Container, Watch } from "./styles";

interface BoxWatchProps {}

const BoxWatch: React.FC<BoxWatchProps> = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const deadline = "Aug, 20, 2023";

  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);

    return () => clearInterval(interval);
  }, []);

  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();

    if (time < 0) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
      return;
    }

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  return (
    <Container>
      <h3>Duração da Campanha</h3>
      <div className="watchs">
        <Watch>
          <h1>{days < 10 ? "0" + days : days}</h1>
          <p>Dias</p>
        </Watch>
        <Watch>
          <h1>{hours < 10 ? "0" + hours : hours}</h1>
          <p>Horas</p>
        </Watch>
        <Watch>
          <h1>{minutes < 10 ? "0" + minutes : minutes}</h1>
          <p>Minutos</p>
        </Watch>
        <Watch className="seconds">
          <h1>{seconds < 10 ? "0" + seconds : seconds}</h1>
          <p>Segundos</p>
        </Watch>
      </div>
    </Container>
  );
};

export default BoxWatch;
