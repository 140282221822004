import styled from 'styled-components';
import { colors } from '../../styles/tokens/color';

export const Container = styled.div`
  width: 60%;
  height: 648px;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 32px 40px;
  background: #FFFFFF;
  box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  display: none;
  flex-direction: column;
  
  &.visible {
      display: flex !important;
  }

  @media (max-width: 600px) {
    padding: 24px;
    width: 100%;
  }

  @media (max-width: 375px) {
    margin: 10px;
    padding: 18px;
    height: 500px;
  }

  img {
    width: fit-content;
    height: 33px;
    margin-bottom: 23px;
  }
  
  h1 {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    color: #000000;

    @media (max-width: 475px) {
      font-size: 18px;
    }
  }

  .checkbox-accept {
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    .accept-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #000000;

      @media (max-width: 475px) {
        font-size: 13px;
      }
    }
  }

  button {
    height: 58px;
    margin-bottom: 25px;
    background: #BDB8B8;
    border-radius: 4px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    transition: all 0.2s;

    @media (max-width: 475px) {
      font-size: 13px;
      height: 50px;
    }

    &.activated {
      background: #FFD60A;
      color: ${colors.primary.blue};
    }
  }

  .cancel-text {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #011032;
    cursor: pointer;
  }
`;

export const Scroller = styled.div`
  height: 310px;
  padding-right: 10px;
  overflow-y: auto;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;

  @media (max-width: 475px) {
    font-size: 13px;
  }

  @media (max-width: 375px) {
    font-size: 12px;
  }

  a {
    color: blue;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside
  }

  ul {
    padding-left: 15px;
    list-style-type: lower-alpha;
    list-style-position: inside
  }
`

export const Checkbox = styled.div`
  min-width: 20px;
  min-height: 20px;
  margin-right: 12px;
  border-radius: 3px;
  border: 2px solid black;
  position: relative;

  svg {
    position: absolute;
    top: -3px;
    left: -3px;
    min-width: 22px;
    min-height: 22px;
    border-radius: 3px;
    padding: 4px;
    color: #FFF;
    background-color: #FFD60A;
  }
`
