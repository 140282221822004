import styled from 'styled-components';

interface IContainerProps {
  isError?: boolean;
}

export const Container = styled.div<IContainerProps>`
  width: 135px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  position: relative;

  p {
    &.error-msg {
      font-weight: 500;
      font-size: 10px;
      line-height: 100%;
      color: #011032;
      margin-top: 3px;
      margin-left: 4px;
      color: #FF0000;
    }
  }
  
  .react-datepicker__current-month {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    color: #011032;
    margin-bottom: 16px;
  }

  .react-datepicker__header  {
    background-color: #F5F5F6;
    border-bottom: unset;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker {
    border: unset;
    box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 6px 6px 30px rgba(174, 195, 214, 0.29);
    border-radius: 14px;
  }

  .react-datepicker__day-names {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #011032;
  }

  .react-datepicker__month {
    background-color: #F5F5F6;
    margin: 0;
    padding: 6.4px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #011032;
  }

  .react-datepicker__day {
    border-radius: 50%;

    &:hover {
      background-color: #FFD60A;
      transition: all 0.2s;
    }
  }

  .react-datepicker__day--selected {
    background-color: #FFD60A;
    color: #011032;
    border-radius: 50%;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #FFD60A;
    color: #011032;
    border-radius: 50%;
  }

  .react-datepicker__month-container {
    border: unset;
  }

  .react-datepicker__day--outside-month {
    color: #cccccc;
    pointer-events: none;
    z-index: -99;
  } 

  button {
    min-width: unset;
    width: unset;
    background-color: unset;
    box-shadow: unset;
    margin: 0;

    &:hover {
      background-color: unset;
    }

    &.react-datepicker__navigation {
      @media (max-width: 1024px) {
        min-width: 15px;
        width: 15px;
        margin: 10px;
      }
    }
  }

  label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: ${props => props.isError ? '#FF0000' : '#011032'};
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      width: 18px;
      height: 20px;
    }
  }

  .date-input {
    height: 43px;
    border: 1px solid '#011032';
    border-radius: 4px;
  }

  input {
    width: 135px;
    height: 43px;
    border: 1px solid ${props => props.isError ? '#FF0000' : '#011032'};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    padding: 0px 21px;
    outline: none;

    &:focus {
      outline: 1px solid ${props => props.isError ? '#FF0000' : '#011032'};
    }
  }
`;
