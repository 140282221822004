import React, { ReactNode } from "react";

import { Container } from "./styles";

import { formatDateText } from "utils/date";

import { BiCalendar } from "react-icons/bi";

interface InfoHeaderProps {
  startDate: Date | null;
  endDate: Date | null;
  filterDiv?: ReactNode
  periodInfoDiv?: ReactNode
}

const InfoHeader: React.FC<InfoHeaderProps> = ({
  startDate,
  endDate,
  filterDiv,
  periodInfoDiv
}) => {
  return (
    <Container>
      <div className="info-content">
        <div className="periods-div">
          <div className="period">
            <BiCalendar size={20}/>
            <p>
              <b>Período Selecionado: </b>{" "}
              {startDate ? formatDateText(startDate) : "N/A"} a{" "}
              {endDate ? formatDateText(endDate) : "N/A"}
            </p>
          </div>
          {
            periodInfoDiv
          }
        </div>
        {
          filterDiv
        }
      </div>
    </Container>
  );
};

export default InfoHeader;
