import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import CurrencyInput from 'react-currency-input-field'
import { useToast } from '@chakra-ui/react';

interface CustomInputMaskProps {
    placeholder: string,
    type: string,
    label?: string,
    change: (e: string) => void,
    value: string,
    isError?: boolean,
    msgError?: string,
    readonly?: boolean,
    valueI?: string | number,
    setValueI: (e: string) => void,
}

const CustomMaskInput: React.FC<CustomInputMaskProps> = ({ placeholder, change, isError, msgError, readonly, valueI, setValueI }) => {

    const toast = useToast();
    const [insideError] = useState(false);

    const handleOnValueChange = (_value: string | undefined, fieldName: string | undefined): void => {
        if (_value !== undefined && _value !== ',') {
            change(_value.replace(',', '.'))
            setValueI(_value);
            return
        }
        setValueI('');
        change('')
    }

    const locale = {
        locale: 'pt-BR',
        currency: 'BRL',      
    }

    const getClassName = (er ?: boolean, ro ?: boolean, ie ?: boolean) => {
        if (ie) {
            return 'custom-input error';
        }
        if (er) {
            return 'custom-input error';
        }
        if (ro) {
            return 'custom-input-readonly';
        }
        return 'custom-input';
    }

    function handleKeyPoint(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === '.') {
            toast({
                title: 'Para usar casas decimais, use a vírgula ","',
                status: 'info',
                position: 'top',
                isClosable: true,
            });
        }
    }

    useEffect(() => {        
        getClassName(isError, readonly, insideError);
    }, [isError, readonly, insideError])

    return (
        <Container onKeyDown={handleKeyPoint}>
            <CurrencyInput
                intlConfig={locale}
                onValueChange={handleOnValueChange}
                decimalsLimit={2}
                groupSeparator="."
                decimalSeparator=","
                step={1}
                className={getClassName(isError, readonly, insideError)}
                readOnly={readonly}
                placeholder={placeholder}
                value={valueI}
                maxLength={11}
                onKeyPress={(event) => {
                    if (event.key === '-') {
                      event.preventDefault();
                    }
                }}
            />
            {isError && <p className="error-msg">{msgError}</p>}
        </Container>
    );
}

export default CustomMaskInput;