import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 75px);
  max-height: calc(100vh - 75px);
  background-color: #F7F7F7;
  display: flex;

  @media (max-width: 1024px) {
    //min-height: -webkit-fill-available;
    height: 100%;
    min-height: 100%;
    //height: calc(100vh - calc(100vh - 100%));
    display: flex;
    //flex-direction: column;
  }

  .star-background {
    position: fixed;
    right: -120px;
    bottom: -90px;
    z-index: 1;
  }
`;
