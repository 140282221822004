import React, {useEffect, useState, useContext, useLayoutEffect} from 'react';
import { UserContext } from '../../../context/userContext';
import axios from 'axios';

// Components
import WithdrawBoxPixKeys from '../../../components/Withdraws/WithdrawBoxPixKeys';
import WithdrawBoxForm from '../../../components/Withdraws/WithdrawBoxForm';
import CustomInput from '../../../components/Inputs/CustomInput';
import CustomSelect from '../../../components/Inputs/CustomSelect';
import LoadingScreen from '../../../components/LoadingScreen';
import HeaderComponent from '../../../components/HeaderComponent';
import { useToast } from '@chakra-ui/react';

//Úteis
import { registerPixKeysSchema } from '../../../utils/validateSchemas';
import endPoints from '../../../utils/endPoints';
import { useHistory } from 'react-router-dom';

// Estilização
import { Container, SubContainer } from './styles';
import { pixIcon } from '../../../assets/pixIcon';

// Mixpanel
import mixPanelTrack from '../../../mixpanel';

const RegisterPixKey: React.FC = () => {
  // Constantes Estáticas
  const options = [
    { id: 0, text: 'Celular', value: 0 },
    { id: 1, text: 'CPF', value: 1 },
    { id: 2, text: 'E-mail', value: 2 },
    { id: 3, text: 'Chave Aleatória', value: 3 },
    { id: 4, text: 'CNPJ', value: 4 },
  ]

  //States
  const [loading, setLoading] = useState(false);

  const [pixKey, setPixKey] = useState('');
  const [pixKeyError, setPixKeyError] = useState(false);
  const [pixKeyMsgError, setPixKeyMsgError] = useState('');

  const [pixKeyType, setPixKeyType] = useState('');
  const [pixKeyTypeError, setPixKeyTypeError] = useState(false);
  const [pixKeyTypeMsgError, setPixKeyTypeMsgError] = useState('');

  const toast = useToast();
  const { affiliate_accounts } = endPoints;
  const history = useHistory();

  const [pixKeys, setPixKeys] = useState([]);

  const { userData } = useContext(UserContext);

  //const [currentPixIcon, setCurrentPixIcon] = useState(pixIcon);

  useLayoutEffect(() => {
    history.push('/withdraw');
  }, [history]);

  //UseEffects
  useEffect(() => { setPixKeyError(false) }, [pixKey])
  useEffect(() => { setPixKeyTypeError(false) }, [pixKeyType])

  useEffect(() => {
    getPixKeys();
    // eslint-disable-next-line
  }, [])

  const getPixKeys = async () => {
    let url = affiliate_accounts;

    axios.get(url).then((response) => {
      setPixKeys(response.data.data);
    }).catch((e) => {
      console.log(e);
    })
  }

  const deletePixKey = async (id: number) => {
    setLoading(true);
    let url = `${affiliate_accounts}/${id}`;

    axios.delete(url).then(() => {
      toast({
        title: 'Chave PIX removida com sucesso!',
        status: 'success',
        position: 'top',
        isClosable: true,
      })
      getPixKeys()
      setLoading(false);
      mixPanelTrack('Saque - PIX', { option: 'delete-pix-key', user_smartico: userData.smartico_id })
    }).catch((e) => {
      toast({
        title: 'Erro',
        status: 'error',
        position: 'top',
        isClosable: true,
      })
      // notificação de erro ao carregar dados
      console.log(e);
      setLoading(false);
    })
  }

  const createPixKey = async () => {
    if (userData.person_type) {
      toast({
        title: 'Você deve realizar a requisição de saque por envio de nota fiscal (PJ), com isso não precisa registrar uma nova chave PIX!',
        status: 'error',
        position: 'top',
        isClosable: true,
      })
      return
    }

    setLoading(true);
    let url = `${affiliate_accounts}`;

    axios.post(url, { pix_key: pixKey, type: Number(pixKeyType) }).then(() => {
      // Zerar values
      setPixKey('');
      setPixKeyType('');
      toast({
        title: 'Nova Chave PIX criada com sucesso!',
        status: 'success',
        position: 'top',
        isClosable: true,
      })
      getPixKeys()
      setLoading(false);
      mixPanelTrack('Saque - PIX', { option: 'create-pix-key', user_smartico: userData.smartico_id })
    }).catch((e) => {
      toast({
        title: 'Erro',
        status: 'error',
        position: 'top',
        isClosable: true,
      })
      console.log(e);
      setLoading(false);
    })
  }

  const submitForm = async (e: any) => {
    e.preventDefault();

    if (await validationForm() === false) {
      return
    }

    createPixKey()
  }

  const validationForm = async () => {
    //setPixKeyError(false);
    //setPixKeyTypeError(false);

    const registerPixKeysData = {
      pixKey,
      pixKeyType
    }

    let errors: any = [];

    await registerPixKeysSchema.validate(registerPixKeysData, { abortEarly: false }).catch((err) => {
      err.inner.forEach((e: any) => {
        errors.push({ path: e.path, message: e.message })
      });
      return err;
    });

    const isValid = await registerPixKeysSchema.isValid(registerPixKeysData);

    if (isValid === false) {
      errors.forEach((error: any) => {
        if (error.path === 'pixKeyType') {
          setPixKeyTypeError(true);
          setPixKeyTypeMsgError(error.message);
        }
        if (error.path === 'pixKey') {
          setPixKeyError(true);
          setPixKeyMsgError(error.message);
        }
      })
      return false
    } else {
      return true
    }
  }

  return (
    <Container translate="no">
      <LoadingScreen show={loading} />
      <SubContainer>
        <div className='header'>
          <HeaderComponent
            title="Cadastrar Chave PIX"
            buttons={
              <>
                <button
                  onClick={() => { history.push("/withdraw-pix") }}
                >
                  {pixIcon}
                  <p>Saque PIX</p>
                </button>
              </>
            }
          />
        </div>
        <div className='content'>
          <WithdrawBoxForm
            title='Adicionar Chave Pix'
            textContent={
              <p>
                A chave PIX pode ser um número de celular, CPF, CNPJ, endereço de e-mail ou até mesmo uma sequência aleatória de letras e números.
              </p>
            }
            submit={submitForm}
            content={
              <>
                <CustomSelect
                  change={setPixKeyType}
                  placeholder="Tipo de Chave PIX"
                  isError={pixKeyTypeError}
                  msgError={pixKeyTypeMsgError}
                  options={options}
                  value={pixKeyType}
                />
                <CustomInput
                  change={setPixKey}
                  placeholder="Chave PIX"
                  type="text"
                  isError={pixKeyError}
                  msgError={pixKeyMsgError}
                  value={pixKey}
                />
              </>
            }
            textButton='Salvar'
          />
          <WithdrawBoxPixKeys
            title='Minhas Chaves Pix'
            pixKeys={pixKeys}
            deletePixKey={deletePixKey}
          />
        </div>
      </SubContainer>
    </Container>
  );
}

export default RegisterPixKey;