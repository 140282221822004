import styled from 'styled-components';

import { colors } from '../../../styles/tokens/color';

export const Container = styled.div`
  position: relative;
  margin-bottom: 20px;

  .error-msg {
    margin-top: -10px;
    margin-bottom: 0;
    color: red;
  }
`;

export const FalseInput = styled.div`
  .custom-file-input {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;

    cursor: pointer;
    width: 100%;

    margin: 0 auto;

    background-color: #FAFAFA;
    opacity: 0;
  }

  .custom-file-input-block {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;

    cursor: 'default';
    width: 100%;

    margin: 0 auto;

    background-color: #FAFAFA;
    opacity: 0;
  }

  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;
  border: 2px solid ${colors.primary.yellow};
  border-radius: 5px;
  height: 53px;
  padding: 0px 16px;
  justify-content: space-between;
  color: #718096;

  &.readonly-false-input {
    border: 2px solid ${colors.grey.block};

    svg {
      color: ${colors.grey.block};
    }

    .file {
      color: ${colors.grey.block};
    }

    .no-file {
      color: ${colors.grey.block};
    }

    &:hover {
      border: 2px solid ${colors.grey.block};

      svg {
        color: ${colors.grey.block};
      }

      .file {
        color: ${colors.grey.block};
      }

      .no-file {
        color: ${colors.grey.block};
      }
    }
  }

  .file {
    color: black;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: 14px;

    @media (max-width: 350px) {
      max-width: 150px;
    }
  }

  .no-file {
    @extend .file;

    color: #718096;
  }

  svg {
    color: ${colors.primary.yellow};
    min-height: 23px;
    min-width: 23px;
  }

  &:hover {
    transition: all 0.1s ease-in-out;
    border: 2px solid #CBD5E0;

    svg {
      transition: all 0.1s ease-in-out;
      color: #718096;
    }
  }

  @media (max-width: 350px) {
    height: 48px;
  }
`