import React, { useState } from 'react';
import { Container, Title, Number, Divider, Infos, Group, Info, Text, Point, Flex, Grid } from './styles';
import { BsArrowDownCircle, BsArrowUpCircle } from 'react-icons/bs';
import vectorBackground from '../../../assets/vector_background.png';
import { Tooltip } from '@chakra-ui/react'
import { AiOutlineInfoCircle } from 'react-icons/ai'

interface ComissionCardProps {
  number: number;
  info_number: number;
  percentage: string;
  className?: string;
  lastText: string;
  index: boolean;
}

const ComissionCard: React.FC<ComissionCardProps> = ({ number, info_number, percentage, className, lastText, index }) => {

  const [tool, setTool] = useState(false);

  const getQuant = (qnt: number) => {
    if (qnt === 0) {
      return 'R$ 0.000.000,00';
    }
    return qnt.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  const getUndefined = () => {
    return (number === 0 && info_number === 0) 
  }

  return (
    <Container className={className}>
      <Flex>
        <Grid>
          <Title index={index}>
            Comissão
            <Tooltip
              isOpen={tool}
              hasArrow
              placement='top-start'
              bg="#011032"
              px="12px"
              mb="2px"
              ml="-7px"
              mr='-7px'
              borderRadius='4px'
              maxW='150px'
              label={
                <p
                  style={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight: '400', fontSize: '10px', lineHeight: '16px', textAlign: 'center' }}
                >
                  Valor total a receber a partir das regras de negociação
                </p>
              }
            >
              <span onClick={() => setTool(!tool)} onMouseEnter={() => setTool(true)} onMouseLeave={() => setTool(false)}><AiOutlineInfoCircle size={15} /></span>
            </Tooltip>
          </Title>
          <Number>
            { getQuant(number) }
          </Number>
        </Grid>
        <img src={vectorBackground} alt="logo-text"/>
      </Flex>
      <Divider />
      <Infos>
        <Point>
          •
        </Point>
        <Text>
          <Info>
            <span>
              {lastText}
            </span>
            <p>
              { getUndefined() ? 'Sem dados' : info_number === 0 ? 'R$ 0,00' : getQuant(info_number) }
            </p>
          </Info>
        </Text>
        {
          (!getUndefined()) && (
            <Group color='#FFF'>
              {
                number < info_number ? (
                  <BsArrowDownCircle color='#E14040'/>
                )
                :
                (
                  <BsArrowUpCircle color='#119664'/>
                )
              }
              <p>{percentage}</p>
            </Group>
          )
        }
      </Infos>
    </Container>
    )
}

export default ComissionCard;