import styled from "styled-components";
import { colors } from "styles/tokens/color";

export const Container = styled.div`
  padding: 0px 0px 13px 0px;
  margin-top: 5px;
  background: #FFFFFF;
  display: flex;
  box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.12);
  height: auto;
  overflow-y: auto;
  overflow-x: auto;

  @media (max-width: 575px) {
    display: none;
  }

  table {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    tbody {
      flex: 1 1 auto;
    }

    tr {
      display: flex;
      
      @media (max-width: 1300px) {
        width: fit-content;
      }

      &.headers {
        border-bottom: none;
        padding: 15px 0;

        &:hover {
          background: white;
        }
      }

      &:hover {
        transition: 0.1s ease-in-out;
        background: #EEF1F2;
      }
    }

    th {
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
      text-align: start;
      color: ${colors.primary.blue};
    }

    td {
      font-weight: 400;
      padding: 15px 0;
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 15px;
      color: #716B6B;
    }

    th, td {
      text-align: center;
      justify-content: center;
      width: 10%;
      min-width: 107px;
      overflow: hidden;
      word-break: break-all;
    }
  }
`;
