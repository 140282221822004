import React, { useContext } from 'react';

import { UserContext } from '../../../context/userContext';

import { Container, Title, Actions, Button } from './styles';
// TODO: Comentado para logo temporário
// import logoPartners from '../../../assets/Partners/logo-partners.png';
import { starTemporaryLogo } from 'assets/Partners/starTemporaryLogo';
import { MdLogout, MdAccountCircle } from 'react-icons/md';

// Mixpanel
import mixPanelTrack from '../../../mixpanel';

interface TopBarProps {
  logout: () => void
}

const HeaderComponent: React.FC<TopBarProps> = ({ logout }) => {
  const { userData } = useContext(UserContext);

  const handleLogout = () => {
    mixPanelTrack('Session End', { user_smartico: userData.smartico_id})
    logout();
  }

  return (
    <Container>
      <Title>
        {/* <img src={logoPartners} alt="partners-img" className='partners-img' /> */}
        <div>
          {starTemporaryLogo}
        </div>
      </Title>
      <Actions>
        <p>Olá, {userData.name}</p>
        <MdAccountCircle size='48px' color='#D3D3D3' className='profile-avatar' />
        <Button onClick={handleLogout}>
          <MdLogout size='20px' />
        </Button>
      </Actions>
    </Container>
  );
}

export default HeaderComponent;