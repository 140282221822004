import React from 'react';

import { format } from 'date-fns';

import { WithdrawRequests } from '../TableWithdraw';

import { WrapScroll, Container, Item } from './styles';
import { IoMdDownload } from "react-icons/io";

interface MobileTableWithdrawProps {
  requests: WithdrawRequests[]
}

const MobileTableWithdraw: React.FC<MobileTableWithdrawProps> = ({ requests }) => {

  const getStatus = (status: number) => {
    if (status === 3) {
      return <span className="right"><span className="pending">Pendente</span></span>
    } else if (status === 1) {
      return <span className="right"><span className="accepted">Aceito</span></span>
    } else if (status === 2) {
      return <span className="right"><span className="refused">Reprovado</span></span>
    }
  }

  return (
    <WrapScroll>
      <Container>
        {requests.map(request => (
          <Item key={request.id}>
            <div className="row">
              <span className="left">Data de criação</span>
              <span className="right">
                {format(new Date(request.date_created), 'dd/MM/yyyy')}
              </span>
            </div>
            <div className="row">
              <span className="left">Tipo</span>
              <span className="right">{request.bank}</span>
            </div>
            <div className="row">
              <span className="left">Dados Bancários</span>
              <span className="right">{request.agency} / {request.account}</span>
            </div>
            <div className="row">
              <span className="left">Valor</span>
              <span className="right">
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(request.value)}
              </span>
            </div>
            {/* <div className="row">
              <span className="left">Nota Fiscal</span>
              <span className="right">Número NF</span>
            </div> */}
            <div className="row">
              <span className="left">Status</span>
              {getStatus(request.status)}
            </div>
            <div className="row">
              <span className="left">Comprovante ou Motivo de recusa</span>
              <span className="right">{request.reason}</span>
            </div>
            <div className="row">
              <span className="left">Anexo</span>
              <span className="right"><IoMdDownload /></span>
            </div>
          </Item>
        ))}
      </Container>
    </WrapScroll>
  )
}

export default MobileTableWithdraw;