import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: calc(100vw - 252px);
  justify-content: center;
  align-content: center;
  overflow-y: auto;
  @media (max-width: 1024px) {
    width: 100%;
  }
`

export const PageContent = styled.div`
  max-width: 1320px;
  width: 100%;
  padding: 22px 14px 0px 14px;
  height: fit-content;

  .header {
    @media (max-width: 1024px) {
      margin: -22px -14px 0px -14px;
    }
  }
`

export const Description = styled.div`
  margin-top: 10px;
  font-size: 14px;
  width: 100%;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 2px 2px 4px rgba(85,102,153,0.11), 6px 6px 30px rgba(174,195,214,0.29);
  p {
    padding: 10px;
    background-color: #F7F7F7;
    color: #011032;
    border-radius: 12px;
  }
`

export const IntegrationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

export const IntegrationsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media (max-width: 750px) {
    justify-content: center;
  }
`

export const ListHeader = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const ListTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  padding-left: 5px;
`

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 16, 50, 0.3);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  transition: color 0.2s;
`
