import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from "react";

import HeaderComponent from "components/HeaderComponent";
import FilterByStatus from "components/Manager/FilterByStatus";
import TableAffiliatesManager from "components/Tables/TableAffiliatesManager";
import MobileTableAffiliatesManager from "components/Tables/MobileTableAffiliatesManager";
import Pagination from "components/Pagination";
import LoadingScreen from "components/LoadingScreen";

import {
  Container,
  SubContainer,
  OverlayLoading,
  Wrapper,
  PaginationBar,
  PageDescription,
} from "./styles";

import { FiUsers } from "react-icons/fi";
import { FaFilter } from "react-icons/fa";

import {
  Menu,
  MenuList,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  CircularProgress,
  useToast,
} from "@chakra-ui/react";
import endPoints from "utils/endPoints";
import { Affiliates } from "components/Tables/TableAffiliatesManager";
import SubHeader from "components/Headers/SubHeader";
import axios from "axios";
// import DealsModal from "components/DealsModal";
import ConfirmationModal from "components/ConfirmationModal";
import { UserContext } from "context/userContext";
import mixPanelTrack from "mixpanel";
import { buildDeals } from "models/affiliate-ids";

const AffiliatesManager: React.FC = () => {
  const toast = useToast();
  const [affiliates, setAffiliates] = useState<Affiliates[]>([]);
  // const [currentAffiliate, setCurrentAffiliate] = useState<Affiliates |null>(null);

  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [confirmationData, setConfirmationData] = useState<{
    affiliate: Affiliates | null;
    status: string | null;
  }>({ affiliate: null, status: null });

  // const [showDealsModal, setShowDealsModal] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [nPages, setNPages] = useState<number>(0);

  const { approval_user_manager } = endPoints;

  const myRef = useRef<any>();
  const myRef2 = useRef<any>();

  const { userData } = useContext(UserContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        myRef.current &&
        !myRef.current.contains(e.target) &&
        myRef2.current &&
        !myRef2.current.contains(e.target) &&
        !showLoading &&
        showFilter
      ) {
        setShowFilter(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showFilter, showLoading]);

  const getAffiliates = useCallback(
    async (page: number = 1) => {
      setShowLoading(true);
      let url =
        approval_user_manager + `/${userData.id}?page=${page}&itemsPerPage=10`;

      axios
        .get(url)
        .then((response) => {
          setAffiliates(response.data.affiliates);
          calcPages(response.data.total, page);
          setPage(page);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setShowLoading(false);
        });
    },
    [approval_user_manager, userData.id],
  );

  useEffect(() => {
    getAffiliates();
  }, [getAffiliates]);

  const handleStatusUpdate = (affiliate: Affiliates, status: string) => {
    const { approval_user } = endPoints;

    mixPanelTrack("Gerente Action", {
      gerente_smartico_id: userData.smartico_id,
      affiliate_nick: affiliate.nick,
      affiliate_name: affiliate.name,
      type: status,
    });

    axios
      .put(approval_user, { id: affiliate.id, status })
      .then((response) => {
        if (status === "APPROVED" && response.status === 200) {
          // getAffiliates(page);
          // setCurrentAffiliate(response.data);
          // setShowDealsModal(true);
          dealsCreation(response.data);
        }
      })
      .catch((e) => {
        console.log(e);
        let data = e.response.data;
        const message = `${data.message}. Procure o suporte.`
        toast({
          title: message,
          status: "error",
          position: "top",
          isClosable: true,
        });
      })
      .finally(() => {
        // if (status !== "APPROVED") getAffiliates(page);
        setShowConfirmationModal(false);
        getAffiliates(page);
      });
  };

  const dealsCreation = (currentAffiliate: Affiliates) => {
    const { rake_deals } = endPoints;

    const deals_name = `Deals_${currentAffiliate.name.replace(/\s/g, "")}`;

    let submitData: {} = {
      affiliate_id: currentAffiliate.smartico_id,
      deal_group_name: deals_name,
      deal_type_id: 6,
      ...buildDeals(userData.id),
    };

    axios
      .post(rake_deals, submitData)
      .then((response) => {
        toast({
          title: "Novo Afiliado aprovado com sucesso.",
          status: "success",
          position: "top",
          isClosable: true,
        });
      })
      .catch((e) => {
        let data = e.response.data;
        toast({
          title: data.message,
          status: "error",
          position: "top",
          isClosable: true,
        });
      });
  };

  const handleActionClick = (affiliate: Affiliates, status: string) => {
    setConfirmationData({ affiliate, status });
    setShowConfirmationModal(true);
  };

  const handleFilter = async () => {
    getAffiliates();
  };

  const calcPages = (totalItems: number, currentPage: number) => {
    let holder = totalItems / 10;
    let nOfPages = Math.ceil(holder);
    setNPages(nOfPages);
  };

  const getConfirmationDescription = (
    affiliate: Affiliates | null,
    status: string | null,
  ) => {
    const revShareByManager = buildDeals(
      userData.id,
    ).smartico_rev_share_percentage;
    const cpaMyManager = buildDeals(userData.id).smartico_cpa_amount;
    const baselineByManager = buildDeals(userData.id).smartico_qua_cpa_volume;

    if (affiliate) {
      if (status === "APPROVED") {
        return (
          <>
            Tem certeza que deseja aprovar o cadastro? <br />
            <br />
            Após a aprovação o <strong>{affiliate.name}</strong> se tornará um
            novo afiliado com as taxas padrões cadastradas de R${cpaMyManager}
            ,00 CPA, R${baselineByManager},00 baseline + {revShareByManager}% de
            RevShare.
          </>
        );
      } else if (status === "REFUSED") {
        return (
          <>
            Tem certeza que deseja reprovar o cadastro do novo afiliado{" "}
            <strong>{affiliate.name}</strong>?
          </>
        );
      }
    }
    return <>Tem certeza de que deseja executar esta ação?</>;
  };

  return (
    <Container>
      <LoadingScreen show={showLoading} />
      <SubContainer>
        <div className="header">
          <HeaderComponent
            title="GERENTE | Gestão de afiliados"
            buttons={
              <>
                <button className="mb-filter" onClick={onOpen}>
                  <FaFilter className="icon-filter" />
                </button>
                <Menu isOpen={showFilter}>
                  {/*<MenuButton
                    className="filter"
                    onClick={() => setShowFilter(!showFilter)}
                    ref={myRef2}
                  >
                    <FaFilter className="icon-filter" />
                    <span className="conditional">
                      Filtros
                      <TbChevronDown
                        className={
                          showFilter ? "icon-arrow active" : "icon-arrow"
                        }
                      />
                    </span>
                      </MenuButton>*/}
                  <MenuList zIndex={99}>
                    <FilterByStatus
                      onClose={() => setShowFilter(false)}
                      handleFilter={handleFilter}
                    />
                  </MenuList>
                </Menu>
              </>
            }
          />
        </div>

        <SubHeader>
          <PageDescription>
            <span>
              Aqui, você gerente faz a gestão dos afiliados que se cadastraram
              utilizando seu link de convite.
            </span>
            <span>
              Você deverá aprovar/reprovar as solicitações de afiliação e
              definir os atributos de remuneração que será considerado para o
              respectivo afiliado.
            </span>
            {/* <div className="period">
              <FaFilter size={20} />
              <span>
                Através do Filtro, você poderá filtrar os Afiliados Aprovados, Reprovados e Pendentes.
              </span>
          </div> */}
          </PageDescription>
        </SubHeader>

        <Wrapper>
          {affiliates.length > 0 && (
            <TableAffiliatesManager
              affiliates={affiliates}
              onApprove={(affiliate: Affiliates) =>
                handleActionClick(affiliate, "APPROVED")
              }
              onReprove={(affiliate: Affiliates) =>
                handleActionClick(affiliate, "REFUSED")
              }
              onDisable={(affiliate: Affiliates) =>
                handleActionClick(affiliate, "DISABLED")
              }
            />
          )}
          {affiliates.length > 0 && (
            <MobileTableAffiliatesManager
              affiliates={affiliates}
              onApprove={(affiliate: Affiliates) =>
                handleActionClick(affiliate, "APPROVED")
              }
              onReprove={(affiliate: Affiliates) =>
                handleActionClick(affiliate, "REFUSED")
              }
              onDisable={(affiliate: Affiliates) =>
                handleActionClick(affiliate, "DISABLED")
              }
            />
          )}
          {!(affiliates.length > 0) && (
            <div className="no-requests">
              <FiUsers size={100} />
              <span className="empty-text">Sem dados.</span>
            </div>
          )}
          <PaginationBar>
            <Pagination
              total={nPages}
              currentPage={page}
              getSaques={getAffiliates}
            />
          </PaginationBar>
        </Wrapper>

        {/* {currentAffiliate && (
          <DealsModal
            currentAffiliate={currentAffiliate}
            showModal={showDealsModal}
            onClose={() => setShowDealsModal(false)}
          />
        )} */}

        <ConfirmationModal
          title="Confirmação"
          description={getConfirmationDescription(
            confirmationData.affiliate,
            confirmationData.status,
          )}
          isOpen={showConfirmationModal}
          onAccept={() => {
            if (confirmationData.affiliate && confirmationData.status)
              handleStatusUpdate(
                confirmationData.affiliate,
                confirmationData.status,
              );
          }}
          onCancel={() => setShowConfirmationModal(false)}
        />

        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay className="performance" />
          <ModalContent
            bgColor="transparent"
            boxShadow="unset"
            justifyContent="center"
            display="grid"
          >
            <OverlayLoading className={showLoading ? "show" : ""}>
              <CircularProgress
                isIndeterminate
                color="#FFD60A"
                trackColor="rgba(1, 16, 50, 0.3)"
              />
            </OverlayLoading>
            <FilterByStatus
              onClose={() => setShowFilter(false)}
              handleFilter={handleFilter}
            />
          </ModalContent>
        </Modal>
      </SubContainer>
    </Container>
  );
};

export default AffiliatesManager;
