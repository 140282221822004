import React, { useState } from 'react';
import { ContainerDetail, Infos, Number, Percentage, Group } from '../styles';
import { BsArrowDownCircle, BsArrowUpCircle } from 'react-icons/bs'
import { Tooltip } from '@chakra-ui/react'
import { PerformanceProps } from '../../../../pages/Reports/Performance/utils';
import { AiOutlineInfoCircle } from 'react-icons/ai'

interface DetailProps {
    detail: PerformanceProps;
    title: string;
    tooltipText: string;
}

const Detail: React.FC<DetailProps> = ({ detail, title, tooltipText }) => {

    const [tool, setTool] = useState(false);

    const getBigger = () => {
        return (detail.default_dates_values > detail.comparative_dates_values)
    }


    const getNumber = (value: number) => {
        const decimalPlaces = (value.toString().indexOf('.') === -1) ? 0 : value.toString().split('.')[1].length;
        const absValue = Math.abs(value);
      
        if (title === 'FTD to Lead%') {
          return `${absValue.toFixed(2)}%`;
        }
      
        if (absValue % 1 === 0) {
          return `${absValue.toLocaleString('pt-BR', { currency: 'BRL' })}`;
        }
      
        if (decimalPlaces === 1) {
          return `${absValue.toLocaleString('pt-BR', { currency: 'BRL' })}`;
        }
      
        return absValue.toLocaleString('pt-BR', { currency: 'BRL' });
    };

    return (
        <ContainerDetail>
            <Group>
                <h2>{title}</h2>
                <Tooltip
                isOpen={tool}
                hasArrow
                placement='top-start'
                bg="#011032"
                px="12px"
                mb="2px"
                ml="-7px"
                mr='-7px'
                borderRadius='4px'
                maxW='150px'
                label={
                    <p
                    style={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight: '400', fontSize: '10px', lineHeight: '16px', textAlign: 'center' }}
                    >
                    { tooltipText }
                    </p>
                }
                >
                    <span onClick={() => setTool(!tool)} onMouseEnter={() => setTool(true)} onMouseLeave={() => setTool(false)}><AiOutlineInfoCircle color='#011032' /></span>
                </Tooltip>
            </Group>
            <Infos>
                <Number>
                    {getNumber(detail.default_dates_values)}
                </Number>
                {
                    detail.comparative_dates_values !== 0 ? (
                        <>
                            <Number>
                                <span>
                                    
                                    {getNumber(detail.comparative_dates_values)}
                                </span>
                            </Number>
                            <Percentage className={getBigger() ? 'green' : 'red'} >
                                {
                                    getBigger() ? (
                                    <BsArrowUpCircle />
                                    )
                                    :
                                    (
                                    <BsArrowDownCircle />
                                    )
                                } 
                                { getNumber(detail.diff) } ({detail.diff_percentage.replaceAll("-", "")})
                            </Percentage>
                        </>
                    ) : (
                        <>
                            <Number>
                                <span>
                                    Sem dados
                                </span>
                            </Number>
                            <Percentage>
                                <BsArrowDownCircle color='#FFF'/>
                            </Percentage>
                        </>
                    )
                }
            </Infos>
        </ContainerDetail>
    )
}

export default Detail;