import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  background: #FFFFFF;
  box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 6px 6px 30px rgba(174, 195, 214, 0.29);
  border-radius: 14px;
  margin-right: 10px;
  padding: 10px 15px 11px 15px;

  @media (max-width: 1450px) {
    margin-bottom: 15px;
  }

  @media (max-width: 610px) {
    margin-right: 0px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;

  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #011032;
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #C6BEBE;

    span {
      color: #FFD60A;
      margin-right: 4px;
    }
  }
`

export const Divider = styled.div`
  border-top: 1px solid #EBE3FF;
  width: 585px;
  height: 0px;
  margin-left: -15px;
  margin-top: 10px;
  margin-bottom: 10px;

  @media (max-width: 610px) {
    width: 480px;
  }

  @media (max-width: 515px) {
    width: 400px;
  }

  @media (max-width: 430px) {
    width: 287px;
  }
`

export const ContainerDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;

  @media (max-width: 610px) {
    margin-bottom: 23px;

    &:last-child {
      margin-bottom: 4px;
    }
  }

  h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #222222;

    @media (max-width: 610px) {
      font-size: 11px;
      line-height: 13px;
      margin-left: 0px;
    }
  }

  img {
    width: 210px;
    margin-right: auto;
    margin-left: 15px;
  }
`

export const Icon = styled.div`
  background: #ECEEF2;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;

  &.green {
    color: #09945F;
  }

  &.red {
    color: #E14040;
  }

  @media (max-width: 610px) {
    display: none;
  }
`

export const Infos = styled.div`
  text-align: end;
`

export const Number = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #011032;

  span {
    font-size: 19px;
    color: #C6BEBE
  }

  @media (max-width: 610px) {
    font-size: 13px;
    line-height: 17px;

    span {
      font-size: 12px;
    }
  }
`

export const Percentage = styled.p`
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  align-items: center;
  margin-top: 1px;
  justify-content: end;

  svg {
    margin-right: 10px;
  }

  &.green {
    color: #09945F
  }

  &.red {
    color: #E14040
  }

  @media (max-width: 610px) {
    margin-top: 2px;
    font-size: 11px;
    line-height: 16px;
  }
`

export const Group = styled.div`
  display: flex;
  align-items: center;
  min-width: 30%;

  span {
    margin-left: 8px;
    margin-bottom: 25px;
  }
`