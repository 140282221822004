import React from "react";
 
import { Container, ActionButton } from "./styles";
// import { Switch } from "@chakra-ui/react";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import CustomTooltip from "components/CustomTooltip";
import { format } from "date-fns";

interface TableAffiliatesManagerProps {
  affiliates: Affiliates[];
  onApprove: (affiliates: Affiliates) => void;
  onReprove: (affiliates: Affiliates) => void;
  onDisable: (affiliates: Affiliates) => void;
}

export interface Affiliates {
  id: number;
  nick: string;
  name: string;
  status: string,
  date_created: string,
  cpa: number,
  baseline: number,
  smartico_id?: number,
}

const TableAffiliatesManager: React.FC<TableAffiliatesManagerProps> = ({
  affiliates,
  onApprove,
  onReprove,
  // onDisable,
}) => {
  const getPercentage = (value: any) => {
    if (value) {
      return value + '%';
    }
    return "N/A";
  };

  const getValue = (value: any) => {
    if (value) {
      return value;
    }
    return "N/A";
  };

  const formatDate = (value: string) => {
    if (value) {
      return format(new Date(value), 'dd-MM-yyyy HH:mm:ss');
    }
    return "N/A";
  }
  
  const getStatus = (status: string) => {
    if (status === "PENDING") {
      return <span className="right"><span className="pending">Pendente</span></span>
    } else if (status === "APPROVED") {
      return <span className="right"><span className="accepted">Aceito</span></span>
    } else if (status === "REFUSED") {
      return <span className="right"><span className="refused">Reprovado</span></span>
    } else if (status === "DISABLED") {
      return <span className="right"><span className="disabled">Desativado</span></span>
    }
  }

  const renderActions = (affiliate: Affiliates) => {
    const {status} = affiliate;
    if (status === "PENDING") {
      return (
        <>
          <CustomTooltip label="Aprovar">
            <ActionButton type="approve" onClick={() => onApprove(affiliate)}>
              <BiCheckCircle />
            </ActionButton>
          </CustomTooltip>
          <CustomTooltip label="Reprovar">
            <ActionButton type="reprove" onClick={() => onReprove(affiliate)}>
              <BiXCircle />
            </ActionButton>
          </CustomTooltip>
        </>
      )
    } else if (status === "APPROVED") {
      // return <Switch isChecked onChange={() => onDisable(affiliate)}/>
      return <React.Fragment/>;
    }
    return <React.Fragment/>;
  }

  return (
    <Container>
      <table>
        <thead>
          <tr className="headers">
            <th className="id">ID Afiliado</th>
            <th className="nickname">Nickname</th>
            <th className="name">Nome</th>
            <th className="status">Status</th>
            <th className="creation-date">Data de Criação</th>
            <th className="creation-date">CPA</th>
            <th className="creation-date">Porcentagem</th>
            <th className="actions">Ações</th>
          </tr>
        </thead>
        <tbody>
          {affiliates.map((affiliate, index) => (
            <tr key={index}>
              <td className="id">{getValue(affiliate.id)}</td>
              <td className="nickname">{getValue(affiliate.nick)}</td>
              <td className="name">{getValue(affiliate.name)}</td>
              <td className="status">{getStatus(affiliate.status)}</td>
              <td className="creation-date">{formatDate(affiliate.date_created)}</td>
              <td className="cpa">{getValue(affiliate.cpa)}</td>
              <td className="baseline">{getPercentage(affiliate.baseline)}</td>
              <td className="actions">
                {renderActions(affiliate)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default TableAffiliatesManager;
