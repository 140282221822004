import styled from 'styled-components';
import { colors } from '../../styles/tokens/color';

export const Container = styled.div`
  background-color: #fff;
  min-width: 351px;
  max-width: 351px;
  flex: 1 1 auto;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 6px 6px 30px rgba(174, 195, 214, 0.29);
  max-height: 657px;

  @media (max-width: 1024px) {
    min-width: 378px;
    max-width: 378px;
    flex: unset;
    height: 570px;
  }

  @media (max-width: 800px) {
    min-width: unset;
    max-width: unset;
    width: 468px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const Balance = styled.div`
  width: 100%;
  height: 75px;
  padding: 30px;
  background-color: ${colors.primary.blue};
  display: flex;
  flex-direction: column;
  place-content: center;
  color: #FFF;
  border-radius: 16px;

  .balance-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  .hidden-balance {
    font-size: 24px;
    line-height: 24px;
  }

  .balance-div {
    display: flex;
    place-content: space-between;
    align-items: center;

    .balance {
      line-height: 24px;
      font-weight: 700;
      font-size: 18px;
    }

    svg {
      font-size: 22px;
      cursor: pointer;
    }
  }
`;

export const History = styled.div`
  height: calc(100% - 130px);
  width: 100%;
  padding: 8px 18px;
  overflow-y: auto;
  position: absolute;
  top: 130px;
  left: 0;

  @media (max-width: 400px) {
    padding: 10px;
  }

  @media (max-width: 357px) {
    height: calc(100% - 160px);
    top: 160px;
  }

  .day-div {
    padding: 0 3px;
  }

  .day-title {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #011032;
  }

  .show-more-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .show-more {
      margin: 10px;
      background-color: ${colors.primary.blue};
      color: #fff;
      padding: 10px 15px;
      border-radius: 5px;
      transition: all 0.2s;

      &:hover {
        background-color: #113585;
      }
    }
  }
`;

export const WrapScroll = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;  
  background-color: #fff;
`

export const Filter = styled.div`
  background-color: #fff;
  padding: 0 18px;

  h1 {
    font-weight: 600;
    font-size: 13.5px;
    line-height: 15px;
    color: #011032;
    margin-top: 13px;
    margin-bottom: 5px;
  }

  button {
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    padding: 7.5px 12px;
    height: 26px;
    margin-right: 3px;
    font-weight: 600;
    font-size: 9px;
    line-height: 10px;
    text-align: center;
    color: #011032;
    justify-content: center;
    align-items: center;

    &:nth-child(2) {
      @media (max-width: 357px) {
        margin-bottom: 3px;
      }
    }

    &:hover {
      background: #011032;
      border-color: #011032;
      color: white;
    }

    &.on {
      background: #011032;
      border-color: #011032;
      color: white;

      &:hover {

      }
    }
  }
`

export const Nothing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70%;
  width: 100%;

  img {
    width: 184px;
  }

  p {
    padding: 0 50px;
    font-weight: 400;
    font-size: 14.5px;
    line-height: 20px;
    margin-top: 13px;
    text-align: center;
  }
`

export const Loading = styled.div`
  &.show {
    position: absolute;
    width: 100%;
    height: calc(100% - 130px);
    display: flex;
    justify-content: center;
    padding-top: 27%;
    bottom: 0;
    z-index: 1;
    background-color: rgba(1, 16, 50, 0.3);
    transition: background-color 0.2s ease-in-out;

    @media (max-width: 357px) {
      height: calc(100% - 160px);
    }
  }
`