import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: calc(100vw - 252px);
  justify-content: center;
  overflow-y: auto;

  @media (max-width: 1024px) {
      width: 100%;
  }
`;

export const SubContainer = styled.div`
  max-width: 1320px;
  width: 100%;
  padding: 22px 14px 0px 14px;

  .header {
    @media (max-width: 1024px) {
      margin: -22px -14px 0px -14px;
    }
  }
`;

interface CircularProps {
    color: string;
}

export const LgCircularSm = styled.div<CircularProps>`
    box-sizing: border-box;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    border: 0.75px solid ${props => props.color};
    align-items: center;
    justify-content: center;
    display: flex;

    .star {
        min-width: 80px;

        @media (max-width: 768px) {
            min-width: 60px;
        }
    }

    @media (max-width: 768px) {
        width: 50px;
        height: 50px;
    }
`

export const LgCircular = styled.div<CircularProps>`
    box-sizing: border-box;
    border-radius: 50%;
    width: 67.5px;
    height: 67.5px;
    border: 0.75px solid ${props => props.color};
    align-items: center;
    justify-content: center;
    display: flex;

    @media (max-width: 768px) {
        width: 58px;
        height: 58px;
    }
`

export const LgCircularLg = styled.div<CircularProps>`
    box-sizing: border-box;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    border: 0.75px solid ${props => props.color};
    align-items: center;
    justify-content: center;
    display: flex;

    @media (max-width: 768px) {
        width: 65px;
        height: 65px;
    }
`

export const CardVideo = styled.div`
    width: 1185px;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 6px 6px 30px rgba(174, 195, 214, 0.29);
    border-radius: 14px;
    padding: 20px 20px 37px 20px;
    margin-left: 6px;
    margin-top: 16px;

    @media (max-width: 1450px) {
        width: 100%;
    }

    @media (max-width: 768px) {
        width: 100%;
    }

    .content {
        display: flex;

        @media (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .video {
        max-height: 298px;
        max-width: 168px;
        margin-left: 15px;
        position: relative;

        button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 35px;
            height: 35px;
            border-radius: 50%;
            border: 0;
            background-color: #FFD60A;
            color: #ffffff;
            text-align: center;
            justify-content: center;
            align-items: center;
            display: flex;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .playing {
        button {
            display: none;
            opacity: 0.7;
        }

        &:hover {
            button {
                display: flex;
            }
        }
    }

    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        color: #011032;
        margin-bottom: 16px;
    }

    .text {
        background: #F7F7F7;
        border-radius: 16px;
        padding: 20px 26px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        
        @media (max-width: 768px) {
            background: unset;
            border-radius: unset;
            padding: 0;
            margin-bottom: 30px;
        }

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
            color: #011032;
            margin-top: 10px;
            
            &:first-child {
                margin-top: 0;
            }
        }
    }
`

export const Video = styled.video`
    width: 100%;
    height: 100%;
    object-fit: content;
    background: rgba(1, 16, 50, 0.3);
    border-radius: 16px;
`

export const ThumbnailCanvas = styled.canvas`
    display: none;
`;

export const Achievements = styled.div`
    width: fit-content;
    height: fit-content;
    padding: 20px 20px 15px 20px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 6px 6px 30px rgba(174, 195, 214, 0.29);
    border-radius: 14px;
    margin-left: 6px;
    margin-top: 16px;

    @media (max-width: 1450px) {
        width: 100%;
    }

    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        color: #011032;
    }

    .plates {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 14px;

        @media (max-width: 1450px) {
            justify-content: start;
            width: 100%;
            overflow-x: auto;
            padding: 0 10px 10px 10px
        }
    }
`

export const PlateTitle = styled.h2<CircularProps>`
    margin-top: 15px;
    margin-bottom: 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${props => props.color};

    @media (max-width: 768px) {
        margin-bottom: 40px;
    }
`

export const PlateText = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    padding: 0 10px;
    text-align: center;
    color: #FFFFFF;
    height: 109px;

    @media (max-width: 768px) {
        height: 90px;
        padding: 0px;
        font-size: 13px;
        line-height: 16px;
    }

    span {
        font-weight: 700;
        white-space: nowrap;
    }
`