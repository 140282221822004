import React, { ReactNode } from 'react';

import { Container } from './styles';

import kit from '../../assets/plate-kit.png';
import kitLg from '../../assets/plate-kit-lg.png';
import kitDisabled from '../../assets/plate-kit-disabled.png';
import kitAward from '../../assets/plate-kit-award.png';
import star from '../../assets/star-plate-kit.png';
import starLg from '../../assets/star-plate-kit-lg.png';
import stawAward from '../../assets/star-plate-kit-award.png';
import starDisabled from '../../assets/star-plate-kit-disabled.png';

interface PlateKitProps {
    size: string,
    disabled?: boolean,
    content?: ReactNode
}

const PlateKit: React.FC<PlateKitProps> = ({ size, disabled, content }) => {
    return (
        <Container className={size}>
            {
                size === 'award' ? (
                    <img src={kitAward} alt="kit" className='award'/>
                ) : size === 'lg' ? (
                    <img src={kitLg} alt="kit" className='kit'/>
                ) : (
                    <img src={disabled ? kitDisabled : kit} alt="kit" className='kit'/>
                )
            }
            {
                size === 'award' ? (
                    <img src={stawAward} alt="star" className='star-award'/>
                ) : size === 'lg' ? (
                    <img src={starLg} alt="star" className='star'/>
                ) : (
                    <img src={disabled ? starDisabled : star} alt="star" className={disabled ? 'disabled' : 'star'}/>
                )
            }
            {content}
        </Container>
    )
};

export default PlateKit;