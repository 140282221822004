import React, { ReactNode, useContext } from "react";

import { Container, SubContainer } from "./styles";

import { IoIosArrowUp } from "react-icons/io";

import { useHistory } from "react-router-dom";

import mixPanelTrack from "../../../mixpanel";

import { UserContext } from "../../../context/userContext";

interface SubmenuProps {
  path: string;
  title: string;
  mixName: string;
}

interface MenuButtonProps {
  onClick: () => void;
  paths: string[];
  icon: ReactNode;
  title: string;
  stateSubmenu?: boolean;
  submenus?: SubmenuProps[];
}

const MenuButton: React.FC<MenuButtonProps> = ({
  onClick,
  paths,
  icon,
  title,
  submenus,
  stateSubmenu,
}) => {
  const history = useHistory();
  const { userData } = useContext(UserContext);

  const goPage = async (page: string, mixName: string) => {
    mixPanelTrack("Menu", { menu_name: mixName, user_smartico: userData.smartico_id });
    history.push(page)
  }

  return (
    <>
      <Container
        onClick={onClick}
        className={paths.includes(document.location.pathname) ? "active" : ""}
      >
        {icon}
        {submenus && submenus.length > 0 ? (
          <div className="submenu-button">
            <span>{title}</span>
            <IoIosArrowUp
              className={`submenu-toggle ${stateSubmenu ? "active" : ""}`}
              size={13}
            />
          </div>
        ) : (
          <span>{title}</span>
        )}
      </Container>
      <div>
      {submenus &&
        submenus.length > 0 &&
        submenus.map((sub: SubmenuProps) => (
          <SubContainer className={stateSubmenu ? "active" : ""} key={sub.path}>
            <button
              className={
                document.location.pathname === sub.path ? "active" : ""
              }
              onClick={() => goPage(sub.path, sub.mixName)}
            >
              {sub.title}
            </button>
          </SubContainer>
        ))}
      </div>
    </>
  );
};

export default MenuButton;
