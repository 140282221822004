import styled from 'styled-components';

export const Container = styled.div`
  width: 500px;
  background: #FFFFFF;
  box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 25px 22px 15px 22px;
  @media (max-width: 1200px) {
    width: 100%;
  }
  h3 {
    @media (max-width: 500px) {
      margin-left: 14px;
      line-height: 15px;
    }
  }
  .content {
    background: #F7F7F7;
    border-radius: 14px;
    padding: 8px 24px;
    margin: 8px 0px 4px 0px;
    @media (max-width: 500px) {
      margin: 19px 14px;
      padding: 17px
    }
    p {
      margin-bottom: 4px;
      @media (max-width: 768px) {
        margin-bottom: 8px;
      }
      @media (max-width: 500px) {
        line-height: 15px;
      }
    }
  }
  button {
    padding: 5px 10px;
    border: 1px solid #011032;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    &:hover {
      background-color: #011032;
      color: #fff;
      transition: 0.2s all;
    }
  }
  .button-div {
    display: flex;
    justify-content: center;
    margin-top: 17px;
  }
`