import React from 'react';
import { Container, Option } from './styles';

interface FilterByStatusProps {
    onClose: () => void;
    handleFilter: () => void;
    mb?: boolean;
}

const FilterByStatus: React.FC<FilterByStatusProps> = ({ 
    onClose,
    handleFilter,
    mb
}) => {
    return (
        <Container className={mb ? 'mb' : ''}>
          <Option className='all'>
            Todos
          </Option>
          <Option className='approved'>
            Aprovados
          </Option>
          <Option className='reproved'>
            Reprovados
          </Option>
          <Option className='pending'>
            Pendentes
          </Option>
          <Option className='disabled'>
            Desativados
          </Option>
        </Container>
    )
}

export default FilterByStatus;