import React, { useState } from 'react';

import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';

import { FaEye, FaEyeSlash } from "react-icons/fa";

import { Container } from '../styles';

interface PasswordInputProps {
  placeholder: string,
  label: string,
  change: (e: string) => void,
  isError?: boolean,
  msgError?: string
}

const PasswordInput: React.FC<PasswordInputProps> = ({ placeholder, label, change, isError, msgError }) => {
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  return (
    <Container>
      <label>{label}</label>
      <InputGroup size='md'>
        <Input
          onChange={e => change(e.target.value)}
          className="custom-input"
          type={show ? 'text' : 'password'}
          placeholder={placeholder}
          isInvalid={isError}
        />
        <InputRightElement width='3.5rem' height='100%'>
          {show ? <FaEyeSlash onClick={handleClick} /> : <FaEye onClick={handleClick} />}
        </InputRightElement>
      </InputGroup>
      {isError && <p className="error-msg">{msgError}</p>}
    </Container>
  );
}

export default PasswordInput;