import React from 'react';

import { Container } from './styles';

import { CircularProgress } from '@chakra-ui/react';

interface LoadingScreenProps {
  show: boolean,
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ show = false }) => {
  return (
        <>
            {show && (
                <Container>
                    <CircularProgress isIndeterminate color='#011032' />
                </Container>
            )}
        </>
    )
}

export default LoadingScreen;