import styled from "styled-components";
import { colors } from "styles/tokens/color";

export const Container = styled.div`
  width: calc(100vw - 252px);
  display: flex;
  justify-content: center;
  overflow-y: auto;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const SubContainer = styled.div`
  max-width: 1320px;
  width: 100%;
  padding: 20px 14px 14px 14px;
  height: unset;

  .header {
    @media (max-width: 1024px) {
      margin: -20px -14px 20px -14px;
    }

    button {
      height: 44px;
      border-radius: 5px;
      box-shadow: none;
    }
  }

  .header-table {
    display: flex;
    align-items: center;
    margin: 24px 0 20px 0;

    @media (max-width: 700px) {
      display: grid;
    }

    h1 {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #011032;
      margin-right: 30px;

      @media (max-width: 700px) {
        margin-bottom: 10px;
        margin-right: 0;
      }
    }

    input {
      width: 410px;
      height: 44px;
      background: #ffffff;
      border-radius: 5px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      padding: 0 15px;

      @media (max-width: 1100px) {
        width: 100%;
      }
    }
  }
`;

export const OverlayLoading = styled.div`
  display: none;

  &.show {
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 16, 50, 0.3);
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    transition: color 0.2s;
  }
`;

export const Wrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 70px 0px;

  .no-requests {
    width: 100%;
    padding: 30px 0px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;

    @media (max-width: 575px) {
      margin-top: 20px;
      border-radius: 16px 16px 0px 0px;
    }

    svg {
      height: 50%;
    }

    .empty-text {
      margin-top: 20px;
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      color: ${colors.primary.blue};
    }
  }
`;

export const PaginationBar = styled.div`
  padding: 20px;
  display: flex;
  align-items: end;
  place-content: end;
  background: #ffffff;
  box-shadow: 0px 18px 30px -15px rgba(0, 0, 0, 0.12),
    -15px 0px 10px -15px rgba(0, 0, 0, 0.12),
    15px 0px 10px -15px rgba(0, 0, 0, 0.12);
  z-index: 0;

  @media (max-width: 575px) {
    place-content: center;
  }

  .pagination-info {
    display: flex;
    align-items: center;

    span {
      font-size: 13px;
      background: #d9d9d9;
      border-radius: 2px;
      padding: 4px 8px;
      margin-right: 10px;
    }

    svg {
      font-size: 24px;
    }
  }
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 700px) {
    display: block;
  }
`;

export const ButtonExport = styled.button`
  padding-right: 20px;
  cursor: pointer;
  color: #117a41;
`;
