import React from "react";

import { Affiliates } from "../TableAffiliatesManager";
import { WrapScroll, Container, Item, ActionButton } from "./styles";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import CustomTooltip from "components/CustomTooltip";
import { format } from "date-fns";
// import { Switch } from "@chakra-ui/react";

interface MobileTableAffiliatesManagerProps {
  affiliates: Affiliates[];
  onApprove: (affiliates: Affiliates) => void;
  onReprove: (affiliates: Affiliates) => void;
  onDisable: (affiliates: Affiliates) => void;
}

const MobileTableAffiliatesManager: React.FC<MobileTableAffiliatesManagerProps> = ({
  affiliates,
  onApprove,
  onReprove,
  // onDisable,
}) => {
  const getPercentage = (value: any) => {
    if (value) {
      return value + '%';
    }
    return "N/A";
  };

  const getValue = (value: any) => {
    if (value) {
      return value;
    }
    return "N/A";
  };

  const formatDate = (value: string) => {
    if (value) {
      return format(new Date(value), 'dd-MM-yyyy HH:mm:ss');
    }
    return "N/A";
  }

  const getStatus = (status: string) => {
    if (status === "PENDING") {
      return <span className="right"><span className="pending">Pendente</span></span>
    } else if (status === "APPROVED") {
      return <span className="right"><span className="accepted">Aceito</span></span>
    } else if (status === "REFUSED") {
      return <span className="right"><span className="refused">Reprovado</span></span>
    } else if (status === "DISABLED") {
      return <span className="right"><span className="disabled">Desativado</span></span>
    }
  }

  const renderActions = (affiliate: Affiliates) => {
    const { status } = affiliate;

    if (status === "PENDING") {
      return (
        <>
          <CustomTooltip label="Aprovar">
            <ActionButton type="approve" onClick={() => onApprove(affiliate)}>
              <BiCheckCircle />
            </ActionButton>
          </CustomTooltip>
          <CustomTooltip label="Reprovar">
            <ActionButton type="reprove" onClick={() => onReprove(affiliate)}>
              <BiXCircle />
            </ActionButton>
          </CustomTooltip>
        </>
      )
    } else if (status === "APPROVED") {
      // return <Switch isChecked onChange={() => onDisable(affiliate)}/>
      return <React.Fragment/>;
    }
    return <React.Fragment/>;
  }

  return (
    <WrapScroll>
      <Container>
        {affiliates.map((affiliate, index) => (
          <Item key={index}>
            <div className="row">
              <span className="left">ID Afiliado</span>
              <span className="right">{getValue(affiliate.id)}</span>
            </div>
            <div className="row">
              <span className="left">Nickname</span>
              <span className="right">{getValue(affiliate.nick)}</span>
            </div>
            <div className="row">
              <span className="left">Nome</span>
              <span className="right">{getValue(affiliate.name)}</span>
            </div>
            <div className="row">
              <span className="left">Status</span>
              <span className="right">{getStatus(affiliate.status)}</span>
            </div>
            <div className="row">
              <span className="left">Data de cadastro</span>
              <span className="right">{formatDate(affiliate.date_created)}</span>
            </div>
            <div className="row">
              <span className="left">CPA</span>
              <span className="right">{getValue(affiliate.cpa)}</span>
            </div>
            <div className="row">
              <span className="left">Porcentagem</span>
              <span className="right">{getPercentage(affiliate.baseline)}</span>
            </div>
            <div className="row">
              <span className="left">Ações</span>
              <span className="right">
                {renderActions(affiliate)}
              </span>
            </div>
          </Item>
        ))}
      </Container>
    </WrapScroll>
  );
};

export default MobileTableAffiliatesManager;
