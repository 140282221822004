import React, { useContext, useEffect, useRef } from 'react';

import { UserContext } from '../../../context/userContext';
import { useHistory } from 'react-router-dom';

import MenuTopButtons from '../MenuTopButtons';

//Estilização
import { Container, Head, Buttons, BottomButtons, Button } from './styles';
import { MdLogout, MdMenuOpen, MdAccountCircle } from "react-icons/md";
// TODO: Comentado para logo temporário
// import logoPartners from '../../../assets/Partners/logo-partners.png';
import { starTemporaryLogo } from 'assets/Partners/starTemporaryLogo';

import mixPanelTrack from '../../../mixpanel';

interface MenuProps {
  logout: () => void
}

const MobileDrawer: React.FC<MenuProps> = ({ logout }) => {
  const myRef = useRef<any>();
  //Context
  const { userData, expandMenu, setExpandMenu } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleClickOutside = (e: any) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      setExpandMenu(false);
    }
  };

  const goPage = async (page: string, mixName: string) => {
    mixPanelTrack('Menu', { menu_name: mixName, user_smartico: userData.smartico_id })
    setExpandMenu(false)
    history.push(page)
  }

  return (
    <Container ref={myRef} className={expandMenu === true ? 'expanded' : ''}>
      <Head>
        <div className="top-div">
          <MdMenuOpen onClick={() => setExpandMenu(!expandMenu)} />
          <div className="logo">
            {/* <img src={logoPartners} alt="partners-img" className='partners-img' /> */}
            {starTemporaryLogo}
          </div>
        </div>
        <div className="bottom-div">
          <MdAccountCircle className='profile-avatar' />
          <p>Olá, {userData.name}</p>
        </div>
      </Head>
      <Buttons>
        <MenuTopButtons goPage={goPage}/>
        <BottomButtons>
          {/* <Button>
            <BsFillGearFill />
            <span>Configurações</span>
          </Button> */}
          <Button onClick={logout}>
            <MdLogout />
            <span>Sair</span>
          </Button>
        </BottomButtons>
      </Buttons>
    </Container>
  );
}

export default MobileDrawer;