import React, { useContext } from 'react';
import {
  Container,
  Card,
  InfoTitle,
  InfoText,
  ActionButtonsContainer,
  SignButton,
  CloseButton
} from './styles';

import axios from 'axios';
import endPoints from 'utils/endPoints';
import mixPanelTrack from "mixpanel";
import { useToast } from '@chakra-ui/react';
import { Integration, SubscriptionStatus } from 'types/Integration';
import { UserContext } from 'context/userContext';

interface IntegrationModalProps {
  onClose: () => void;
  refreshIntegrations: () => void;
  integration: Integration;
}

const IntegrationModal: React.FC<IntegrationModalProps> = ({
  onClose,
  refreshIntegrations,
  integration
}) => {
  const texts = {
    title: 'INFORMAÇÕES DA ASSINATURA',
    // Mais para frente essa mensagem deve ser dinâmica de acordo com o tipo de assinatura (ex.: Se não for trial) e nome também.
    info: 'Por favor, confirme abaixo para prosseguir com a assinatura para utilizar o Cassino Score. Essa é uma assinatura gratuita que permitirá a você criar múltiplas salas de sinais.',
    sign: 'Assinar',
    notNow: 'Agora não',
    success: 'Assinatura realizada com sucesso!',
    error: 'Erro ao tentar realizar assinatura. Tente novamente mais tarde.',
  }

  const toast = useToast();
  const { subscribe_integration } = endPoints;
  const { userData } = useContext(UserContext);

  const onSubscribe = async () => {
    const url = subscribe_integration
    const payload = {
      subscription_type: { id: integration.id },
      subscription_status: { id: SubscriptionStatus.TRIAL }
    }

    axios.post(url, payload).then(() => {
      onClose();
      toast({
        title: texts.success,
        status: 'success',
        position: 'top',
        isClosable: true,
      })

      mixPanelTrack('Assinar integração', {
        integration: integration.title,
        user_smartico: userData.smartico_id
      });

      refreshIntegrations();
    }).catch(() => {
      onClose();
      toast({
        title: texts.error,
        status: 'error',
        position: 'top',
        isClosable: true,
      })
    })
  }

  const onUserClose = async () => {
    onClose();
    mixPanelTrack('Usuário acessou ao pop-up de assinatura e fechou', {
      integration: integration.title,
      user_smartico: userData.smartico_id
    });
  }

  return (
    <Container>
      <Card>
        <InfoTitle>{texts.title}</InfoTitle>
        <InfoText>{texts.info}</InfoText>
        <ActionButtonsContainer>
          <CloseButton onClick={() => onUserClose()}>{texts.notNow}</CloseButton>
          <SignButton onClick={() => onSubscribe()}>{texts.sign}</SignButton>
        </ActionButtonsContainer>
      </Card>
    </Container>
  )
}

export default IntegrationModal;