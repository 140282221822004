import React from 'react';
import { Container, ErroMsg } from './styles';

interface FilterProps {
    qtdTime: number | null;
    setQtdTime: (number: number | null) => void;
    qtdTimeError: boolean;
    qtdTimeMsgError: string;
    myRef?: any;
    onClose: () => void;
    handleFilter: () => void;
    mb?: boolean;
}

const Filter: React.FC<FilterProps> = ({ 
    qtdTime,
    setQtdTime,
    qtdTimeError,
    qtdTimeMsgError,
    myRef, 
    onClose,
    handleFilter,
    mb
}) => {

    const days = [
        1, 7, 15, 30 
    ]

    const handleTime = (time: number, type: number) => {
        if (time === qtdTime) {
            setQtdTime(null);
            return;
        }
    
        setQtdTime(time);
    }

    return (
        <Container ref={myRef} className={mb ? 'mb' : ''}>
            <h1>Selecionar o período</h1>
            <div className='buttons-select'>
                {
                    days.map(day => (
                        <button
                            key={day}
                            className={qtdTime === day ? 'select on' : 'select'} 
                            onClick={() => handleTime(day, 2)}
                        >
                            {day === 1 ? `${day} dia` : `${day} dias`}
                        </button>
                    ))
                }
            </div>
            <ErroMsg show={qtdTimeError}>
                {qtdTimeMsgError}
            </ErroMsg>
            <div className='buttons-actions'>
                <button onClick={onClose}>Cancelar</button>
                <button onClick={handleFilter}>OK</button>
            </div>
        </Container>
    )
}

export default Filter;