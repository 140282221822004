import styled from 'styled-components';

export const Container = styled.div`
    width: fit-content;
    height: fit-content;
    padding: 20px 20px 15px 20px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 6px 6px 30px rgba(174, 195, 214, 0.29);
    border-radius: 14px;
    margin-left: 6px;
    margin-top: 16px;

    @media (max-width: 590px) {
        width: calc(100% - 6px);
        justify-content: center;
    }


    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        color: #011032;
    }

    .plates {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 14px 0px;

        @media (max-width: 590px) {
            padding: 14px 0
        }

        @media (max-width: 460px) {
            margin-left: 13px;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }
`

export const Text = styled.div`
    margin-top: 18px;

    &.sirius {
        margin-top: 16px;
    }

    &.pollux {
        margin-top: 14px;
    }
`