import React, { useEffect, useState, useContext } from 'react';

// Components
import WithdrawBoxForm from '../../../components/Withdraws/WithdrawBoxForm';
import CustomInput from '../../../components/Inputs/CustomInput';
import CustomInputMask from '../../../components/Inputs/CustomInputMask';
import CustomFile from '../../../components/Inputs/CustomFile';
import CustomSelect from '../../../components/Inputs/CustomSelect';
import WithdrawBoxInfo from '../../../components/Withdraws/WithdrawBoxInfo';
import HeaderComponent from '../../../components/HeaderComponent';

//Úteis
import { withdrawPJSchema } from '../../../utils/validateSchemas';
import endPoints from '../../../utils/endPoints';
import { toBase64 } from '../../../utils/tobase64';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { IoDocumentText } from 'react-icons/io5';
import { UserContext } from '../../../context/userContext';

// Estilização
import { Container, SubContainer, SubHeader, ContractSelected } from './styles';
import LoadingScreen from '../../../components/LoadingScreen';

// Mixpanel
import mixPanelTrack from '../../../mixpanel';

// Interfaces
interface IOptions {
  text: string;
  value: string;
}

interface IBank {
  COMPE: string;
  LongName: string;
}

const WithdrawPJ: React.FC = () => {
  // Úteis
  const toast = useToast();
  const { userData } = useContext(UserContext);

  // Constantes Estáticas
  const { invoice, users } = endPoints;

  // UseStates
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const [options, setOptions] = useState<IOptions[]>([]);
  const [block, setBlock] = useState(false);

  const [value, setValue] = useState('');
  const [valueError, setValueError] = useState(false);
  const [valueMsgError, setValueMsgError] = useState('');

  const [bank, setBank] = useState('');
  const [bankError, setBankError] = useState(false);
  const [bankMsgError, setBankMsgError] = useState('');

  const [agency, setAgency] = useState('');
  const [agencyError, setAgencyError] = useState(false);
  const [agencyMsgError, setAgencyMsgError] = useState('');

  const [account, setAccount] = useState('');
  const [accountError, setAccountError] = useState(false);
  const [accountMsgError, setAccountMsgError] = useState('');

  const [invoiceFile, setInvoiceFile] = useState('');
  const [invoiceFileError, setInvoiceFileError] = useState(false);
  const [invoiceFileMsgError, setInvoiceFileMsgError] = useState('');

  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceNumberError, setInvoiceNumberError] = useState(false);
  const [invoiceNumberMsgError, setInvoiceNumberMsgError] = useState('');

  const [contract, setContract] = useState('');
  const [contractError, setContractError] = useState(false);
  const [contractMsgError, setContractMsgError] = useState('');

  const [contractName, setContractName] = useState('');
  const [invoiceFileName, setInvoiceFileName] = useState('');
  const [valueI, setValueI] = useState('');

  //UseEffects
  useEffect(() => { setValueError(false) }, [value])
  useEffect(() => { setBankError(false) }, [bank])
  useEffect(() => { setAgencyError(false) }, [agency])
  useEffect(() => { setAccountError(false) }, [account])
  useEffect(() => { setInvoiceFileError(false) }, [invoiceFile])
  useEffect(() => { setInvoiceNumberError(false) }, [invoiceNumber])
  useEffect(() => { setContractError(false) }, [contract])

  useEffect(() => {
    const getBanks = async () => {
      const url = "https://guibranco.github.io/BancosBrasileiros/data/bancos.json";

      const httpRequest = new XMLHttpRequest();
      httpRequest.open("GET", url);
      httpRequest.responseType = "json";

      httpRequest.addEventListener("readystatechange", function () {
        if (httpRequest.readyState === 4) {
          if (httpRequest.status === 200) {
            const banks = httpRequest.response.map(({ COMPE, LongName }: IBank) => `${COMPE}, ${LongName}`)
            mapOptions(banks);
          }
        }
      });
      httpRequest.send();
    };
    getBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  useEffect(() => {
    getBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getContract = async () => {
      setLoading(true);

      const url = `${invoice}/0/2`;

      axios.get(url).then((response) => {
        setContractName(response.data.file_name)
        setContract(response.data.file)
        setBlock(false);
        setLoading(false);
      }).catch((e) => {
        setBlock(true);
        setLoading(false);
      });
    };
    getContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  const getBalance = async () => {
    // const token = sessionGet('estrela_af_tk');
    const url = `${users}/user`;

    axios.get(url).then((response) => {
      setBalance(response.data.balance);
    }).catch((e) => {
      toast({
        title: 'Erro ao buscar saldo',
        status: 'error',
        position: 'top',
        isClosable: true,
      });
      console.log(e);
    });
  };

  const createInvoiceRequest = async () => {

    const customVal = customValidation()
    if (!customVal) {
      return;
    }

    setLoading(true);

    const url = `${invoice}/upload/0`;

    axios.post(url, { value: Number(value), bank: bank, agency: agency, account: account, file: invoiceFile, invoiceNumber: invoiceNumber, contractFile: contract, file_name: contractName }).then(() => {
      // Zerar values
      toast({
        title: 'Nota Fiscal enviada com sucesso!',
        status: 'success',
        position: 'top',
        isClosable: true,
      })
      setValue('');
      setValueI('');
      setBank('');
      setAgency('');
      setAccount('');
      setInvoiceFile('');
      setInvoiceNumber('');
      setInvoiceFileName('');
      let noteFile: any = document.getElementById('nota-fiscal');
      if (noteFile) {
        noteFile.value = null;
      }
      getBalance();
      setLoading(false);
      mixPanelTrack('Saque - Solicitação de Saque', { type: 'Nota Fiscal', user_smartico: userData.smartico_id, value: Number(value) });
    }).catch((e) => {
      toast({
        title: e.response.data.message,
        status: 'error',
        position: 'top',
        isClosable: true,
      })
      setLoading(false);
      mixPanelTrack('Saque - Erro ao solicitar saque', { type: 'Nota Fiscal', user_smartico: userData.smartico_id, value: Number(value) });
      console.log(e);
    })
  }

  const putFile = async () => {
    const file = document.getElementById('fileid') as HTMLInputElement;
    file.click();
    file.onchange = async () => {
      if (file.files !== null) {
        const base64_file = await toBase64(file.files[0]);

        const url = `${invoice}/upload/1`;

        axios.post(url, { file: base64_file, file_name: file.files[0].name }).then((response) => {
          if (typeof (base64_file) === 'string') {
            setContract(base64_file);
          }
          if (file.files) {
            setContractName(file.files[0].name)
          }
          setBlock(false);
          toast({
            title: 'Contrato Social cadastrado com sucesso',
            status: 'success',
            position: 'top',
            isClosable: true,
          })
          mixPanelTrack('Saque - Upload Contrato Social', { user_smartico: userData.smartico_id });
        }).catch((e) => {
          toast({
            title: 'Não foi possível enviar o arquivo',
            status: 'error',
            position: 'top',
            isClosable: true,
          })
        })
      } else {
        toast({
          title: 'Documento inválido',
          status: 'error',
          position: 'top',
          isClosable: true,
        })
      }
      setLoading(false);
    }
  }

  // Methods
  const mapOptions = (data: string[]) => {
    let options: IOptions[];

    options = data.map((item: string, index) => {
      return { id: item, text: item, value: item.substring(0, 3) }
    })

    setOptions(options);
  }

  const submitForm = async (e: any) => {
    e.preventDefault();

    if (await validationForm() === false) {
      return
    }

    createInvoiceRequest();
  }

  const downloadFile = () => {
    let file = contract;
    let name = contractName;
    var a = document.createElement("a");
    a.href = file;
    a.download = name;
    a.click();
  }


  const validationForm = async () => {

    if (balance === 0) {
      toast({
        title: 'Saldo indisponível',
        status: 'error',
        position: 'top',
        isClosable: true,
      })
      return false
    }

    const withdrawPJData = {
      value,
      bank,
      agency,
      account,
      invoiceFile,
      invoiceNumber,
      contract,
    }

    let errors: any = [];

    await withdrawPJSchema.validate(withdrawPJData, { abortEarly: false }).catch((err) => {
      err.inner.forEach((e: any) => {
        errors.push({ path: e.path, message: e.message })
      });
      return err;
    });

    const isValid = await withdrawPJSchema.isValid(withdrawPJData);

    if (isValid === false) {
      errors.forEach((error: any) => {
        if (error.path === 'value') {
          setValueError(true);
          setValueMsgError(error.message);
        }
        if (error.path === 'bank') {
          setBankError(true);
          setBankMsgError(error.message);
        }
        if (error.path === 'agency') {
          setAgencyError(true);
          setAgencyMsgError(error.message);
        }
        if (error.path === 'account') {
          setAccountError(true);
          setAccountMsgError(error.message);
        }
        if (error.path === 'invoiceFile') {
          setInvoiceFileError(true);
          setInvoiceFileMsgError(error.message);
        }
        if (error.path === 'invoiceNumber') {
          setInvoiceNumberError(true);
          setInvoiceNumberMsgError(error.message);
        }
        if (error.path === 'contract') {
          setContractError(true);
          setContractMsgError(error.message);
        }
      })
      return false
    } else {
      return true
    }
  }

  const customValidation = () => {
    if (balance === 0) {
      toast({
        title: 'Você não possui saldo para realizar a requisição de saque',
        status: 'error',
        position: 'top',
        isClosable: true,
      })
      return false
    } else if (Number(value) > balance) {
      toast({
        title: 'Valor de saque não pode ser maior que o saldo disponível',
        status: 'error',
        position: 'top',
        isClosable: true,
      })
      return false
    } else if (Number(value) < 1) {
      toast({
        title: 'Valor de saque deve ser maior que R$ 0,99',
        status: 'error',
        position: 'top',
        isClosable: true,
      })
      return false
    } else {
      return true
    }
  }

  return (
    <Container translate="no">
      <LoadingScreen show={loading} />
      <SubContainer>
        <div className='header'>
          <HeaderComponent title='SAQUE | Saque NF' />
        </div>
        <SubHeader className={block ? '' : 'no-show'}>
          <div>
            Para a realizaçao do Saque com Nota Fiscal, seu Contrato Social precisa estar cadastrado em nosso Sistema
            <button onClick={putFile}>
              <IoDocumentText size='23px' />
              <span>Cadastrar Contrato Social</span>
            </button>
            <input id='fileid' type='file' hidden />
          </div>
        </SubHeader>
        <div className='content'>
          <WithdrawBoxForm
            block={block}
            title='Requisição de Saque com Nota Fiscal'
            textContent={
              <h2 className={block ? 'block' : ''}>
                Saldo disponível: {balance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </h2>
            }
            submit={submitForm}
            content={
              <>
                <CustomInputMask
                  change={setValue}
                  value={value}
                  placeholder="Valor"
                  type="number"
                  isError={valueError}
                  msgError={valueMsgError}
                  readonly={block}
                  valueI={valueI}
                  setValueI={setValueI} // add this line to pass the `setValueI` prop
                />
                <CustomSelect
                  change={setBank}
                  value={bank}
                  placeholder="Banco"
                  isError={bankError}
                  msgError={bankMsgError}
                  options={options}
                  readonly={block}
                />
                <CustomInput
                  change={setAgency}
                  value={agency}
                  placeholder="Agência"
                  type="text"
                  isError={agencyError}
                  msgError={agencyMsgError}
                  readonly={block}
                />
                <CustomInput
                  change={setAccount}
                  value={account}
                  placeholder="Conta"
                  type="text"
                  isError={accountError}
                  msgError={accountMsgError}
                  readonly={block}
                />
                <CustomFile
                  id='nota-fiscal'
                  change={setInvoiceFile}
                  placeholder="Anexar Nota Fiscal"
                  isError={invoiceFileError}
                  msgError={invoiceFileMsgError}
                  fileName={invoiceFileName}
                  readonly={block}
                  changeName={setInvoiceFileName}
                />
                <CustomInput
                  change={setInvoiceNumber}
                  value={invoiceNumber}
                  placeholder="Número da Nota Fiscal"
                  type="text"
                  isError={invoiceNumberError}
                  msgError={invoiceNumberMsgError}
                  readonly={block}
                />
                <CustomFile
                  id='social-contract'
                  change={setContract}
                  placeholder="Atualize seu Contrato Social sempre que houver alterações"
                  isError={contractError}
                  msgError={contractMsgError}
                  fileName={contractName}
                  readonly={block}
                  changeName={setContractName}
                />
                <ContractSelected className={block ? 'block' : ''}>
                  <span onClick={() => downloadFile()} className="file-name">{contractName}</span>
                  {/* Removido mas existe possibilidade de implementação */}
                  {/* <IoCloseSharp onClick={() => { deleteContract() }} /> */}
                </ContractSelected>
              </>
            }
            textButton='Confirmar Solicitação'
          />
          <div className='conditional-display'>
            <WithdrawBoxInfo
              block={block}
              title="Instruções para emissão de Nota Fiscal"
              content={
                <>
                  <p className={block ? 'block-p' : ''}><span className='point'>•</span> A Nota Fiscal deverá estar no <b>formato PDF</b> e não ultrapassar o limite de <b>tamanho de 15MB</b>.</p>
                  <p className={block ? 'block-p' : ''}><span className='point'>•</span> Nota Fiscal, Contrato Social e Banco precisam ter o mesmo CNPJ do solicitante.</p>
                  <p className={block ? 'block-p' : ''}><span className='point'>•</span> <b>Tomador do Serviço</b>: Stars Investments</p>
                  <p className={block ? 'block-p' : ''}><span className='point'>•</span> Ao solicitar o Saque, seu saldo será zerado e no extrato será registrado um saque com o status de "Pendente".</p>
                  <p className={block ? 'block-p' : ''}><span className='point'>•</span> Aguarde o retorno do Setor Financeiro, consultando o status da sua requisição.</p>
                </>
              }
            />
          </div>
        </div>
      </SubContainer>
    </Container>
  );
}

export default WithdrawPJ;