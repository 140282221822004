import React, { useState, useEffect, useRef } from 'react';

import HeaderComponent from '../../../components/HeaderComponent';
import SimpleCard from '../../../components/Performance/SimpleCard';
import ComissionCard from '../../../components/Performance/ComissionCard';
import DetailsCard from '../../../components/Performance/DetailsCard';
import Filter from '../../../components/Performance/Filter';

import { FaFilter } from 'react-icons/fa';
import { TbChevronDown } from 'react-icons/tb'; 
import { BiCalendar } from 'react-icons/bi';

import { Container, Header, Cards, Overlay, Double, Content, OverlayLoading, InfoCard, SubContainer } from './styles';

import { Menu, MenuButton, MenuList, Modal, ModalOverlay, ModalContent, useDisclosure, CircularProgress, useToast } from '@chakra-ui/react'

import axios from 'axios';
import endPoints from '../../../utils/endPoints';
import { PerformancesProps, performancesDefault, DetailsProps, detailsDefault } from './utils';
import { performanceSchema } from '../../../utils/validateSchemas';

const Performance: React.FC = () => {

  const lastMonth = new Date(new Date().setDate(new Date().getDate() - 30))

  //Form consts
  const [startDate, setStartDate] = useState<Date | null>(lastMonth); 
  const [startDateError, setStartDateError] = useState(false);
  const [startDateMsgError, setStartDateMsgError] = useState('');

  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [endDateError, setEndDateError] = useState(false);
  const [endDateMsgError, setEndDateMsgError] = useState('');

  const [qtdTime, setQtdTime] = useState<number | null>(30);
  const [qtdTimeError, setQtdTimeError] = useState(false);
  const [qtdTimeMsgError, setQtdTimeMsgError] = useState('');

  const [qtdTimeFaster, setQtdTimeFaster] = useState<number | null>(30);

  const [typeTime, setTypeTime] = useState<number | null>(2);

  //Other consts
  const myRef = useRef<any>();
  const myRef2 = useRef<any>();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast();
  const [showFilter, setShowFilter] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [performances, setPerformances] = useState<PerformancesProps>(performancesDefault);
  const [details, setDetails] = useState<DetailsProps>(detailsDefault);

  // const [timeSelected, setTimeSelected] = useState<number | null>();
  // const [lastText, setLastText] = useState<string>('Últimos 30 dias');
  
  const lastText = 'Período Comparativo'
  
  const [startDateText, setStartDateText] = useState<string>('N/A');
  const [endDateText, setEndDateText] = useState<string>('N/A');

  const { performance } = endPoints;
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  useEffect(() => {
    const getPerformance = async () => {
      setShowLoading(true);

      let url = performance;
      
      axios.get(url).then((response) => {
        setPerformances(response.data);
        setShowLoading(false);
      }).catch((e) => {
        setShowLoading(false);
        toast({
          title: 'Erro ao buscar dados, tente novamente mais tarde.',
          status: 'error',
          position: 'top',
          isClosable: true,
        })
      })
    };
    getPerformance();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setDetails ({
      ConversionRate: performances.ConversionRate,
      FtdCount: performances.FtdCount,
      FtdTotal: performances.FtdTotal,
      QftdCount: performances.QftdCount,
    })
  }, [performances])
  
  useEffect(() => {
    if (qtdTime !== null && startDate !== null) {
  
      const initialDate = new Date(startDate); // cria uma cópia da data inicial
      initialDate.setDate(initialDate.getDate() - 1); // subtrai um dia

      const test = new Date(initialDate.getTime());

      if (typeTime === 1) {
        setStartDateText(formatDateText(  new Date(test.setDate(initialDate.getDate() - qtdTime * 30) )))
      } else {
        setStartDateText(formatDateText(  new Date(test.setDate(initialDate.getDate() - qtdTime) )))
      }
      
      setEndDateText(formatDateText(initialDate));
    } else {
      setStartDateText('N/A');
      setEndDateText('N/A');
    }
  }, [qtdTime, typeTime, startDate]);

  useEffect(() => { setStartDateError(false) }, [startDate])
  useEffect(() => { setEndDateError(false) }, [endDate])
  useEffect(() => { setQtdTimeError(false) }, [qtdTime])

  const handleClickOutside = (e: any) => {
    if (myRef.current && !myRef.current.contains(e.target) && myRef2.current && !myRef2.current.contains(e.target) && !showLoading && showFilter ) {
      setShowFilter(false);
    }
  };

  const formatDate = (data: Date) => {
    const ano = data.getFullYear();
    const mes = ("0" + (data.getMonth() + 1)).slice(-2);
    const dia = ("0" + data.getDate()).slice(-2);
    return `${ano}-${mes}-${dia}`;
  }  

  const formatDateText = (date: Date) => {
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`
  }

  const validationForm = async () => {

    const performanceData = {
      startDate,
      endDate,
      qtdTime,
    }

    let errors: any = [];

    await performanceSchema.validate(performanceData, { abortEarly: false }).catch((err) => {
      err.inner.forEach((e: any) => {
        errors.push({ path: e.path, message: e.message })
      });
      return err;
    });

    const isValid = await performanceSchema.isValid(performanceData);

    if (isValid === false) {
      errors.forEach((error: any) => {
        if (error.path === 'startDate') {
          setStartDateError(true);
          setStartDateMsgError(error.message);
        }
        if (error.path === 'endDate') {
          setEndDateError(true);
          setEndDateMsgError(error.message);
        }
        if (error.path === 'qtdTime') {
          setQtdTimeError(true);
          setQtdTimeMsgError(error.message);
        }
      })
      return false
    } else {
      return true
    }
  }

  const handleFilter = async () => {
    if (await validationForm() === false) {
      return
    }

    setShowLoading(true);

    let url = performance;
    let params = '';

    if (startDate && endDate && qtdTime && typeTime) {
      params = `?initial_date=${formatDate(startDate)}&final_date=${formatDate(endDate)}&referenced_date=${qtdTime}&type=${typeTime}`;
    } else {
      toast({
        title: 'Algo deu errado, tente novamente mais tarde.',
        status: 'error',
        position: 'top',
        isClosable: true,
      })
      return
    }

    url = url + params;

    axios.get(url).then((response) => {
      setPerformances(response.data);
      // setTimeSelected(qtdTime);
      setTimeout(() => {
        onClose();
        setShowFilter(false);
      });
    }).catch((e) => {
      if (e.response.status === 404) {
        toast({
          title: e.response.data.message,
          status: 'error',
          position: 'top',
          isClosable: true,
        })
      } else {
        toast({
          title: 'Erro ao buscar dados, tente novamente mais tarde.',
          status: 'error',
          position: 'top',
          isClosable: true,
        })
      }
    }).finally(() => {
      setShowLoading(false);
    })
  }

  // useEffect(() => {
  //   const handleLast = async () => {
  //     if (timeSelected) { 
  //       if (timeSelected === 1) {
  //         setLastText('Último dia')
  //       } else if (timeSelected === 3 || timeSelected === 6 || timeSelected === 18 || timeSelected === 24) {
  //         setLastText(`Últimos ${timeSelected} meses`)
  //       } else {
  //         setLastText(`Últimos ${timeSelected} dias`)
  //       }
  //     } else {
  //       setLastText('Últimos 30 dias')
  //     }
  //   };
  //   handleLast();
  // },[timeSelected]);

  return (
    <Container>
      <SubContainer>
        <Overlay className={showFilter ? 'show' : ''}/>
        <OverlayLoading className={showLoading && !isOpen ? 'show' : ''}>
          <CircularProgress isIndeterminate color='#FFD60A' trackColor='rgba(1, 16, 50, 0.3)'/>
        </OverlayLoading>
        <Header
          style={{ position: 'relative' }}
        >
          <HeaderComponent
            title="RELATÓRIO | Performance do Afiliado"
            buttons={
              <>
                <button className='mb-filter' onClick={onOpen}>
                  <FaFilter className='icon-filter'/>
                </button> 
                <Menu isOpen={showFilter}>
                  <MenuButton className='filter' onClick={() => setShowFilter(!showFilter)} ref={myRef2}>
                    <FaFilter className='icon-filter'/>
                    <span className='conditional'>
                      Filtros
                      <TbChevronDown className={showFilter ? 'icon-arrow active' : 'icon-arrow'}/>
                    </span>
                  </MenuButton>
                  <MenuList zIndex={99}>
                    <Filter 
                      myRef={myRef}
                      startDate={startDate}
                      setStartDate={setStartDate}
                      startDateError={startDateError}
                      startDateMsgError={startDateMsgError}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      endDateError={endDateError}
                      endDateMsgError={endDateMsgError}
                      setQtdTime={setQtdTime}
                      qtdTime={qtdTime}
                      setQtdTimeFaster={setQtdTimeFaster}
                      qtdTimeFaster={qtdTimeFaster}
                      qtdTimeError={qtdTimeError}
                      qtdTimeMsgError={qtdTimeMsgError}
                      startDateText={startDateText}
                      endDateText={endDateText}
                      setTypeTime={setTypeTime}
                      onClose={() => setShowFilter(false)}
                      handleFilter={handleFilter}
                    />
                  </MenuList>
                </Menu>
              </>
            }
          />
        </Header>
        <Content>
          <InfoCard>
            <div className='info-content'>
              <div className='periods-div'>
                <div className='period'>
                  <BiCalendar/><p><b>Período Selecionado: </b> {startDate ? formatDateText(startDate) : 'N/A'} a {endDate ? formatDateText(endDate) : 'N/A'}</p>
                </div>
                <div className='period'>
                  <BiCalendar/><p><b>Período Comparativo: </b> {startDateText} a {endDateText}</p>
                </div>
              </div>
              <div className='filter-div'>
                <div className='icon-container'>
                  <FaFilter/>
                </div>
                <p>
                  Através do Filtro, você poderá alterar tanto o período quanto a data de comparação. <br/>
                  As datas selecionadas são consideradas entre 00:00 e 23:59.
                </p>
              </div>
            </div>
          </InfoCard>
          <Cards>
            <ComissionCard
              number={performances.CommissionsTotal.default_dates_values}
              info_number={performances.CommissionsTotal.comparative_dates_values}
              percentage={performances.CommissionsTotal.diff_percentage.replace('-', '')}
              lastText={lastText}
              index={!showFilter}
            />
            <Double>
              <SimpleCard 
                title="Visitas"
                number={performances.VisitCount.default_dates_values}
                info_number={performances.VisitCount.comparative_dates_values}
                percentage={performances.VisitCount.diff_percentage.replace('-', '')}
                tooltipText="Número de acessos à nossa plataforma através do seu link de afiliação"
                type='quant'
                lastText={lastText}
              />
              <SimpleCard 
                title="Registros"
                number={performances.RegistrationCount.default_dates_values}
                info_number={performances.RegistrationCount.comparative_dates_values}
                percentage={performances.RegistrationCount.diff_percentage.replace('-', '')}
                tooltipText="Quantidade de cadastros realizados a partir do seu link de afiliado"
                type='quant'
                lastText={lastText}
                isSecond
              />
            </Double>
          </Cards>
          <Cards>
            <DetailsCard
              details={details}
              lastText={lastText}
            />
            <Double>
              <SimpleCard 
                title="RevShare"
                number={performances.CommissionsRevShare.default_dates_values}
                info_number={performances.CommissionsRevShare.comparative_dates_values}
                percentage={performances.CommissionsRevShare.diff_percentage.replace('-', '')}
                tooltipText='Valor de comissionamento a receber a partir de apostas'
                type='quant'
                lastText={lastText}
              />
              <SimpleCard 
                title="CPA"
                number={performances.CommissionsCpa.default_dates_values}
                info_number={performances.CommissionsCpa.comparative_dates_values}
                percentage={performances.CommissionsCpa.diff_percentage.replace('-', '')}
                type='quant'
                tooltipText='Comissionamento por cadastro de usuários'
                lastText={lastText}
                isSecond
              />
            </Double>
          </Cards>
        </Content>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay className='performance'/>
          <ModalContent bgColor='transparent' boxShadow='unset' justifyContent='center' display='grid'>
            <OverlayLoading className={showLoading ? 'show' : ''}>
              <CircularProgress isIndeterminate color='#FFD60A' trackColor='rgba(1, 16, 50, 0.3)'/>
            </OverlayLoading>
            <Filter 
              startDate={startDate}
              setStartDate={setStartDate}
              startDateError={startDateError}
              startDateMsgError={startDateMsgError}
              endDate={endDate}
              setEndDate={setEndDate}
              endDateError={endDateError}
              endDateMsgError={endDateMsgError}
              setQtdTime={setQtdTime}
              qtdTime={qtdTime}
              setQtdTimeFaster={setQtdTimeFaster}
              qtdTimeFaster={qtdTimeFaster}
              qtdTimeError={qtdTimeError}
              qtdTimeMsgError={qtdTimeMsgError}
              startDateText={startDateText}
              endDateText={endDateText}
              setTypeTime={setTypeTime}
              onClose={onClose}
              handleFilter={handleFilter}
              mb={true}
            />
          </ModalContent>
        </Modal>
      </SubContainer>
    </Container>
  );
}

export default Performance;
