import styled from 'styled-components';
import { colors } from '../../../styles/tokens/color';

export const Container = styled.div`
  display: flex;
  overflow-y: scroll;
  justify-content: center;
  flex: 1 1;
  z-index: 2;

  @media (max-width: 1024px) {
    padding-top: 0;
  }

  .content {
    display: flex;
    padding: 0 5px;

    @media (max-width: 1360px) {
      flex-direction: column;
      align-items: center;
      padding-bottom: 190px;
    }

    @media (max-width: 575px) {
      padding: 0 10px;
      padding-bottom: 120px;
    }
  }

  .conditional-display {
    height: 329px;
    display: grid;
  }

  .center {
    width: 100%;
    justify-content: center;
    display: flex;
  }

  p {
    margin-bottom: 4px;
  }

  span {
    white-space: nowrap
  }

  .btn-pj {
    width: 280px;
    height: 36px;
    margin-top: 12px;
    margin-left: 18px;
    background-color: #FFF;
    border: 1px solid ${colors.primary.blue};
    border-radius: 4px;
    display: flex;
    align-items: center;
    place-content: center;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: ${colors.primary.blue};
    transition: all 0.2s;

    @media (max-width: 1024px) {
      margin-left: 8px;
      width: 300px;
      height: 40px;
    }

    @media (max-width: 500px) {
      margin-left: 8px;
      width: 200px;
      height: 40px;
    }

    &:hover {
      background-color: ${colors.primary.blue};
      color: #FFFFFF;
      transition: all 0.1s;

      svg {
        path {
          fill: white;
        }
      }
    }

    img {
      height: 22px;
      margin-right: 9px;

      @media (max-width: 1024px) {
        height: 22px;
      }
    }
  }
`;

export const SubContainer = styled.div`
  width: 100%;
  max-width: 1320px;
  padding: 20px 14px 0 14px;

  .header {
    @media (max-width: 1024px) {
      margin: -20px -14px 0 -14px;
    }
  }
`