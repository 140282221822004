import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: calc(100vw - 252px);
  justify-content: center;
  overflow-y: auto;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const SubContainer = styled.div`
  max-width: 1320px;
  width: 100%;
  padding: 22px 14px 0px 14px;
  height: fit-content;

  .header {
    @media (max-width: 1024px) {
      margin: -22px -14px 0px -14px;
    }
  }
`

export const Card = styled.div`
  margin-top: 20px;
  max-width: 1186px;
  height: fit-content;
  background: #FFFFFF;
  box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 24px;

  @media (max-width: 1024px) {
    margin: 20px;
  }

  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 13.5px;
    line-height: 16px;
    margin-bottom: 16px;

    &.link {
      margin-bottom: 0;
      margin-top: 15px;
      margin-bottom: 9px;

      &:nth-child(4) {
        margin-top: 34px;
      }
    }
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 11px;
  }

  button {
    min-width: 114px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 12px;
    background: #FFFFFF;
    border: 1px solid #011032;
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;

    @media (max-width: 600px) {
      width: 380px;
    }

    @media (max-width: 450px) {
      width: 280px;
    }

    @media (max-width: 350px) {
      width: 200px;
    }

    &:hover {
      background: #f7f7f7;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }
`;

export const LinkDiv = styled.div`
  display: flex;
  /* margin-top: 22.5px; */

  @media (max-width: 600px) {
    display: grid;
    justify-content: center;
  }
`;

export const LinkContent = styled.div`
  background: #FFFFFF;
  border: 1px solid #011032;
  border-radius: 4px;
  padding: 13px 27px;
  color: #8A8D91;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  width: 999px;
  margin-right: 25px;   

  @media (max-width: 1440px) {
    max-width: 999px;
  }

  @media (max-width: 600px) {
    border-color: #D8D9DB;
    margin-bottom: 10px;
    margin-right: 0;  
    width: 380px;
  }

  @media (max-width: 450px) {
    width: 280px;
  }

  @media (max-width: 350px) {
    width: 200px;
  }
`

export const Overlay = styled.div`
  display: none;

  &.show {
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 16, 50, 0.3);
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    transition: color 0.2s;
  }
`