import React, { useContext } from 'react';

import { Container, Buttons } from './styles';
import { MdMenu } from "react-icons/md";

import { UserContext } from '../../context/userContext';
interface HeadComponentProps {
  buttons?: React.ReactNode,
  title?: string,
  subtitle?: string,
  path?: string
}

const HeaderComponent: React.FC<HeadComponentProps> = ({ buttons, title, subtitle, path }) => {
  const { expandMenu, setExpandMenu } = useContext(UserContext);

  return (
    <Container className={path === 'home' ? 'hidden' : ''}>
      <div>
        <div className="left-content">
          <MdMenu onClick={() => setExpandMenu(!expandMenu)} className="icon-menu" />
          <h1>{title}</h1>
        </div>
        <p className={subtitle ? 'subtitle' : 'hidden'}>{subtitle}</p>
      </div>
      <Buttons>
        {buttons}
      </Buttons>
    </Container>
  );
}

export default HeaderComponent;