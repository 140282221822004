import React, { useEffect, useState, useContext } from 'react';

import HeaderComponent from '../../components/HeaderComponent';

import { Container, Card, LinkDiv, LinkContent, Overlay, SubContainer } from './styles';
import { IoCopyOutline } from 'react-icons/io5';
import { useToast, CircularProgress } from '@chakra-ui/react';
import { UserContext } from "context/userContext";
import axios from 'axios';
import mixPanelTrack from "mixpanel";
import endPoints from '../../utils/endPoints';
// import LinkGames from 'components/LinkGames';

interface LinkResponse {
	bonus_stelar: string,
	default_register_page: string,
	aviator: string,
	stelar: string
}

const Link: React.FC = () => {
  const toast = useToast();
  const { userData } = useContext(UserContext);

  const { promo_links } = endPoints;
  const [links, setLinks] = useState<LinkResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  const copyLink = (link: string, type: string) => {
    if (link === 'Sem Link Disponível') return toast({ 
      title: 'Sem link disponível',
      status: 'error',
      position: 'top',
      isClosable: true,
    })

    navigator.clipboard.writeText(link);
    mixPanelTrack('Link Copiado', { type, link, user_smartico: userData.smartico_id });
    toast({
      title: 'Copiado para área de transferência',
      status: 'success',
      position: 'top',
      isClosable: true,
    });
  }
  
  const getLinks = async () => {
    setLoading(true);
    const url = promo_links

    axios.get(url).then((response) => {
      setLinks(response.data);
      setLoading(false);
    }).catch((e) => {
      console.log(e);
      setLoading(false);
    })
  }

  useEffect(() => {
    getLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Overlay className={loading ? 'show' : ''}>
        <CircularProgress isIndeterminate color='#FFD60A' trackColor='rgba(1, 16, 50, 0.3)'/>
      </Overlay>
      <SubContainer>
        <div className='header'>
          <HeaderComponent
            title="LINKS"
          />
        </div>
        <Card>
          <h1>
            Link de Tracking
          </h1>
          <p>
            O link de Afiliado é uma URL que permite rastrearmos a sua divulgação e identificarmos os jogadores que você trouxe para a casa de aposta.
          </p>
          <p>
            No botão ao lado, copie a URL e divulgue conforme sua estratégia de captação.
          </p>
          
          {/* <h1 className='link'>Link Lading Page Bonus Stelar</h1>
          <LinkDiv>
            <LinkContent>
            { links?.bonus_stelar ? links.bonus_stelar : 'Sem Link Disponível' }
            </LinkContent>
            <button onClick={() => copyLink(links?.bonus_stelar ? links.bonus_stelar : 'Sem Link Disponível', 'stelar-bonus')}>
              <IoCopyOutline />
              Copiar link
            </button>
          </LinkDiv> */}

          <h1 className='link'>Link Página de registro padrão</h1>
          <LinkDiv>
            <LinkContent>
              { links?.default_register_page ? links.default_register_page : 'Sem Link Disponível' }
            </LinkContent>
            <button onClick={() => copyLink(links?.default_register_page ? links.default_register_page : 'Sem Link Disponível', 'registro')}>
              <IoCopyOutline />
              Copiar link
            </button>
          </LinkDiv>
    
          {/* <h1 className='link'>Link Landing Page Aviator</h1>
          <LinkDiv>
            <LinkContent>
            { links?.aviator ? links.aviator : 'Sem Link Disponível' }
            </LinkContent>
            <button onClick={() => copyLink(links?.aviator ? links.aviator : 'Sem Link Disponível', 'aviator')}>
              <IoCopyOutline />
              Copiar link
            </button>
          </LinkDiv>

          <h1 className='link'>Link Landing Page Stelar</h1>
          <LinkDiv>
            <LinkContent>
              { links?.stelar ? links.stelar : 'Sem Link Disponível' }
            </LinkContent>
            <button onClick={() => copyLink(links?.stelar ? links.stelar : 'Sem Link Disponível', 'stelar')}>
              <IoCopyOutline />
              Copiar link
            </button>
          </LinkDiv> */}
        </Card>

        {/* <LinkGames /> */}
      </SubContainer>
    </Container>
  )
}

export default Link;