import styled from 'styled-components';
import { colors } from '../../styles/tokens/color';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  place-content: space-between;
  z-index: 1;

  .icon-menu {
    display: none;
    margin-right: 8px;
    font-size: 28px;

    @media (max-width: 1024px) {
      display: block;
    }
  }

  .left-content {
    display: flex;
    align-items: center;
  }

  &.hidden {
    display: none;

    @media (max-width: 1024px) {
      display: flex;
    }
  }

  @media (max-width: 1024px) {
    padding: 0 10px;
    position: relative;
    background: #FFFFFF;
    min-height: 70px;
    height: 70px;
    align-items: center;
  }

  @media (max-width: 768px) {
    min-height: 52px;
    height: 52px;
  }

  h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.primary.blue};

    @media (max-width: 1024px) {
      color: ${colors.primary.blue};
      font-size: 16px;
      line-height: 20px;
    }

    @media (max-width: 768px) {
      font-size: 15px;
    }

    @media (max-width: 450px) {
      font-size: 14px;
    }
  }

  .hidden {
    display: none;
  }

  .subtitle {
    margin-top: 15px;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;

    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;

  button {
    min-width: 160px;
    height: 32px;
    padding: 0 15px;
    margin-left: 18px;
    background-color: #FFF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
    color: ${colors.primary.blue};
    transition: all 0.2s;
    box-shadow: 0px 4px 41px rgba(0, 0, 0, 0.2);

    &.filter {
      min-width: 149px;
      height: 41px;
      font-size: 11px;
      padding: 0px 9px;

      .icon-filter {
        width: 15px;
        margin-left: 10px;
        margin-right: 10px;
      }

      .icon-arrow {
        width: 20px;
        color: #FFD60A;
        margin-left: 10px;
        transition: all 0.2s;

        &.active {
          transform: rotate(180deg);
        }
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }

    &.mb-filter {
      display: none;

      @media (max-width: 1024px) {
        display: flex;
        min-width: unset;
        height: 35px;
        padding: 0;
        
        .icon-filter {
          width: 18px;
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      @media (max-width: 768px) {
        height: 30px;
        
        .icon-filter {
          width: 15px;
        }
      }
    }

    @media (max-width: 1024px) {
      min-width: 48px;
      padding: 0 12px;
      margin-left: 12px;
      border: none;
    }

    @media (max-width: 768px) {
      margin-left: 8px;
      padding: 0 10px;
      min-width: 38px;
      height: 37px;
      font-size: 14px;
    }

    @media (max-width: 450px) {
      padding: 0 10;
      height: 37px;
      font-size: 12px;
    }

    @media (max-width: 350px) {
      p {
        display: none;
      }
    }

    svg {
      height: 20px;
      font-size: 18px;
      margin-right: 5px;
      margin-left: -2px;
      
      path {
        fill: ${colors.primary.blue};
      }

      &.icon-arrow {
        path {
          fill: unset;
        }
      }

      @media (max-width: 1024px) {
        margin-right: 0;
      }

      @media (max-width: 768px) {
        font-size: 21px;
      }

      @media (max-width: 450px) {
        font-size: 19px;
      }
    }

    span {
      @media (max-width: 1024px) {
        display: none;

        &.not-hidden {
          display: block;
        }
      }
    }

    p {
      @media (max-width: 1024px) {
        margin-left: 8px
      }
    }

    &:hover {
      background-color: ${colors.primary.blue};
      color: #FFFFFF;
      transition: all 0.0s;

      svg {
        path {
          fill: white;
        }
  
        &.icon-arrow {
          path {  
            fill: ${colors.primary.blue};
          }
        }
      }
    }
  }

  .icon-img {
    height: 21px;
    margin-right: 12px;

    @media (max-width: 1024px) {
      height: 25px;
      margin: 0;
    }

    @media (max-width: 768px) {
      height: 20px;
    }
  }
`
