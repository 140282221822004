import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Páginas
import RedeemPassword from "../pages/RedeemPassword";
// import AffiliateAcquisition from "pages/AffiliateAcquisition";

import PrivateRoutes from "./PrivateRoutes";
import Auth0Login from "pages/Auth0Login";
// import { affiliateIds } from "../models/affiliate-ids";

const InviteRoute = ({
  path,
  managerId,
}: {
  path: string;
  managerId: number;
}) => (
  <Route path={path}>
    {/* <AffiliateAcquisition managerId={managerId} /> */}
  </Route>
);

const Routes: React.FC = () => {
  return (
    <Switch>
      {/* <InviteRoute
        path="/4817213a104fefb78feb9b6dbdf9ead5"
        managerId={1001290}
      />
      <InviteRoute
        path="/01bba411b416ed68c8146e539aa5af6f"
        managerId={1015731}
      />
      <InviteRoute
        path={"/9347b170bd133ea46ac250001fdcf20a"}
        managerId={1004895}
      />
      <InviteRoute path="/mtawmzk3" managerId={1008730} />
      <InviteRoute path="/admin-zg3nji" managerId={1} />

      {Object.values(affiliateIds).map(({ path, managerId }) => (
        <InviteRoute path={path} managerId={managerId} />
      ))} */}

      <Route path="/redeem-password" exact>
        <RedeemPassword />
      </Route>

      <Route path="/login">
        <Auth0Login />
      </Route>

      {/* Rotas privadas */}
      <PrivateRoutes />

      <Redirect to="/login" />
    </Switch>
  );
};

export default Routes;
