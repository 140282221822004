import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory } from "react-router-dom";

//Services
import endPoints from '../../utils/endPoints';
import axios from 'axios';

//Componentes
import AuthBox from '../../components/AuthBox';
import CustomInput from '../../components/Inputs/CustomInput';
import PasswordInput from '../../components/Inputs/PasswordInput';
import { PinInput, PinInputField, HStack, useToast } from '@chakra-ui/react';

//Úteis
import { redeemEmailSchema, redeemPasswordSchema } from '../../utils/validateSchemas';

//Estilização
import { Container } from './styles';
import { FiArrowLeft } from "react-icons/fi";

const RedeemPassword: React.FC = () => {
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState('Redefinição de Senha');

  //Dados Inputs
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailMsgError, setEmailMsgError] = useState('');

  const [confirmEmail, setConfirmEmail] = useState('');
  const [confirmEmailError, setConfirmEmailError] = useState(false);
  const [confirmEmailMsgError, setConfirmEmailMsgError] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordMsgError, setPasswordMsgError] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordMsgError, setConfirmPasswordMsgError] = useState('');

  const [token, setToken] = useState('');

  //Variáveis úteis
  const toast = useToast();
  const history = useHistory();

  //UseEffects
  useEffect(() => { setEmailError(false) }, [email])
  useEffect(() => { setConfirmEmailError(false) }, [confirmEmail])
  useEffect(() => { setPasswordError(false) }, [password])
  useEffect(() => { setConfirmPasswordError(false) }, [confirmPassword])

  useEffect(() => {
    if (step === 2) {
      setTitle('Valide seu Token')
    } else {
      setTitle('Redefinição de Senha')
    }
  }, [step])

  //Funções
  const manageStep = (e: any) => {
    e.preventDefault();

    if (step === 1) {
      sendEmail();
    } else if (step === 2) {
      sendToken();
    } else {
      sendPassword();
    }
  }

  const validateFirstStep = async () => {
    //setEmailError(false);
    //setConfirmEmailError(false);

    const registerData = {
      email,
      confirmEmail
    }

    let errors: any = [];

    await redeemEmailSchema.validate(registerData, { abortEarly: false }).catch((err) => {
      err.inner.forEach((e: any) => {
        errors.push({ path: e.path, message: e.message })
      });
      return err;
    });

    const isValid = await redeemEmailSchema.isValid(registerData);

    if (email !== confirmEmail) {
      setConfirmEmailError(true);
      setConfirmEmailMsgError('Os e-mails devem ser iguais.');

      return false;
    }

    if (isValid === false) {
      errors.forEach((error: any) => {
        if (error.path === 'email') {
          setEmailError(true);
          setEmailMsgError(error.message);
        }
        if (error.path === 'confirmEmail') {
          setConfirmEmailError(true);
          setConfirmEmailMsgError(error.message);
        }
      })
      return false
    } else {
      return true
    }
  }

  const validateThirdStep = async () => {
    //setPasswordError(false);
    //setConfirmPasswordError(false);

    const registerData = {
      password,
      confirmPassword
    }

    let errors: any = [];

    await redeemPasswordSchema.validate(registerData, { abortEarly: false }).catch((err) => {
      err.inner.forEach((e: any) => {
        errors.push({ path: e.path, message: e.message })
      });
      return err;
    });

    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      setConfirmPasswordMsgError('As senhas devem ser iguais.');

      return false;
    }

    const isValid = await redeemPasswordSchema.isValid(registerData);

    if (isValid === false) {
      errors.forEach((error: any) => {
        if (error.path === 'password') {
          setPasswordError(true);
          setPasswordMsgError(error.message);
        }
        if (error.path === 'confirmPassword') {
          setConfirmPasswordError(true);
          setConfirmPasswordMsgError(error.message);
        }
      })
      return false
    } else {
      return true
    }
  }

  const sendEmail = async () => {
    if (await validateFirstStep() === false) {
      return
    }

    const url = endPoints.users + "/send_email";

    let redeem = {
      email: email,
      new_password: '',
      token: null
    }

    axios.post(url, redeem).then((response) => {
      toast({
        title: 'Um token foi enviado para seu e-mail.',
        status: 'success',
        position: 'top',
        isClosable: true,
      })
      setStep(2);
    }).catch((err) => {
      toast({
        title: `${err.response ? err.response.data.message : 'Algo deu errado.' }`,
        status: 'error',
        position: 'top',
        isClosable: true,
      })
    })
  }

  const sendToken = async () => {
    const url = endPoints.users + "/send_token";

    let redeem = {
      email: email,
      new_password: '',
      token: token
    }

    axios.post(url, redeem).then((response) => {
      toast({
        title: 'Token validado com sucesso!',
        status: 'success',
        position: 'top',
        isClosable: true,
      })
      setStep(3);
    }).catch((err) => {
      toast({
        title: 'Algo deu errado.',
        status: 'error',
        position: 'top',
        isClosable: true,
      })
    })
  }

  const sendPassword = async () => {
    if (await validateThirdStep() === false) {
      return
    }

    const url = endPoints.users + "/send_password";

    let redeem = {
      email: email,
      new_password: password,
      token: token
    }

    axios.post(url, redeem).then((response) => {
      toast({
        title: 'Senha alterada com sucesso!',
        status: 'success',
        position: 'top',
        isClosable: true,
      })
      setTimeout(() => { history.push('/') }, 3000)
    }).catch((err) => {
      toast({
        title: 'Algo deu errado.',
        status: 'error',
        position: 'top',
        isClosable: true,
      })
    })
  }

  return (
    <Container translate="no">
      <AuthBox
        title={title}
        submit={manageStep}
        content={
          <>
            {step === 1 &&
              <>
                <CustomInput
                  change={setEmail}
                  placeholder="estrelabet@email.com"
                  label="E-mail"
                  type="text"
                  isError={emailError}
                  msgError={emailMsgError}
                />
                <CustomInput
                  change={setConfirmEmail}
                  placeholder="estrelabet@email.com"
                  label="Confirmar E-mail"
                  type="text"
                  isError={confirmEmailError}
                  msgError={confirmEmailMsgError}
                />
              </>
            }
            {
              step === 2 &&
              <>
                <HStack className="pin-content">
                  <PinInput variant="filled" onChange={(e: string) => setToken(e)}>
                    <PinInputField borderColor="#011032" />
                    <PinInputField borderColor="#011032" />
                    <PinInputField borderColor="#011032" />
                    <PinInputField borderColor="#011032" />
                    <PinInputField borderColor="#011032" />
                    <PinInputField borderColor="#011032" />
                  </PinInput>
                </HStack>
              </>
            }
            {
              step === 3 &&
              <>
                <PasswordInput
                  change={setPassword}
                  placeholder="********"
                  label="Nova Senha"
                  isError={passwordError}
                  msgError={passwordMsgError}
                />
                <PasswordInput
                  change={setConfirmPassword}
                  placeholder="********"
                  label="Confirmar Nova Senha"
                  isError={confirmPasswordError}
                  msgError={confirmPasswordMsgError}
                />
              </>
            }
          </>
        }
        button={<a href="#" style={{textAlign: "center", lineHeight: "40px"}} className="submit-button redeem" onClick={manageStep}>CONFIRMAR</a>}
        linksText={
          <div className="links-text">
            <NavLink to="/login"><FiArrowLeft /></NavLink>
            <p> Voltar para o <Link to="/login">Login.</Link></p>
          </div>
        }
      />
    </Container>
  );
}

export default RedeemPassword;
