import React, { useContext, useState, ReactNode } from 'react';
import { UserContext } from '../../context/userContext';
import { useHistory } from 'react-router-dom';
import mixPanelTrack from '../../mixpanel';

import {
  Container,
  SubContainer,
  Content,
  Welcome,
  WelcomeTitle,
  Buttons,
  CardsActions,
  CardHomeContainer,
  CardBalance
} from './styles';

import styleTitleHome from '../../assets/style-title-home.png';
import homeImg from '../../assets/home-img.png';

// Warning
//import WarningCard from '../../components/WarningCard';
//import { WarningContext } from '../../context/warningContext';
//import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'

// Icons
import { MdAttachMoney } from "react-icons/md";
import { IoStatsChart } from "react-icons/io5";
import { BiCalendarStar, BiWallet } from "react-icons/bi";
import { FaEyeSlash, FaEye } from "react-icons/fa";

import Carousel from '../../components/Carousel';
import HeaderComponent from '../../components/HeaderComponent';
// import Banner from 'components/Animateds/Banner';
// import BannerApp from 'components/Animateds/BannerApp';
// import BannerCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

interface ICardHomeProps {
  title: string;
  text: string;
  icon: ReactNode;
  page: string;
  mixName: string;
}

const CardHome: React.FC<ICardHomeProps> = ({ title, icon, text, page, mixName }) => {
  const history = useHistory();
  const { userData } = useContext(UserContext);

  const goPage = async (page: string, mixName: string) => {
    mixPanelTrack('Home - Atalhos', { path: mixName, user_smartico: userData.smartico_id })
    history.push(page)
  }

  return (
    <CardHomeContainer onClick={() => goPage(page, mixName)}>
      <div className='icon-container'>
        {icon}
      </div>
      <h1>
        {title}
      </h1>
      <p>
        {text}
      </p>
    </CardHomeContainer>
  )
}

const Home: React.FC = () => {
  const [showBalance, setShowBalance] = useState(true);
  const { userData } = useContext(UserContext);
  const history = useHistory();
  
  //const [showTerms, setShowTerms] = useState(true);

  // TODO: Vamos utilizar essa lógica depois, quando tiver mais tempo.
  // Verificar se está rodando via aplicativo webview - INÍCIO
  // const [isAppRendering, setIsAppRendering] = useState(false);

  // useEffect(() => {
  //   function handleEvent(event: any) {
  //     console.log('Received message:', event.data);
  //     if (event.data) {
  //       console.log('Setting isAppRendering to true');
  //       setIsAppRendering(true);
  //     }
  //   }
  //   window.addEventListener('message', handleEvent);
  //   return () => {
  //     window.removeEventListener('message', handleEvent);
  //   };
  // }, []);
  // const itemsCarouselApp = [<Banner/>]
  // Verificar se está rodando via aplicativo webview - FIM

  // Warning
  //const { showWarning, setShowWarning } = useContext(WarningContext);

  const goPage = async (page: string, mixName: string) => {
    mixPanelTrack('Home - Atalhos', { path: mixName, user_smartico: userData.smartico_id })
    history.push(page)
  }

  // const itemsCarouselWeb = [<BannerApp/>, <Banner/>]

  return (
    <>
      <Container>
        <SubContainer>
          <div className='header-comp'>
            <HeaderComponent title='INÍCIO' path="home" />
          </div>
          {/* <div className='banner-comp'>
            <BannerCarousel
              mouseTracking
              items={itemsCarouselWeb}
              disableButtonsControls
              infinite
              autoPlay
              autoPlayInterval={4000}
            />
          </div> */}
          <Content>
            <section className='welcome'>
              <Welcome>
                <WelcomeTitle>
                  <h1>
                    AFILIADO, <br/>
                    SEJA BEM-VINDO!
                  </h1>
                  <img src={styleTitleHome} alt="style-title-home" />
                </WelcomeTitle>
                <p>
                  A <span>Partners</span> é a nossa plataforma de gestão com você Afiliado. Ela oferece a você com segurança e privacidade o controle das suas comissões, saques, depósitos, desempenhos, premiações, campanhas de marketing e muito mais.
                </p>
                <div className='home-img-mobile-container'>
                  <img src={homeImg} alt="home-img" className='home-img-mobile'/>
                </div>
                <Buttons>
                  <button className='start-btn' onClick={() => goPage('/extract', 'extrato')}>VER EXTRATO</button>
                </Buttons>
              </Welcome>
              <div className='home-img-container'>
                <img src={homeImg} alt="home-img" className='home-img'/>
              </div>
            </section>
            <section className='actions'>
              <CardsActions>
                <h1 className='balance-title'>
                  SALDO NA PARTNERS
                </h1>
                <CardBalance>
                  <div className='icon-container'>
                    <BiWallet size={22} color='white'/>
                  </div>
                  <div>
                    <div className='title-balance'>
                      <h1>
                        Saldo atual
                      </h1>
                      {showBalance === true ?
                        <FaEyeSlash onClick={() => { setShowBalance(!showBalance) }} color='white'/>
                        :
                        <FaEye onClick={() => { setShowBalance(!showBalance) }} color='white'/>
                      }
                    </div>
                    <h2> {showBalance === true ? userData.balance?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '••••' } </h2>
                  </div>
                  <div className='icon-mob'>
                    {showBalance === true ?
                      <FaEyeSlash onClick={() => { setShowBalance(!showBalance) }} color='white'/>
                      :
                      <FaEye onClick={() => { setShowBalance(!showBalance) }} color='white'/>
                    }
                  </div>
                </CardBalance>
              </CardsActions>
              <CardsActions>
                <h1 className='condition-title'>
                  EXPLORE E CONHEÇA AS PRINCIPAIS FUNCIONALIDADES
                </h1>
                <Carousel items={
                  [
                    <CardHome
                      title='Solicitar Saque'
                      text='O Saque pode ser solicitado via PIX ou com Nota Fiscal. Para isso, basta acessar o menu Saque para consultar os valores já liberados.'
                      page='/withdraw'
                      mixName='solicitar_saque'
                      icon={<MdAttachMoney size={24} />}
                      key={1}
                    />,
                    <CardHome
                      title='Relatório de Performance'
                      text='O Relatório de Performance é o demonstrativo do seu desempenho como Afiliado.'
                      page='/performance'
                      mixName='relatorio_performance'
                      icon={<IoStatsChart size={18} />}
                      key={2}
                    />,
                    <CardHome
                      title='Promoções'
                      text='As campanhas mais surpreendentes você só vai encontrar aqui! Fique ligado no menu Promoções.'
                      page='/scpa'
                      mixName='promocoes'
                      icon={<BiCalendarStar size={20} />}
                      key={3}
                    />
                  ]
                }/>
                <div className='cards'>
                  <CardHome
                    title='Solicitar Saque'
                    text='O Saque pode ser solicitado via PIX ou com Nota Fiscal. Para isso, basta acessar o menu Saque para consultar os valores já liberados.'
                    page='/withdraw'
                    mixName='solicitar_saque'
                    icon={<MdAttachMoney size={24} />}
                  />
                  <CardHome
                    title='Relatório de Performance'
                    text='O Relatório de Performance é o demonstrativo do seu desempenho como Afiliado.'
                    page='/performance'
                    mixName='relatorio_performance'
                    icon={<IoStatsChart size={18} />}
                  />
                  <CardHome
                    title='Promoções'
                    text='As campanhas mais surpreendentes você só vai encontrar aqui! Fique ligado no menu Promoções.'
                    page='/scpa'
                    mixName='promocoes'
                    icon={<BiCalendarStar size={20} />}
                  />
                </div>
              </CardsActions>
            </section>
          </Content>
        </SubContainer>
        {/*TO DO: Activate when fixing the problem of communication between webview and web application */}
        {/* Warning */}
        {/*<Modal isOpen={showWarning} onClose={() => setShowWarning(false)} closeOnEsc={false} closeOnOverlayClick={false} isCentered>
          <ModalOverlay />
          <ModalContent bgColor='transparent' boxShadow='unset' justifyContent='center' display='grid' alignContent='center'>
            <WarningCard onClose={() => setShowWarning(false)} />
          </ModalContent>
        </Modal>*/}
      </Container>
      </>
  );
}

export default Home;
