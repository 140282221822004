import React from 'react';
import { InputDiv } from '../styles';
import { Select } from '@chakra-ui/react';

interface SelectProps {
    placeholder: string;
    label?: string;
    required?: boolean;
    options: any[];
    type?: string;
    setSelect: (argument : any) => void;
    setOtherSelect?: (argument : any) => void;
    setSelectApi?: (argument : any) => void;
    isError?: boolean;
    value: string;
}

const CustomSelect: React.FC<SelectProps> = ({ placeholder, label, options, type, setSelect, setOtherSelect, setSelectApi, isError, value }) => {

    const ufs = [
        { name: 'Acre', sigla: 'AC' },
        { name: 'Alagoas', sigla: 'AL' },
        { name: 'Amapá', sigla: 'AP' },
        { name: 'Amazonas', sigla: 'AM' },
        { name: 'Bahia', sigla: 'BA' },
        { name: 'Ceará', sigla: 'CE' },
        { name: 'Distrito Federal', sigla: 'DF' },
        { name: 'Espírito Santo', sigla: 'ES' },
        { name: 'Goiás', sigla: 'GO' },
        { name: 'Maranhão', sigla: 'MA' },
        { name: 'Mato Grosso', sigla: 'MT' },
        { name: 'Mato Grosso do Sul', sigla: 'MS' },
        { name: 'Minas Gerais', sigla: 'MG' },
        { name: 'Pará', sigla: 'PA' },
        { name: 'Paraíba', sigla: 'PB' },
        { name: 'Paraná', sigla: 'PR' },
        { name: 'Pernambuco', sigla: 'PE' },
        { name: 'Piauí', sigla: 'PI' },
        { name: 'Rio de Janeiro', sigla: 'RJ' },
        { name: 'Rio Grande do Norte', sigla: 'RN' },
        { name: 'Rio Grande do Sul', sigla: 'RS' },
        { name: 'Rondônia', sigla: 'RO' },
        { name: 'Roraima', sigla: 'RR' },
        { name: 'Santa Catarina', sigla: 'SC' },
        { name: 'São Paulo', sigla: 'SP' },
        { name: 'Sergipe', sigla: 'SE' },
        { name: 'Tocantins', sigla: 'TO' },
    ]

    function handleSelect(event: any) {
        const et = event.target.value;
        setSelect(et);

        if (type === 'uf' && setOtherSelect && setSelectApi) {
            const uf = ufs.find((uf) => uf.name === et);
            if (!uf) return;
            setSelectApi(uf.sigla)
            setOtherSelect("0");
        }
    }

    return (
        <InputDiv>
            <Select
                className={isError ? 'error' : '' }
                onChange={handleSelect}
                value={value}
            >
                <option value="0">{placeholder}</option>
                {options.map((select : any) => (
                    <option key={select.id} value={select.nome}>
                        {select.nome}
                    </option>
                ))}
            </Select>
            <label className={isError ? 'error' : ''}>{label}<span> *</span></label>
        </InputDiv>
    )
}

export default CustomSelect;