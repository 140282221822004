import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Inputs, OverlayLoading } from './styles';
import Input from './Input';
import CustomSelect from './Select';
import PhoneCustomInput from './PhoneInput';
import TextArea from './TextArea';
import envelope from '../../assets/Envelope.png';
import requestImg from '../../assets/request-img.png';
import { requestPlateSchema } from '../../utils/validateSchemas';
import { useToast, CircularProgress } from '@chakra-ui/react';
import endPoints from '../../utils/endPoints';

interface RequestModalProps {
    onClose: () => void;
    refresh: () => void;
    requesterId: number;
}

interface ErrorMsgProps {
    isError: boolean;
    errorMsg: string;
}

interface IBGEUFResponse {
    sigla: string;
    nome: string;
};

interface IBGECITYResponse {
    id: number;
    nome: string;
};

const ErrorMsg: React.FC<ErrorMsgProps> = ({ isError, errorMsg }) => {
    return isError ? <p className='error-msg'>{errorMsg}</p> : null;
};

const RequestModal: React.FC<RequestModalProps> = ({ onClose, requesterId, refresh }) => {

    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(false);
    const [addressMsgError, setAddressMsgError] = useState('');

    const [complement, setComplement] = useState('');
    const [complementError, setComplementError] = useState(false);
    const [complementMsgError, setComplementMsgError] = useState('');

    const [cep, setCep] = useState('');
    const [cepError, setCepError] = useState(false);
    const [cepMsgError, setCepMsgError] = useState('');

    const [city, setCity] = useState('0');
    const [cityError, setCityError] = useState(false);
    const [cityMsgError, setCityMsgError] = useState('');

    const [uf, setUf] = useState('0');
    const [ufError, setUfError] = useState(false);
    const [ufMsgError, setUfMsgError] = useState('');

    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [phoneMsgError, setPhoneMsgError] = useState('');

    const [msg, setMsg] = useState('')
    const [msgError, setMsgError] = useState(false);
    const [msgMsgError, setMsgMsgError] = useState('');

    const [ufs, setUfs] = useState<IBGEUFResponse[]>([]);
    const [cities, setCities] = useState<IBGECITYResponse[]>([]);
    const [loading, setLoading] = useState(false);
    const [ufApi, setUfApi] = useState('');
    const toast = useToast();
    const { achievements } = endPoints;

    const validationForm = async () => {

        const requestPlateData = {
            address,
            complement,
            cep,
            uf,
            city,
            phone,
            msg
        }
    
        let errors: any = [];
    
        await requestPlateSchema.validate(requestPlateData, { abortEarly: false }).catch((err) => {
          err.inner.forEach((e: any) => {
            errors.push({ path: e.path, message: e.message })
          });
          return err;
        });
    
        const isValid = await requestPlateSchema.isValid(requestPlateData);
    
        if (isValid === false) {
          errors.forEach((error: any) => {
            if (error.path === 'address') {
              setAddressError(true);
              setAddressMsgError(error.message);
            }
            if (error.path === 'complement') {
              setComplementError(true);
              setComplementMsgError(error.message);
            }
            if (error.path === 'cep') {
              setCepError(true);
              setCepMsgError(error.message);
            }
            if (error.path === 'uf') {
              setUfError(true);
              setUfMsgError(error.message);
            }
            if (error.path === 'city') {
                setCityError(true);
                setCityMsgError(error.message);
            }
            if (error.path === 'phone') {
              setPhoneError(true);
              setPhoneMsgError(error.message);
            }
            if (error.path === 'msg') {
              setMsgError(true);
              setMsgMsgError(error.message);
            }
          })
          return false
        } else {
          return true
        }
    }

    const submitForm = async (e: any) => {
        e.preventDefault();
    
        if (await validationForm() === false) {
            return
        }
    
        createPlateRequest();
    }

    const createPlateRequest = async () => {
        setLoading(true);
    
        const url = `${achievements}/claim/${requesterId}`;

        axios.post(url, { 
            address: address, 
            adjunct: complement,
            CEP: cep.replace('-', ''),
            state: uf,
            city: city,
            phone: phone,
            message: msg
        }).then(() => {
            toast({
                title: 'Solicitação enviada com sucesso!',
                status: 'success',
                position: 'top',
                isClosable: true,
            })
            refresh();
            setLoading(false);
            onClose();
        }).catch(() => {
            toast({
                title: 'Erro ao fazer a solicitação, tente novamente mais tarde!',
                status: 'error',
                position: 'top',
                isClosable: true,
            })
            setLoading(false);
        })
    }
    

    useEffect(() => { setAddressError(false) }, [address])
    useEffect(() => { setCepError(false) }, [cep])
    useEffect(() => { setComplementError(false) }, [complement])
    useEffect(() => { setCityError(false) }, [city])
    useEffect(() => { setUfError(false) }, [uf])
    useEffect(() => { setPhoneError(false) }, [phone])
    useEffect(() => { setMsgError(false) }, [msg])

    useEffect(() => {
      if (ufApi === "0") {
        return;
      }
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufApi}/municipios`
        )
        .then((response) => {
          setCities(response.data);
        });
    }, [ufApi]);
  
    useEffect(() => {
      axios
        .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados/")
        .then((response) => {
          setUfs(response.data);
        });
    }, []);

    return (
        <>
            <OverlayLoading className={loading ? 'show' : ''}>
                <CircularProgress isIndeterminate color='#FFD60A' trackColor='rgba(1, 16, 50, 0.3)'/>
            </OverlayLoading>
            <Container>
                <img src={requestImg} alt='request-img' className='request'/>
                <h1>
                    CHEGOU MINHA VEZ
                </h1>
                <p>
                    Você está a um clique de solicitar sua premiação! Preencha as informações abaixo e clique em “Enviar”. O nosso time do setor de Premiações enviará seu prêmio! 
                </p>
                <p className='last'>
                    Certifique-se de informar dados válidos para realizarmos o envio corretamente.
                </p>
                <form>
                    <Input 
                        placeholder='Avenida Tancredo Neves' 
                        label='Endereço'
                        required={true}
                        iconContent={
                            <img src={envelope} alt='envelope' className='envelope'/>
                        }
                        value={address}
                        isError={addressError}
                        change={setAddress}
                    />
                    <ErrorMsg isError={addressError} errorMsg={addressMsgError}/>
                    <Inputs>
                        <div className='partial'>
                            <Input placeholder='Quadra 17' label='Complemento' value={complement} isError={complementError} change={setComplement}/>
                            <ErrorMsg isError={complementError} errorMsg={complementMsgError}/>
                        </div>
                        <div className='partial'>
                            <Input placeholder='65062-710' label='Cep' required={cepError} value={cep} isError={cepError} change={setCep} type='cep'/>
                            <ErrorMsg isError={cepError} errorMsg={cepMsgError}/>
                        </div>
                    </Inputs>
                    <Inputs>
                        <div className='partial'>
                            <CustomSelect placeholder='Estado' label='Estado' options={ufs} type='uf' setSelect={setUf} setOtherSelect={setCity} isError={ufError} value={uf} setSelectApi={setUfApi}/>
                            <ErrorMsg isError={ufError} errorMsg={ufMsgError}/>
                        </div>
                        <div className='partial'>
                            <CustomSelect placeholder='Cidade' label='Cidade' options={cities} type='city' setSelect={setCity} isError={cityError} value={city}/>
                            <ErrorMsg isError={cityError} errorMsg={cityMsgError}/>
                        </div>
                    </Inputs>
                    <PhoneCustomInput placeholder='(00) 0000-0000' label='Celular' required={phoneError} isError={phoneError} setValueI={setPhone}/>
                    <ErrorMsg isError={phoneError} errorMsg={phoneMsgError}/>
                    <TextArea placeholder='Descreva aqui sua mensagem' label='Mensagem' isError={msgError} value={msg} change={setMsg}/>
                    <ErrorMsg isError={msgError} errorMsg={msgMsgError}/>
                </form>
                <button onClick={submitForm}>
                    ENVIAR
                </button>
            </Container>
        </>
    )
}

export default RequestModal;