import styled from 'styled-components';
import { colors } from '../../styles/tokens/color';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 350px) {
    margin-bottom: 15px;
  }

  label {
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    color: ${colors.primary.blue};

    @media (max-width: 350px) {
      font-size: 14px;
    }
  }

  .custom-input {
    border: 2px solid #FFD60A;
    height: 53px;
    font-size: 14px;

    @media (max-width: 350px) {
      height: 48px;
    }

    ::placeholder {
      font-size: 14px;
    }
  }

  .custom-input-readonly {
    border: 2px solid ${colors.grey.block};
    height: 53px;

    @media (max-width: 350px) {
      height: 48px;
    }

    &:hover {
      border: 2px solid ${colors.grey.block};
    }

    &:focus {
      border: 2px solid ${colors.grey.block};
    }

    ::placeholder {
      color: ${colors.grey.block};
    }
  }

  svg {
    cursor: pointer;
    font-size: 27px;
    color: ${colors.primary.blue};

    @media (max-width: 350px) {
      font-size: 20px;
    }
  }

  .error-msg {
    margin-top: 10px;
    margin-bottom: 0;
    color: red;
  }
`;
