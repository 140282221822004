import React, { useState } from 'react';

import { format } from 'date-fns';

import { Container, ButtonDownload } from './styles';
import { IoMdDownload } from "react-icons/io";
import { Tooltip } from '@chakra-ui/react'

import { AiOutlineInfoCircle } from "react-icons/ai";

interface TableWithdrawProps {
  requests: WithdrawRequests[],
  downloadFile: (request: WithdrawRequests) => void
}

export interface WithdrawRequests {
  id: number,
  date_created: string,
  bank: string,
  agency: string,
  account: string,
  value: number,
  status: number,
  reason: string,
  reason_type: number,
  invoiceNumber: string
}

const TableWithdraw: React.FC<TableWithdrawProps> = ({ requests, downloadFile }) => {

  const [tool, setTool] = useState(false);

  const getStatus = (status: number) => {
    if (status === 3) {
      return <span className="status-card pending">Pendente</span>
    } else if (status === 1) {
      return <span className="status-card accepted">Aceito</span>
    } else if (status === 2) {
      return <span className="status-card refused">Reprovado</span>
    }
  }

  const getInvoiceNumber = (number: string) => {
    if (number === undefined || number === null || number === '') {
      return 'N/A'
    } else {
      return number
    }
  }

  const getReasonType = (type: number) => {
    if (type === 1) {
      return 'Valor divergente'
    } else if (type === 2) {
      return 'CNAE divergente'
    } else if (type === 3) {
      return 'Dados do tomador incorreto'
    } else if (type === 4) {
      return 'Dados bancários divergentes'
    } else if (type === 5) {
      return 'Documentação divergente'
    }
  }

  return (
    <Container>
      <table>
        <thead>
          <tr className="headers">
            <th className="date">Data</th>
            <th className="type">Tipo</th>
            <th className="bank-data">Dados Bancários</th>
            <th className="value">Valor</th>
            <th className="nf">Nota Fiscal</th>
            <th className="status">Status</th>
            <th className="proof">Motivo de Recusa</th>
            <th className="attachment">Anexo</th>
          </tr>
        </thead>
        <tbody>
          {requests.map(request => (
            <tr key={request.id}>
              <td className="date">{format(new Date(request.date_created), 'dd/MM/yyyy')}</td>
              <td className="type">{request.bank}</td>
              <td className="bank-data">{request.agency} / {request.account}</td>
              <td className="value">
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(request.value)}
              </td>
              <td className="nf">{getInvoiceNumber(request.invoiceNumber)}</td>
              <td className="status">{getStatus(request.status)}</td>
              <td className="proof">
                {getReasonType(request?.reason_type)}
                {(request.reason_type !== null) &&
                  <Tooltip
                    isOpen={tool}
                    hasArrow
                    placement='top'
                    bg="#011032"
                    px="12px"
                    py="6px"
                    borderRadius='4px'
                    mb='4px'
                    ml='3px'
                    maxW='160px'
                    label={
                      <p
                        style={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight: '400', fontSize: '10px', lineHeight: '16px' }}
                      >
                        {request.reason}
                      </p>
                    }
                  >
                    <span onClick={() => setTool(!tool)} onMouseEnter={() => setTool(true)} onMouseLeave={() => setTool(false)}><AiOutlineInfoCircle /></span>
                  </Tooltip>
                }
              </td>
              <td className="attachment">
                {request.status !== 3 &&
                  <ButtonDownload>
                    <IoMdDownload onClick={() => { downloadFile(request) }} />
                  </ButtonDownload>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
}

export default TableWithdraw;