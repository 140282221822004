import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 11px;
  width: 292px;
  height: fit-content;
  background-color: #FFFFFF;

  &.mb {
    padding: 10px 10px 20px 10px;
    border-radius: 5px;
  }

  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    margin: 16px 0;
  }

  .select {
    border: 1px solid #011032;
    border-radius: 4px;
    max-width: 67px;
    min-width: 67px;
    min-height: 25px;
    max-height: 25px;
    margin: 0 0 6px 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 9.5px;
    line-height: 12px;
    text-align: center;
    color: #011032;
    padding: 0;
    box-shadow: unset;

    &:hover {
      background: #FFD60A;
      border: 1px solid #FFD60A;
      color: #011032;
    }

    &.on {
      background: #FFD60A;
      border: 1px solid #FFD60A;
      color: #011032;

      &:hover {
        border: 1px solid #011032;
      }
    }
  }

  .buttons-select {
    display: flex;
    justify-content: space-between;
  }

  .buttons-actions {
    display: flex;
    justify-content: end;
    margin-top: 15px;

    button {
      font-weight: 500;
      background: unset;
      box-shadow: unset;
      height: 25px;
      margin: 0;
      min-width: 0;
      padding: 0 6px;
      margin-left: 8px;

      &:hover {
        background: #DFDFE1;
        color: #011032;
      }
    }
  }

  .dates-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 10px;

    .date-info {

      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 100%;
        color: #B4B4B4;
        margin-bottom: 10px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 22.5px;
        text-align: center;
        color: #B4B4B4;
      }

      .no-click {
        &:hover {
          cursor: not-allowed;
        }
      }

      .line {
        width: 140px;
        border-top: 1px solid #B4B4B4;
      }
    }
  }
`

export const FlexBt = styled.div`
  display: flex;
  justify-content: space-between;
`

interface IError {
    show: boolean;
}
  
export const ErroMsg = styled.p<IError>`
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
    color: #011032;
    margin-top: 3px;
    color: #FF0000;
    display: ${props => props.show ? 'block' : 'none'};
`