import styled from 'styled-components';
import { colors } from '../../styles/tokens/color'

export const Container = styled.div`
  min-height: 100vh;
  background-color: ${colors.primary.blue};
  display: flex;
  align-items: center;
  place-content: center;

  .pin-content {
    @media (max-width: 400px) {
      margin-bottom: 15px;
    }

    .chakra-pin-input {
      height: 49px;
      width: 49px;

      @media (max-width: 500px) {
        height: 45px;
        width: 45px;
      }

      @media (max-width: 400px) {
        height: 40px;
        width: 40px;
      }

      @media (max-width: 350px) {
        height: 35px;
        width: 35px;
      }
    }
  }
`;