import styled from 'styled-components';

import { colors } from '../../styles/tokens/color';

export const Container = styled.div`
  margin: 30px 8px;
  padding: 33px 38px;
  background: #FFFFFF;
  box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.12);
  border-radius: 16px;

  h1 {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    color: ${colors.primary.blue};
  }

  h2 {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: ${colors.primary.blue};
  }

  p {
    height: 38px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000;
  }

  .link-row {
    margin-top: 12px;
    display: flex;
    margin-bottom: 30px;
    
    .link {
      width: 100%;
      height: 48px;
      padding: 13px 28px;
      background: #FFFFFF;
      border: 1px solid #D8D9DB;
      border-radius: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #8A8D91;
    }

    .link-copy {
      min-width: 152px;
      margin-left: 20px;
      border-radius: 4px;
      border: 1px solid ${colors.primary.blue};
      display: flex;
      align-items: center;
      place-content: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: ${colors.primary.blue};
      transition: all 0.2s;

      svg {
        margin-right: 5px;
        font-size: 20px;
      }

      &:hover {
        color: #fff;
        background-color: ${colors.primary.blue};
      }
    }
  }

  .doc-button {
    width: 252px;
    height: 58px;
    margin-top: 7px;
    background: #FFD60A;
    border-radius: 4px;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: ${colors.primary.blue};
    transition: all 0.2s;

    &:hover {
      background-color: #ccab06;
    }
  }
`;
