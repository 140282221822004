import styled from 'styled-components';
import { colors } from '../../../styles/tokens/color';

export const Container = styled.div`
  position: relative;
  margin-bottom: 20px;

  .error-msg {
    margin-top: 10px;
    margin-bottom: 0;
    color: red;
  }
`;

interface InputProps {
  isError: boolean
  block?: boolean
}

export const Input = styled.div<InputProps>`
  width: 100%;
  height: 53px;
  padding-left: 18px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  place-content: space-between;
  border: ${({ isError }) => isError === true ? '3px solid red' : '2px solid #FFD60A'};
  border-radius: 0.375rem;

  &:hover {
    border: ${({ isError }) => isError === true ? '3px solid red' : '2px solid #CBD5E0'};
  }

  &.selected {
    border: 3px solid #3182ce;
  }

  &.block {
    border: 2px solid ${colors.grey.block};

    svg {
      font-size: 30px;
      color: ${colors.grey.block};
    }
  }

  .placeholder-select {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ block }) => block === true ? '#E3E3E3' : '#8A8D91'}
  }

  svg {
    font-size: 30px;
    color: #FFD60A;
  }
`;

export const Dropdown = styled.div`
  width: 100%;
  max-height: 300px;
  position: absolute;
  top: 61px;
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  border: 2px solid #FFD60A;
  border-radius: 0.375rem;
  overflow-y: auto;
  z-index: 10;

  button {
    height: 100%;
    padding: 10px 15px;
    text-align: start;
    font-weight: 400;
    font-size: 13px;
    color: #8E8585;
    line-height: 30px;
    min-height: fit-content;
    line-height: 18px;
    white-space: pre-line;
    cursor: default;

    &:hover {
      background: rgba(255, 214, 10, 0.19);
      color: #000;
    }
  }
`;
