import React, { ReactNode } from "react";
import { Container, ContainerMob } from "./styles";
// eslint-disable-next-line import/no-unresolved
import { ReactElasticCarouselProps } from "react-elastic-carousel";

interface ICarouselProps {
  items: ReactNode[];
}

const CarouselContent: React.FC<ICarouselProps> = ({ items }) => {
  const carouselProps: ReactElasticCarouselProps = {
    isRTL: false,
    itemsToShow: 2,
  };

  const carouselMobProps: ReactElasticCarouselProps = {
    isRTL: false,
    itemsToShow: 1,
  };

  return (
    <>
      <Container {...carouselProps}>
        {items.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </Container>
      <ContainerMob {...carouselMobProps}>
        {items.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </ContainerMob>
    </>
  );
};

export default CarouselContent;
