import styled from 'styled-components';
import { colors } from '../../../styles/tokens/color';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 350px) {
    margin-bottom: 15px;
  }

  .custom-input {
    border: 2px solid #FFD60A;
    height: 53px;
    font-size: 14px;
    border-radius: 5px;
    padding: 0 18px;
    outline: none;

    &:hover {
      border: 2px solid ${colors.grey.block};
    }

    &:focus {
        border: 3px solid #3182ce;
    }

    @media (max-width: 350px) {
      height: 48px;
    }

    ::placeholder {
      font-size: 14px;
    }
  }

  .custom-input-readonly {
    border: 2px solid ${colors.grey.block};
    height: 53px;
    border-radius: 5px;
    padding: 0 18px;
    outline: none;

    &:focus {
        border: 2px solid ${colors.grey.block};
        cursor: not-allowed;
    }

    @media (max-width: 350px) {
      height: 48px;
    }

    &:hover {
      border: 2px solid ${colors.grey.block};
    }

    &:focus {
      border: 2px solid ${colors.grey.block};
    }

    ::placeholder {
      color: ${colors.grey.block};
    }
  }

  .error-msg {
    margin-top: 10px;
    margin-bottom: 0;
    color: red;
  }

  .error {
    border: 3px solid red;

    &:hover {
        border: 3px solid red;
    }
  }
`;
