import React from 'react';

import { Container } from './styles';

import { IoMdCloseCircle } from 'react-icons/io';

interface PixKeys {
  id: number,
  type: number,
  pix_key: string
}

interface WithdrawBoxPixKeysProps {
  title: string,
  pixKeys: PixKeys[],
  deletePixKey: (id: number) => void
}

const WithdrawBoxPixKeys: React.FC<WithdrawBoxPixKeysProps> = ({ title, pixKeys, deletePixKey }) => {

  const getType = (type: number) => {
    switch (type) {
      case 0:
        return 'Celular';
      case 1:
        return 'CPF';
      case 2:
        return 'E-mail';
      case 3:
        return 'Chave Aleatória';
      case 4:
        return 'CNPJ';
    }
  }

  return (
    <Container>
      <div>
        <h1>{title}</h1>
        <div className='rectangle'></div>
      </div>
      <div className='divider'></div>
      <table>
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Chave</th>
            <th className='conditional-th'>Excluir</th>
          </tr>
        </thead>
        <tbody>
          {pixKeys.map((pixKey : PixKeys) => (
            <tr key={pixKey.id} >
              <td>{getType(pixKey.type)}</td>
              <td>{pixKey.pix_key}</td>
              <td><IoMdCloseCircle onClick={() => deletePixKey(pixKey.id)} /></td>
            </tr>
          ))}
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </Container>
  );
}

export default WithdrawBoxPixKeys;