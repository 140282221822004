export const sessionSet = (dados: any) => {
  localStorage.setItem('localData', JSON.stringify(dados));
}

export const sessionGet = (dado: any) => {
  const dados = JSON.parse(localStorage.getItem('localData') as any);

  return (dados) ? dados[dado] : null;
}

export const sessionGetAll = () => {
  const dados = JSON.parse(localStorage.getItem('localData') as any);

  return (dados) ? dados : 'Sessão não gravada!';
}

export const sessionRemove = (dado: any) => {
  const dados = JSON.parse(localStorage.getItem('localData') as any);

  delete dados[dado];

  localStorage.setItem('localData', JSON.stringify(dados));
}

export const clearSession = async () => {
  localStorage.clear();
};