import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: calc(100vw - 252px);
  justify-content: center;
  overflow-y: auto;

  @media (max-width: 1024px) {
      width: 100%;
  }
`;

export const SubContainer = styled.div`
  max-width: 1320px;
  width: 100%;
  padding: 22px 14px 0px 14px;

  .header {
    @media (max-width: 1024px) {
      margin: -22px -14px 0px -14px;
    }
  }
`

export const AwardContent = styled.div`
    width: fit-content;
    background: #FFFFFF;
    box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 6px 6px 30px rgba(174, 195, 214, 0.29);
    padding: 20px 20px 30px 20px;
    margin-left: 6px;
    margin-top: 16px;
    border-radius: 14px;

    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        color: #011032;
        margin-bottom: 24px;
    }

    h2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 42px;
        text-align: center;
    
        color: #FFFFFF;
    }
`

export const Info = styled.div`
    background-color: #F7F7F7;
    border-radius: 16px;
    padding: 37px;

    @media (max-width: 1300px) {
        padding: 15px;
        padding-top: 30px;
    }

    @media (max-width: 660px) {
        padding: 0;
        background-color: unset;
        height: fit-content;
    }

    @media (max-width: 315px) {
        min-height: 650px;
    }

    @media (max-width: 304px) {
        min-height: 660px;
    }
`

export const PlateContent = styled.div`
    display: flex;

    @media (max-width: 1170px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
    }

    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        span {
            font-weight: 700;
        }

        .value {
            white-space: nowrap;
        }
    }

    .web-title {
        display: flex;
        margin-bottom: 12px;

        @media (max-width: 660px) {
            display: none;
        }
    }

    .mobile-title {
        display: none;

        @media (max-width: 660px) {
            display: flex;
            margin-bottom: 15px;
        }
    }
`

export const Lists = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    @media (max-width: 660px) {
        display: grid;
        background-color: unset;
        height: fit-content;
    }
`

export const List = styled.div`
    display: grid;
    width: 45%;
    height: 85%;

    @media (max-width: 1170px) {
        height: 300px;
        margin-top: 10px;
        width: 48%;

        &:last-child {
            margin-top: 0;
        }
    }

    @media (max-width: 660px) {
        width: fit-content;
    }
`

export const Item = styled.div`
    display: flex;
    align-items: center;
    height: fit-content;

    @media (max-width: 660px) {
        margin-top: 15px;
    }
`

export const Icon = styled.div`
    width: 60px;
    height: 60px;
    min-height: 60px;
    min-width: 60px;
    border-radius: 50%;
    background: #FFFFFF;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .img-send {
        max-width: 25px;
        max-height: 25px;
    }

    .img-reload {
        max-width: 25px;
        max-height: 25px;
        margin-left: -2px;
        margin-bottom: -2px;
    }
`

interface CircularProps {
    color: string;
}

export const CircularSm = styled.div<CircularProps>`
    box-sizing: border-box;
    border-radius: 50%;
    width: 106px;
    height: 106px;
    border: 2px solid ${props => props.color};
    align-items: center;
    justify-content: center;
    display: flex;

    img {
        width: 125px;
        min-width: 125px;

        @media (max-width: 1170px) {
            min-width: 60px;
        }
    }

    @media (max-width: 1170px) {
        width: 70px;
        height: 70px;
        border: 1.5px solid ${props => props.color};
    }
`

export const Circular = styled.div<CircularProps>`
    box-sizing: border-box;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    border: 2px solid ${props => props.color};
    align-items: center;
    justify-content: center;
    display: flex;

    @media (max-width: 1170px) {
        width: 82px;
        height: 82px;
        border: 1.5px solid ${props => props.color};
    }
`

export const CircularLg = styled.div<CircularProps>` 
    box-sizing: border-box;
    border-radius: 50%;
    width: 133px;
    height: 133px;
    border: 2px solid ${props => props.color};
    align-items: center;
    justify-content: center;
    display: flex;

    @media (max-width: 1170px) {
        width: 92px;
        height: 92px;
        border: 1.5px solid ${props => props.color};
    }
`

export const Overlay = styled.div`
    &.show {
        width: 100vw;
        height: 100vh;
        background-color: rgba(1, 16, 50, 0.3);
        justify-content: center;
        align-items: center;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;

        transition: color 0.2s;
    }
`