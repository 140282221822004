import React from 'react';
import { Container, FlexBt, ErroMsg } from './styles';
import DateInput from '../../Inputs/DateInput';

interface FilterProps {
    myRef?: any;
    startDate: Date | null;
    setStartDate: (date: Date | null) => void;
    startDateError: boolean;
    startDateMsgError: string;
    endDate: Date | null;
    setEndDate: ( date: Date | null ) => void;
    endDateError: boolean;
    endDateMsgError: string;
    setQtdTime: (number: number | null) => void;
    qtdTime: number | null;
    setQtdTimeFaster: (number: number | null) => void;
    qtdTimeFaster: number | null;
    qtdTimeError: boolean;
    qtdTimeMsgError: string;
    startDateText: string;
    endDateText: string;
    setTypeTime: (number: number | null) => void;
    onClose: () => void;
    handleFilter: () => void;
    mb?: boolean;
}

const Filter: React.FC<FilterProps> = ({ 
    myRef, 
    startDate, 
    setStartDate, 
    startDateError, 
    startDateMsgError,
    endDate, 
    setEndDate, 
    endDateError, 
    endDateMsgError,
    setQtdTime,
    qtdTime,
    setQtdTimeFaster,
    qtdTimeFaster,
    qtdTimeError,
    qtdTimeMsgError,
    startDateText,
    endDateText,
    setTypeTime,
    onClose,
    handleFilter,
    mb
}) => {

    const days = [
        1, 7, 15, 30 
    ]

    const months = [
        3, 6, 18, 24
    ]

    const handleTime = (time: number, type: number) => {
        if (time === qtdTime) {
            setQtdTime(null);
            setTypeTime(null);
            return;
        }
    
        setQtdTime(time);
        setTypeTime(type);
    }

    const handleTimeFaster = (time: number, type: number) => {
        if (time === qtdTimeFaster) {
            setQtdTimeFaster(null);
            setStartDate(null);
            setEndDate(null);
            return;
        }
    
        if (type === 1) {
            setStartDate(new Date(new Date().setDate(new Date().getDate() - (time * 30))))
        } else {
            setStartDate(new Date(new Date().setDate(new Date().getDate() - time)))
        }

        setEndDate(new Date());
        setQtdTimeFaster(time);
    }

    return (
        <Container ref={myRef} className={mb ? 'mb' : ''}>
            <h1>Selecionar o período</h1>
            <div className='buttons-select'>
                {
                days.map(day => (
                    <button
                    key={day}
                    className={qtdTimeFaster === day ? 'select on' : 'select'} 
                    onClick={() => handleTimeFaster(day, 2)}
                    >
                    {day === 1 ? `${day} dia` : `${day} dias`}
                    </button>
                ))
                }
            </div>
            <div className='buttons-select' style={{ marginBottom: 10 }}>
                {
                months.map(month => (
                    <button 
                    key={month}
                    className={qtdTimeFaster === month ? 'select on' : 'select'} 
                    onClick={() => handleTimeFaster(month, 1)}
                    >
                    {month} meses
                    </button>
                ))
                }
            </div>
            <FlexBt>
                <DateInput 
                    label="Data Inicial"
                    change={setStartDate}
                    value={startDate}
                    isError={startDateError}
                    msgError={startDateMsgError}
                    setClearFaster={setQtdTimeFaster}
                />
                <DateInput 
                    label="Data Final"
                    change={setEndDate}
                    value={endDate}
                    isError={endDateError}
                    msgError={endDateMsgError}
                    setClearFaster={setQtdTimeFaster}
                />
            </FlexBt>
            <h1>Comparar período anterior</h1>
            <div className='buttons-select'>
                {
                days.map(day => (
                    <button
                    key={day}
                    className={qtdTime === day ? 'select on' : 'select'} 
                    onClick={() => handleTime(day, 2)}
                    >
                    {day === 1 ? `${day} dia` : `${day} dias`}
                    </button>
                ))
                }
            </div>
            <div className='buttons-select'>
                {
                months.map(month => (
                    <button 
                    key={month}
                    className={qtdTime === month ? 'select on' : 'select'} 
                    onClick={() => handleTime(month, 1)}
                    >
                    {month} meses
                    </button>
                ))
                }
            </div>
            <ErroMsg show={qtdTimeError}>
                {qtdTimeMsgError}
            </ErroMsg>
            <div className='dates-info'>
                <div className='date-info'>
                <h2>
                    Data Inicial
                </h2>
                <div className='no-click'> 
                    <p>
                    {startDateText}
                    </p>
                    <div className='line'/>
                </div>
                </div>
                <div className='date-info'>
                <h2>
                    Data Final
                </h2>
                <div className='no-click'> 
                    <p>
                    {endDateText}
                    </p>
                    <div className='line'/>
                </div>
                </div>
            </div>
            <div className='buttons-actions'>
                <button onClick={onClose}>Cancelar</button>
                <button onClick={handleFilter}>OK</button>
            </div>
        </Container>
    )
}

export default Filter;