//*Roteamento de páginas
import { Router } from 'react-router-dom';
import { UserProvider } from './context/userContext';
import { WarningProvider } from './context/warningContext';
import Routes from './Routes/routes';
import history from './services/history';

//*Estilização
import { GlobalStyle } from "./styles/global";
import { ChakraProvider } from '@chakra-ui/react';
import theme from './styles/theme';

//*Serviços
import Interceptors from './components/Interceptors';
import HubSpotChat from 'components/HubSpotChat';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: `${process.env.REACT_APP_DD_APPLICATION_ID}`,
  clientToken: `${process.env.REACT_APP_DD_CLIENT_TOKEN}`,
  site: 'datadoghq.com',
  service: 'estrela-wallet',
  env: `${process.env.REACT_APP_DD_ENV}`,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

function App() {

  return (
    <WarningProvider>
      <UserProvider>
        <Interceptors />
        <Router history={history}>
          <ChakraProvider theme={theme}>
            <GlobalStyle />
            <Routes />
            <HubSpotChat />
          </ChakraProvider>
        </Router>
      </UserProvider>
    </WarningProvider>
  );
}

export default App;
