import styled from 'styled-components';

export const PaginationItem = styled.div`
    width: 44px;
    height: 44px;
    background: white;
    border-top: 1px solid #EEF1F2;
    border-bottom: 1px solid #EEF1F2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #011032;

    &:hover {
        background: #EEF1F2;
        cursor: pointer;
    }

    &.active {
        background: #011032;
        color: #FFFFFF;
        transition: 0.2s ease-in-out;

        &:hover {
            background: #011032;
        }
    }

    &:nth-child(1) {
        border-radius: 12px 0px 0px 12px;
        border-left: 1px solid #EEF1F2;
    }

    &:last-child {
        border-radius: 0px 12px 12px 0px;
        border-right: 1px solid #EEF1F2;
    }

    &:only-child {
        border-radius: 12px;
        border-left: 1px solid #EEF1F2;
    }

    &.disabled {
        &:hover {
            background: white;
            cursor: default;
        }
    }
`