export interface PerformanceProps {
    default_dates_values: number,
    comparative_dates_values: number,
    diff: number,
    diff_percentage: string
}
  
export interface PerformancesProps {
    Adjustments: PerformanceProps,
    Balance: PerformanceProps,
    BonusAmount: PerformanceProps,
    ChargbackTotal: PerformanceProps,
    CommissionsCpa: PerformanceProps,
    CommissionsCpl: PerformanceProps,
    CommissionsRevShare: PerformanceProps,
    CommissionsTotal: PerformanceProps,
    ConversionRate: PerformanceProps,
    DepositCount: PerformanceProps,
    DepositTotal: PerformanceProps,
    FtdCount: PerformanceProps,
    FtdTotal: PerformanceProps,
    NetDepositTotal: PerformanceProps,
    Netwin: PerformanceProps,
    Payments: PerformanceProps,
    Pl: PerformanceProps,
    QftdCount: PerformanceProps,
    QleadCount: PerformanceProps,
    RegistrationCount: PerformanceProps,
    Roi: PerformanceProps,
    SubCommissionFromChild: PerformanceProps,
    VisitCount: PerformanceProps,
    WithdrawalCount: PerformanceProps,
    WithdrawalTotal: PerformanceProps,
}

export interface DetailsProps {
    ConversionRate: PerformanceProps,
    FtdCount: PerformanceProps,
    FtdTotal: PerformanceProps,
    QftdCount: PerformanceProps,
}

const performanceDefault = {
    default_dates_values: 0,
    comparative_dates_values: 0,
    diff: 0,
    diff_percentage: ''
}

export const performancesDefault = {
    Adjustments: Object.assign({}, performanceDefault),
    Balance: Object.assign({}, performanceDefault),
    BonusAmount: Object.assign({}, performanceDefault),
    ChargbackTotal: Object.assign({}, performanceDefault),
    CommissionsCpa: Object.assign({}, performanceDefault),
    CommissionsCpl: Object.assign({}, performanceDefault),
    CommissionsRevShare: Object.assign({}, performanceDefault),
    CommissionsTotal: Object.assign({}, performanceDefault),
    ConversionRate: Object.assign({}, performanceDefault),
    DepositCount: Object.assign({}, performanceDefault),
    DepositTotal: Object.assign({}, performanceDefault),
    FtdCount: Object.assign({}, performanceDefault),
    FtdTotal: Object.assign({}, performanceDefault),
    NetDepositTotal: Object.assign({}, performanceDefault),
    Netwin: Object.assign({}, performanceDefault),
    Payments: Object.assign({}, performanceDefault),
    Pl: Object.assign({}, performanceDefault),
    QftdCount: Object.assign({}, performanceDefault),
    QleadCount: Object.assign({}, performanceDefault),
    RegistrationCount: Object.assign({}, performanceDefault),
    Roi: Object.assign({}, performanceDefault),
    SubCommissionFromChild: Object.assign({}, performanceDefault),
    VisitCount: Object.assign({}, performanceDefault),
    WithdrawalCount: Object.assign({}, performanceDefault),
    WithdrawalTotal: Object.assign({}, performanceDefault),
}

export const detailsDefault = {
    ConversionRate: Object.assign({}, performanceDefault),
    FtdCount: Object.assign({}, performanceDefault),
    FtdTotal: Object.assign({}, performanceDefault),
    QftdCount: Object.assign({}, performanceDefault),
}