import styled from "styled-components";

export const Container = styled.div`
    background-color: #FFFFFF;
    padding: 15px 38px 45px 38px;
    border-radius: 20px;
    border: 2px solid #DBDBDB;
    box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.05);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    min-width: 434px;
    margin-top: 4rem;

    @media (max-width: 475px) {
        min-width: 345px;
        max-width: 345px;
        padding: 30px 25px;
    }

    @media (max-width: 375px) {
        min-width: 280px;
        max-width: 280px;
        padding: 20px 10px;
    }

    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 27px;
        line-height: 33px;
        text-align: center;
        color: #011032;

        @media (max-width: 475px) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        text-align: center;
        color: #011032;
        width:  300px;
        margin: 8px 0px;
        justify-content: center;
        align-items: center;

        @media (max-width: 475px) {
            font-size: 12px;
            line-height: 15px;
            margin: 16px 0px 12px 0;
        }

        @media (max-width: 375px) {
            width: 240px;
        }

        &.last {
            margin: 0;
        }
    }

    form {
        margin-top: 8px;

        @media (max-width: 475px) {
            padding: 0 12px;
            margin-top: 22px;
        }

        .error-msg {
            color: red;
            margin: 0 0 0 10px;
            text-align: start;
            width: 100%;
            text-align: start;
            font-size: 9.5px;
        }
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 45px;
        background: #FFD60A;
        border-radius: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        color: #011032;
        margin-top: 20px;

        @media (max-width: 475px) {
            font-size: 20px;
            line-height: 28px;
            width: 261px;
        }

        @media (max-width: 375px) {
            font-size: 20px;
            line-height: 28px;
            width: 231px;
        }

        &:hover {
            background: #f5cd07;
        }
    }

    img {
        &.request {
            max-width: 310px;
            min-width: 310px;
            margin-bottom: 20px;

            @media (max-width: 475px) {
                max-width: 295px;
                min-width: 295px;
                margin-bottom: 20px;
            }

            @media (max-width: 375px) {
                max-width: 260px;
                min-width: 260px;
            }
        }
    }
`

export const InputContainer = styled.input`
    background: #FFFFFF;
    border: 1px solid #D2D5DA;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-size: 11px;
    padding: 10px 12px;
    width: 100%;
    transition: all 0.2s ease-in-out;
    outline: white;

    @media (max-width: 475px) {
        height: 46px;
    }

    &:focus {
        outline: none;
        border: 1.5px solid #3182ce;
        outline: 1.5px solid #3182ce;
    }

    &.icon {
        padding: 10px 35px 10px 12px;
    }

    &.error {
        outline: none;
        border: 2px solid red;

        &:focus {
            border: 2px solid red;
            outline: none;
        }
    }
`

export const InputDiv = styled.div`
    position: relative;
    margin-top: 15px;

    select:-webkit-autofill,
    select:-webkit-autofill:hover, 
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
    }

    label {
        position: absolute;
        left: 10px;
        bottom: 31px;
        padding: 0 5px;
        pointer-events: none;
        background-color: #FFFFFF;
        font-size: 10px;
        font-weight: 600;

        @media (max-width: 475px) {
            left: 10px;
            bottom: 37px;
        }

        &.error {
            color: red
        }

        span {
            color: red;
        }
    }
    
    img {
        position: absolute;
        right: 10px;
        bottom: 10px;
        background-color: #FFFFFF;

        &.envelope {
            width: 13.5px;
            bottom: 12px;

            @media (max-width: 475px) {
                bottom: 16px;
            }
        }
    }

    .chakra-select {
        background: #FFFFFF;
        border: 1px solid #D2D5DA;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        font-size: 11px;
        padding: 10px 12px;
        min-width: 168.95px;
        max-width: 168.95px;
        min-height: 38.1px;
        max-height: 38.1px;

        &:hover {
            cursor: pointer;
        }

        &.error {
            border: 2px solid red;
        }

        @media (max-width: 475px) {
            min-width: 128px;
            max-width: 128px;
            min-height: 46px;
            max-height: 46px;
        }

        @media (max-width: 375px) {
            min-width: 111px;
            max-width: 111px;
        }
    }

    select:-internal-autofill-selected {
        background-color: red !important;
    }
`

export const Inputs = styled.div`
    display: flex;
    justify-content: space-between;
    
    .partial {
        width: 48%;
    }
`

interface ErorI {
    isError: boolean;
}

export const PhoneDiv = styled.div<ErorI>`
    position: relative;
    margin-top: 15px;

    svg {
        width: 14.5px;
    }

    img {
        width: 14.5px;

        &.phone {
            position: absolute;
            width: 13.5px;
            bottom: 12px;
            right: 12px;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
    }

    select:-webkit-autofill,
    select:-webkit-autofill:hover, 
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
    }

    .PhoneInputCountryIconImg {
        max-height: 20px;
    }

    .PhoneInputCountrySelect {
        width: 40px;
        font-size: 12px;
        position: absolute;
        bottom: 11px;
        left: 10px;
        outline: none;
        border: none;

        @media (max-width: 475px) {
            bottom: 14px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .PhoneInputCountryIcon {
        position: absolute;
        bottom: 4px;
        left: 14px;
        background-color: #FFFFFF;
        width: 20px;
        height: 20px;

        @media (max-width: 475px) {
            bottom: 7px;
        }
    }

    .PhoneInputInput {
        background: #FFFFFF;
        border: 1px solid #D2D5DA;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        font-size: 11px;
        padding: 10px 12px 10px 80px;
        width: 100%;
        transition: all 0.2s ease-in-out;
        outline: white;

        @media (max-width: 475px) {
            height: 46px;
        }

        &:focus {
            outline: none;
            border: 1.5px solid #3182ce;
            outline: 1.5px solid #3182ce;
        }

        &.icon {
            padding: 10px 35px 10px 12px;
        }
    }

    .PhoneInputInput {
        border: ${({ isError }) => isError ? '2px solid red' : ''};

        &:focus {
            border: ${({ isError }) => isError ? '2px solid red' : ''};
            outline: ${({ isError }) => isError ? 'none' : ''};
        }
    }

    label {
        position: absolute;
        left: 10px;
        bottom: 29px;
        padding: 0 5px;
        pointer-events: none;
        background-color: #FFFFFF;
        font-size: 10px;
        font-weight: 600;

        @media (max-width: 475px) {
            bottom: 39px;
        }

        &.error {
            color: red
        }

        span {
            color: red;
        }
    }

    p {
        &.country {
            position: absolute;
            bottom: 4px;
            left: 50px;
            width: 30px;
            font-size: 11px;
            text-align: left;

            @media (max-width: 475px) {
                bottom: 3px;
            }
        }
    }
`

export const TextAreaDiv = styled.div`
    position: relative;
    margin-top: 15px;

    label {
        position: absolute;
        left: 10px;
        top: -6px;
        padding: 0 5px;
        pointer-events: none;
        background-color: #FFFFFF;
        font-size: 10px;
        font-weight: 600;

        &.error {
            color: red
        }
    }
`

export const TextAreaContainer = styled.textarea`
    background: #FFFFFF;
    border: 1px solid #D2D5DA;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-size: 11px;
    padding: 10px 12px;
    width: 100%;
    resize: none;
    margin-bottom: -6px;

    &:focus {
        outline: none;
        border: 1.5px solid #3182ce;
        outline: 1.5px solid #3182ce;
    }

    &.error {
        border: 1.5px solid red;
        outline: 1.5px solid red; 
    }
`

export const OverlayLoading = styled.div`
  display: none;

  &.show {
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 16, 50, 0.3);
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    transition: color 0.2s;
  }
`