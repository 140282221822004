import React, { useContext } from 'react';

import MenuTopButtons from '../MenuTopButtons';

//Estilização
import { Container, Buttons } from './styles';

// Mixpanel
import mixPanelTrack from '../../../mixpanel';

import { useHistory } from 'react-router-dom';

import { UserContext } from '../../../context/userContext';

const Menu: React.FC = () => {
  //Context
  const history = useHistory();
  const { userData } = useContext(UserContext);

  const goPage = async (page: string, mixName: string) => {
    mixPanelTrack('Menu', { menu_name: mixName, user_smartico: userData.smartico_id })
    history.push(page)
  }

  return (
    <Container>
      {/* <MenuTopButtons goPage={goPage}/> */}
      <Buttons>
        <MenuTopButtons goPage={goPage}/>
      </Buttons>
    </Container>
  );
}

export default Menu;