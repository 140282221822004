import * as yup from "yup";

export const requestPlateSchema = yup.object().shape({
  address: yup.string().required("Campo obrigatório."),
  complement: yup.string().max(50, "O campo deve ter no máximo 50 caracteres."),
  cep: yup.string().length(9, "CEP Inválido").required("Campo obrigatório."),
  uf: yup.string()
    .test('not-zero', 'Selecione um estado.', value => value !== '0')
    .required("Campo obrigatório."),
  city: yup.string()
    .test('not-zero', 'Selecione uma cidade.', value => value !== '0')
    .required("Campo obrigatório."),
  phone: yup.string().max(19, "O campo deve ter no máximo 17 caracteres.").min(6, "O campo deve ter no mínimo 6 caracteres.").required("Campo obrigatório."),
  msg: yup.string().max(200, "O campo deve ter no máximo 200 caracteres."),
});

export const withdrawPlateSchema = yup.object().shape({
  address: yup.string().required("Campo obrigatório."),
  complement: yup.string().max(50, "O campo deve ter no máximo 50 caracteres."),
  cep: yup.string().length(9, "CEP Inválido").required("Campo obrigatório."),
  uf: yup
    .string()
    .test("not-zero", "Selecione um estado.", (value) => value !== "0")
    .required("Campo obrigatório."),
  city: yup
    .string()
    .test("not-zero", "Selecione uma cidade.", (value) => value !== "0")
    .required("Campo obrigatório."),
  phone: yup
    .string()
    .max(19, "O campo deve ter no máximo 17 caracteres.")
    .min(6, "O campo deve ter no mínimo 6 caracteres.")
    .required("Campo obrigatório."),
  msg: yup.string().max(200, "O campo deve ter no máximo 200 caracteres."),
});

export const performanceSchema = yup.object().shape({
  startDate: yup
    .date()
    .nullable()
    .required("Campo Obrigatório")
    .test("valid-date", "Campo Obrigatório", function (value) {
      return value instanceof Date && !isNaN(value.getTime());
    })
    .test(
      "start-date-before-end-date",
      "A Data Inicial deve ser antes da Final",
      function (value) {
        const { endDate } = this.parent;
        return !value || !endDate || value <= endDate;
      }
    ),
  endDate: yup
    .date()
    .nullable()
    .required("Campo Obrigatório")
    .test("valid-date", "Campo Obrigatório", function (value) {
      return value instanceof Date && !isNaN(value.getTime());
    })
    .test(
      "end-date-after-start-date",
      "A Data Final deve ser depois da Inicial",
      function (value) {
        const { startDate } = this.parent;
        return !value || !startDate || value >= startDate;
      }
    ),
  qtdTime: yup.number().nullable().required("Selecione um período."),
});

export const withdrawPJSchema = yup.object().shape({
  value: yup.string().required("Campo obrigatório."),
  bank: yup.string().required("Campo obrigatório."),
  agency: yup.string().required("Campo obrigatório."),
  account: yup.string().required("Campo obrigatório."),
  invoiceFile: yup
    .string()
    .required("*Inserir a Nota Fiscal conforme o valor do pedido de Saque."),
  invoiceNumber: yup.string().required("Campo obrigatório."),
  contract: yup.string().required("Campo obrigatório."),
});

export const registerPixKeysSchema = yup.object().shape({
  pixKey: yup.string().required("Campo obrigatório."),
  pixKeyType: yup.string().required("Campo obrigatório."),
});

export const withdrawPixSchema = yup.object().shape({
  pixKey: yup.string().required("Campo obrigatório."),
});

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("E-mail no formato errado.")
    .required("Campo obrigatório."),
  password: yup.string().required("Campo obrigatório."),
});

export const registerSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório."),
  cpf: yup.string().required("Campo obrigatório."),
  email: yup
    .string()
    .email("E-mail no formato errado.")
    .required("Campo obrigatório."),
  phone: yup.string().required("Campo obrigatório."),
  password: yup.string().required("Campo obrigatório."),
  confirmPassword: yup.string().required("Campo obrigatório."),
});

export const redeemEmailSchema = yup.object().shape({
  email: yup
    .string()
    .email("E-mail no formato errado.")
    .required("Campo obrigatório."),
  confirmEmail: yup
    .string()
    .email("E-mail no formato errado.")
    .required("Campo obrigatório."),
});

export const redeemPasswordSchema = yup.object().shape({
  password: yup.string().required("Campo obrigatório."),
  confirmPassword: yup.string().required("Campo obrigatório."),
});

export const affiliateFormSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório."),
  email: yup
    .string()
    .email("E-mail no formato errado.")
    .required("Campo obrigatório."),
  phone: yup.string().required("Campo obrigatório."),
  cpf: yup.string().required("Campo obrigatório."),
});