import React, { useContext } from "react";
import {
  Container,
  Title,
  Description,
  Button
} from "./styles";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react'

import { Integration } from "types/Integration";
import IntegrationModal from "components/IntegrationModal";
import mixPanelTrack from "mixpanel";
import { UserContext } from "context/userContext";

interface IntegrationCardProps {
  integration: Integration;
  isSigned?: boolean;
  refreshIntegrations: () => void;
}

const IntegrationCard: React.FC<IntegrationCardProps> = ({
  integration,
  isSigned,
  refreshIntegrations
}) => {
  const { userData } = useContext(UserContext);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const buttonText = isSigned ? 'ACESSAR' : 'CRIAR SALA DE SINAIS'

  const signOrOpenIntegration = () => {
    if (isSigned) {
      mixPanelTrack('Acesso à integração', {
        integration: integration.title,
        user_smartico: userData.smartico_id
      });
      return window.open(integration.access_url, "_blank");
    }
    mixPanelTrack('Clique para pop-up de assinar integração', {
      integration: integration.title,
      user_smartico: userData.smartico_id
    });
    onOpen();
  };

  return (
    <Container>
      <img src={integration.image_url} alt="Logo da integração" />
      <Title>{ integration.title }</Title>
      <Description>{ integration.description }</Description>

      <Button onClick={signOrOpenIntegration}>
        {buttonText}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          bgColor='transparent'
          boxShadow='unset'
          justifyContent='center'
          display='grid'
        >
          <IntegrationModal
            onClose={onClose}
            refreshIntegrations={refreshIntegrations}
            integration={integration}
          />
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default IntegrationCard;
