import styled from 'styled-components';

export const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #BCBFC2;
    box-shadow: 1px 3px 7px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;

    &.disabled {
        border: 1px solid #F6F5F5;
        color: #C4BEB6;
        border: none
    }

    &.sm {
        width: 82px;
        height: 119px;
        margin-right: 13px;

        @media (max-width: 461px) {
            margin-bottom: 10px;
        }

        .kit {
            max-width: 32px;
        }

        .star {
            margin-top: -38px;
            margin-right: -10px;
            width: 60px;
            margin-bottom: -5px;
        }

        .disabled {
            margin-top: -38px;
            margin-right: -10px;
            width: 60px;
            margin-bottom: -5px;
            filter: brightness(100%);
        }

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 9px;
            line-height: 15px;
        }
    }

    &.lg {
        width: 275px;
        min-width: 275px;
        height: 388px;
        margin-right: 15px;
        padding: 22px 30px;

        @media (max-width: 768px) {   
            width: 206px;
            min-width: 206px;
            height: 372px;
            margin-right: 15px;
            padding: 12px 22px;
            justify-content: end;
            padding-bottom: 46px;
        }

        .kit {
            max-width: 60px;

            @media (max-width: 768px) {   
                max-width: 50px;
            }
        }

        .star {
            margin-top: -33px;
            margin-right: -18px;
            width: 27px;
            margin-bottom: 16px;

            @media (max-width: 768px) {   
                margin-top: -30px;
                margin-right: -16px;
                width: 25px;
                margin-bottom: 16px;
            }
        }

        h4 {
            margin-top: 5px;
            margin-bottom: 25px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #011032;

            @media (max-width: 768px) {   
                margin-top: 0px;
            }
        }

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            text-align: center;
            color: #011032;
            padding: 0 25px;

            @media (max-width: 768px) {
                height: 90px;
                padding: 0;
                font-size: 13px;
                line-height: 16px;
                margin-top: 20px;
            }

            span {
                font-weight: 700;
                white-space: nowrap;
            }
        }

        button {
            background: #FFD60A;
            border: 1px solid #FFD60A;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 17px;
            text-align: center;
            color: #011032;
            padding: 11px 0;
            width: 100%;
            margin-top: 37px;

            @media (max-width: 768px) {
                margin-top: 20px;
            }
        }
    } 

    &.award {
        width: 275px;
        min-width: 275px;
        height: 388px;
        margin-right: 15px;

        @media (max-width: 1170px) {
            width: 197px;
            min-width: 197px;
            height: 278px;
            padding: 0px 20px;
            margin-bottom: 15px;
            margin-right: 0;
        }

        .award {
            max-width: 120px;

            @media (max-width: 1170px) {
                max-width: 80px;
            }
        }

        .star-award {
            position: absolute;
            top: 132px;
            right: 90px;
            width: 55px;

            @media (max-width: 1170px) {
                width: 40px;
                top: 90px;
                right: 65px;
            }
        }

        h4 {
            margin-top: 15px;
            margin-bottom: 25px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 32px;
            padding: 0 70px;
            text-align: center;
            color: #011032;

            @media (max-width: 1170px) {
                font-size: 20px;
                line-height: 23px;
            }
        }

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            text-align: center;
            color: #011032;
            padding: 0 25px;

            span {
                font-weight: 700;
                white-space: nowrap;
            }
        }
    }
`