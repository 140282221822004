import styled from 'styled-components';
import { colors } from '../../../styles/tokens/color';

export const ContractSelected = styled.div`
  background-color: #F5F5F5;
  width: 100%;
  height: 30px;
  margin-top: -15px;
  margin-bottom: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 8px;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #2962E4;

  .file-name {
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.block {
    display: none;
  }

  svg {
    color: #C6BFBF;
    min-width: 20px;
    font-size: 21px;

    &:hover {
      cursor: pointer;
      color: #2962E4;
    }
  }

  @media (max-width: 350px) {
    font-size: 12px;
    height: 25px;
  }

`

export const Container = styled.div`
  display: flex;
  overflow-y: scroll;
  justify-content: center;
  flex: 1 1;
  z-index: 2;

  @media (max-width: 1024px) {
    padding-top: 0;
  }

  .content {
    display: flex;
    padding-bottom: 20px;

    @media (max-width: 1360px) {
      display: grid;
      justify-content: center;
      padding-bottom: 80px;
    }
  }

  .conditional-display {
    height: 250px;
    display: grid;
  }

  p {
    margin-bottom: 4px;
  }
`;

export const SubContainer = styled.div`
  max-width: 1320px;
  width: 100%;
  padding: 20px 14px 0 14px;

  .header {

    @media (max-width: 1024px) {
      margin: -20px -14px -14px -14px;
    }
  }
`

export const SubHeader = styled.div`
  margin-top: 15px;

  &.no-show {
    display: none;
  }

  @media (max-width: 1360px) {
    display: grid;
    justify-content: center;
  }

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #011032;
    margin-left: 7px;
  }

  button {
    width: 235px;
    height: 48px;
    background-color: #FFF;
    border: 1px solid ${colors.primary.blue};
    border-radius: 4px;
    display: flex;
    align-items: center;
    place-content: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: ${colors.primary.blue};
    transition: all 0.1s;

    &:hover {
      background-color: ${colors.primary.blue};
      color: #FFFFFF;
      transition: all 0.1s;

      span {
        transition:  all 0.1s;
        color: #FFFFFF;
      }
    }

    @media (max-width: 1360px) {
      width: 100%;
      height: 48px;
    }

  }

  div {
    height: 86px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: ${colors.primary.blue};
    justify-content: space-between;
    box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.12);
  
    @media (max-width: 1360px) {
      height: 131px;
      display: grid;
      margin: 24px 0px 0px 0px;
      width: 550px;
    }
  
    @media (max-width: 1024px) {
      font-size: 14px;
    }

    @media (max-width: 600px) {
      padding: 6px 12px 6px 12px;
      width: auto;
    }
  }
`