import React, { useState, useRef, useEffect } from 'react';

import { Container, Input, Dropdown } from './styles';
import { RiArrowDropDownFill } from "react-icons/ri";

interface CustomSelectProps {
  change: (e: string) => void,
  value?: string,
  placeholder: string,
  options?: object[],
  isError?: boolean,
  msgError?: string,
  readonly?: boolean,
}

const CustomSelect: React.FC<CustomSelectProps> = ({ change, value, placeholder, options = [], isError = false, msgError, readonly = false }) => {
  const myRef = useRef<any>();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(false);
  const [textHolder, setTextHolder] = useState(placeholder)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleClickOutside = (e: any) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      setSelected(false);
      setShow(false);
    }
  };

  const handleClickInside = () => {
    if (!readonly) {
      setSelected(!selected);
      setShow(!show);
    }
  }

  const handleClickOption = (e: any) => {
    setSelected(false);
    setTextHolder(e.target.innerHTML);
    change(e.target.value);
    setShow(false);
  }

  const setClassName = (selected: boolean, readonly: boolean) => {
    if (selected === true) {
      return 'selected'
    } else if (readonly === true) {
      return 'block'
    } else {
      return ''
    }
  }

  return (
    <Container ref={myRef} >
      <Input
        className={
          setClassName(selected, readonly)
        }
        isError={isError}
        block={readonly}
        onClick={() => { handleClickInside() }}
      >
        <div className="placeholder-select">
          {value === '' ? placeholder : textHolder.replace(/&amp;/g, "&")}
        </div>
        <RiArrowDropDownFill />
      </Input>
      {isError && <p className="error-msg">{msgError}</p>}
      {show &&
        <Dropdown>
          {options.map((option: any) => (
            <button key={option.id} value={option.id} onClick={(e: any) => { handleClickOption(e) }}>{option.text} </button>
          ))}
        </Dropdown>
      }
    </Container>
  );
}

export default CustomSelect;