import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  PageContent,
  Description,
  Overlay,
  IntegrationsContainer,
  IntegrationsList,
  ListHeader,
  ListTitle,
} from './styles';

import HeaderComponent from '../../components/HeaderComponent';
import Card from '../../components/IntegrationCard'
import { BsBookmarkCheckFill, BsInfoCircleFill } from "react-icons/bs";
import { CircularProgress } from '@chakra-ui/react';

import axios from 'axios';
import endPoints from 'utils/endPoints';
import { IntegrationResponse } from 'types/Integration';

const Integrations: React.FC = () => {
  const texts = {
    pageTitle: 'INTEGRAÇÕES',
    description: 'Veja as plataformas que você pode integrar com a Partners para ajudar a escalar o seu negócio!',
    active: 'Ativas',
    availableIntegrations: 'Integrações disponíveis',
    noIntegrationsMessage: 'Não há integrações disponíveis no momento'
  };

  const { available_integrations, active_integrations } = endPoints;
  const [availableIntegrations, setAvailableIntegrations] = useState<IntegrationResponse>([]);
  const [activeIntegrations, setActiveIntegrations] = useState<IntegrationResponse>([]);
  const [loading, setLoading] = useState<boolean>(false);
  
  const getAvaiableIntegrations = useCallback(async () => {
    setLoading(true);
    const url = available_integrations
    
    axios.get(url).then((response) => {
      setAvailableIntegrations(response.data);
      setLoading(false);
    }).catch((error) => {
      console.log(error, 'error');
      setLoading(false);
    })
  }, [available_integrations]);
  
  const getActiveIntegrations = useCallback(async () => {
    setLoading(true);
    const url = active_integrations
    
    axios.get(url).then((response) => {
      setActiveIntegrations(response.data);
      setLoading(false);
    }).catch((error) => {
      console.log(error, 'error');
      setLoading(false);
    })
  }, [active_integrations]);

  const refreshIntegrations = () => {
    getAvaiableIntegrations();
    getActiveIntegrations();
  };
  
  const renderIntegrations = (
    integrations: IntegrationResponse,
    title: string,
    refreshIntegrations: () => void,
    isSigned = false
  ) => (
    <IntegrationsContainer>
      <ListHeader>
        { isSigned && <BsBookmarkCheckFill color='#2E7D32' />}
        {title && <ListTitle>{title}</ListTitle>}
      </ListHeader>
      <IntegrationsList>
        {integrations.map((integration, index) => (
          <Card
            key={index}
            integration={integration}
            refreshIntegrations={refreshIntegrations}
            isSigned={isSigned}
          />
        ))}
      </IntegrationsList>
    </IntegrationsContainer>
  );
  
  useEffect(() => {
    getAvaiableIntegrations();
    getActiveIntegrations();
  }, [getAvaiableIntegrations, getActiveIntegrations])
  
  const showNoIntegrationsMessage = !loading && activeIntegrations.length === 0 && availableIntegrations.length === 0;

  return (
    <Container>
      <PageContent>
        <div className='header'>
          <HeaderComponent title={texts.pageTitle}/>
        </div>
        <Description>
          <p>{texts.description}</p>
        </Description>

        { loading && (
          <Overlay>
            <CircularProgress
              isIndeterminate
              color='#FFD60A'
              trackColor='rgba(1, 16, 50, 0.3)'
            />
          </Overlay>
        )}

        { showNoIntegrationsMessage && (
          <ListHeader>
            <BsInfoCircleFill color='#FFD60A' />
            <ListTitle>{texts.noIntegrationsMessage}</ListTitle>
          </ListHeader>
        )}

        {/* Integrações ativas */}
         {activeIntegrations.length > 0 && (
          renderIntegrations(activeIntegrations, texts.active, refreshIntegrations, true)
        )}

        {/* Integrações disponíveis */}
        {availableIntegrations.length > 0 && (
          renderIntegrations(availableIntegrations, texts.availableIntegrations, refreshIntegrations)
        )}
      </PageContent>
    </Container>
  )
}

export default Integrations;