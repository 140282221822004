export const buildWalletlog = (movimentation: any) => {
  if (movimentation.type === 1 || movimentation.type === 2) {
    return {
      title: 'Comissão Recebida',
      subtitle: 'Valor adicionado na sua carteira',
      colorText: 'green',
      colorIcon: 'green',
      status: 1
    };
  } else if (movimentation.type === 3) {
    if (movimentation.status === 2) {
      return {
        title: 'Saque Solicitado',
        subtitle: 'Concluído',
        colorText: 'red',
        colorIcon: 'green',
        status: 1
      }
    } else if (movimentation.status === 7) {
      return {
        title: 'Saque Solicitado',
        subtitle: 'Recusado',
        colorText: 'black',
        colorIcon: 'red',
        status: 0
      }
    } else if (movimentation.status === 6) {
      return {
        title: 'Saque Solicitado',
        subtitle: 'Em processamento',
        colorText: 'black',
        colorIcon: 'yellow',
        status: 2
      }
    } else {
      return {
        title: 'Saque Solicitado',
        subtitle: 'Pendente',
        colorText: 'black',
        colorIcon: 'yellow',
        status: 2
      }
    }
  } else if (movimentation.type === 4) {
    if (movimentation.reason) {
      return {
        title: 'Reembolso',
        subtitle: 'Pedido de saque recusado',
        colorText: 'green',
        colorIcon: 'green',
        status: 1
      }
    } else {
      return {
        title: 'Reembolso',
        subtitle: 'Concluído',
        colorText: 'green',
        colorIcon: 'green',
        status: 1
      }
    }
  } else if (movimentation.type === 5) {
    return {
      title: 'Saldo adicionado',
      subtitle: 'Manualmente pelo administrador',
      colorText: 'green',
      colorIcon: 'green',
      status: 1
    }
  } else if (movimentation.type === 6) {
    return {
      title: 'Saldo retirado',
      subtitle: 'Manualmente pelo administrador',
      colorText: 'red',
      colorIcon: 'green',
      status: 1
    }
  } else if (movimentation.type === 7) {
    return {
      title: 'Saldo retirado',
      subtitle: 'Retirado por administrador no fechamento semanal',
      colorText: 'red',
      colorIcon: 'green',
      status: 1
    }
  } else if (movimentation.type === 8) {
    if (movimentation.value > 0) {
      return {
        title: 'Saldo adicionado',
        subtitle: 'Adicionado pelo administrador',
        colorText: 'green',
        colorIcon: 'green',
        status: 1
      }
    } else {
      return {
        title: 'Saldo retirado',
        subtitle: 'Retirado pelo administrador',
        colorText: 'red',
        colorIcon: 'green',
        status: 1
      }
    }
  } else if (movimentation.type === 9) {
    return {
      title: 'Saldo retirado',
      subtitle: 'Saque realizado com sucesso',
      colorText: 'red',
      colorIcon: 'green',
      status: 1
    }
  } else if (movimentation.type === 10) {
    return {
      title: 'Saldo retido',
      subtitle: 'Analisando pedido de saque',
      colorText: 'red',
      colorIcon: 'yellow',
      status: 2
    }
  } else if (movimentation.type === 11) {
    return {
      title: 'Saldo adicionado',
      subtitle: 'SCPA Afiliado',
      colorText: 'green',
      colorIcon: 'green',
      status: 1
    }
  } else if (movimentation.type === 12) {
    return {
      title: 'Saldo adicionado',
      subtitle: 'SCPA Gerente',
      colorText: 'green',
      colorIcon: 'green',
      status: 1
    }
  } else if (movimentation.type === 13) {
    return {
      title: 'Saldo debitado',
      subtitle: 'Adiantamento SCPA',
      colorText: 'red',
      colorIcon: 'green',
      status: 1
    }
  }
}
