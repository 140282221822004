import React, { useEffect, useState } from 'react';

import WalletBox from '../../components/WalletBox';
import HeaderComponent from '../../components/HeaderComponent';
import Chart from '../../components/Chart';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { Container, SubContainer, Statistics, Wallet, WrapContent, ExtractContent, Card, CardHeader, Divider, MoneyInfo, CircleGreen, CircleRed } from './styles';
import endPoints from '../../utils/endPoints';
import axios from 'axios';

const Extract: React.FC = () => {
  const [monthBalance, setMonthBalance] = useState(0);
  const [monthWithdraw, setMonthWithdraw] = useState(0);
  const [chartData, setChartData] = useState<any>([]);
  const [showDash, setShowDash] = useState<boolean>(false);
  
  useEffect(() => {
    getDashboardData() 
  }, [])

  const getDashboardData = async () => {
    let url = endPoints.walletlog + `/dashboard`;

    axios.get(url).then((response) => {
      setChartData(response.data.every_month_balance);

      if (response.data.monthlyBalance !== null) {
        setMonthBalance(response.data.monthlyBalance);
      } else {
        setMonthBalance(0);
      }

      if (response.data.monthlyWithdrawal !== null) {
        setMonthWithdraw(response.data.monthlyWithdrawal);
      } else {
        setMonthWithdraw(0);
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  return (
    <Container>
      <SubContainer>
        <div className='header'>
          <HeaderComponent title='EXTRATO' path="home" 
            buttons={
              <div>
                <button className={!showDash ? 'btn-home' : 'btn-hidden'} onClick={() => setShowDash(true)}>Dashboard</button>
                <button className={showDash ? 'btn-home' : 'btn-hidden'}  onClick={() => setShowDash(false)}>Extrato</button>
              </div>
            }
          />
        </div>
        <Wallet>
          <ExtractContent show={!showDash}>
            <span className="title-home">EXTRATO</span>
            <WrapContent>
              <WalletBox/>
            </WrapContent>
          </ExtractContent>
          <Statistics show={showDash}>
            <span className="title-home">DASHBOARD</span>
            <CardHeader>
              <Card>
                <h2>ENTRADA DE SALDO</h2>
                <p>Últimos 30 dias</p>
                <Divider />
                <MoneyInfo>
                  <h1>{monthBalance.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h1>
                  <CircleGreen>
                    <AiOutlineArrowUp />
                  </CircleGreen>
                </MoneyInfo>
              </Card>
              <Card>
                <h2>SAQUES REALIZADOS</h2>
                <p>Últimos 30 dias</p>
                <Divider />
                <MoneyInfo>
                  <h1>{monthWithdraw.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h1>
                  <CircleRed>
                    <AiOutlineArrowDown />
                  </CircleRed>
                </MoneyInfo>
              </Card>
            </CardHeader>
            <Chart chartData={chartData} />
          </Statistics>
        </Wallet>
      </SubContainer>
    </Container>
  );
}

export default Extract;