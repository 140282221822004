import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const labels = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

const barThickNess = () => {
    if (window.innerWidth > 800) {
        return 14
    } else {
        return 8
    }
}

interface ChartDataProps {
    chartData: any[]
}

const Chart: React.FC<ChartDataProps> = ({ chartData }) => {
    const [valueData, setValueData] = useState([]);

    useEffect(() => {
        let monthIndex = 0;

        let newArray: any = [];

        var filteredArray = chartData.filter(function (el) {
            return el.year === 2023
        })

        for (let i = 1; i <= 12; i++) {
            if (filteredArray.length > 0) {
                if (filteredArray[monthIndex]?.month === i) {
                    newArray.push(filteredArray[monthIndex].value)
                    monthIndex++;
                } else {
                    newArray.push(0);
                }
            } else {
                newArray.push(0)
            }
        }

        setValueData(newArray);
    }, [chartData]);

    const data = {
        labels,
        datasets: [
            {
                label: 'Mês Atual',
                data: valueData.map((value) => value),
                backgroundColor: '#011032',
                borderRadius: 14,
                barThickness: barThickNess(),
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
                labels: {
                    font: {
                        size: 11,
                        family: 'Inter',
                    }
                }
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                border: {
                    color: 'transparent'
                },
                grid: {
                    tickColor: 'transparent',
                    color: 'transparent',
                    borderColor: 'transparent',
                },
                ticks: {
                    color: '#ADAEAF',
                    font: {
                        size: 10,
                        family: 'Inter',
                    }
                }
            },
            y: {
                stacked: false,
                border: {
                    color: 'transparent'
                },
                ticks: {
                    callback: function (label: any, index: any, values: any) {
                        if (label > 999.99 && label.toString()[1] === '0') {
                            return 'R$ ' + (label / 1000).toFixed(0) + 'K';
                        } else if (label > 999.99) {
                            return 'R$ ' + (label / 1000).toFixed(1) + 'K';
                        }
                        return 'R$ ' + (label.toFixed(1));
                    },
                    color: '#ADAEAF',
                    font: {
                        size: 10,
                        family: 'Inter',
                    }
                }
            },
        },
    };

    return (
        <Container>
            <h1>RECEITA MENSAL</h1>
            <Bar options={options} data={data} className='chart' />
        </Container>
    )
}

export default Chart;