import { createContext, useState, ReactNode } from "react";

interface WarningContextData {
  showWarning: boolean,
  setShowWarning: (value: boolean) => void;
}

interface WarningProviderProps {
  children: ReactNode
}

export const WarningContext = createContext<WarningContextData>({} as WarningContextData);

export function WarningProvider({ children }: WarningProviderProps) {
  const [showWarning, setShowWarning] = useState(false);

  return (
    <WarningContext.Provider value={{ showWarning, setShowWarning }}>
      {children}
    </WarningContext.Provider>
  )
}