export type Integration = {
  id: number,
  title: string,
  description: string,
  image_url: string,
  access_url: string,
  created_at: string,
  updated_at: string
};

export type IntegrationResponse = Integration[];

export enum SubscriptionStatus {
  PAID = 1,
  PENDING = 2,
  TRIAL = 3,
  CANCELED = 4
};
