import styled from 'styled-components';

interface ContainerProps {
  isSecond?: boolean;
}
export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 145px;
  background: #FFFFFF;
  box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 6px 6px 30px rgba(174, 195, 214, 0.29);
  border-radius: 14px;
  margin-right: ${props => props.isSecond ? '0px' : '10px'};
  padding: 10px 11px 11px 15px;

  @media (max-width: 610px) {
    height: fit-content;
    margin-right: 0px;
  }
`

export const Title = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: #011032;
  position: relative;

  @media (max-width: 610px) {
    font-size: 11px;
    line-height: 12px;
  }

  span {
    position: absolute;
    right: 1px;
  }
`

export const Number = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 46px;
  color: #011032;

  @media (max-width: 610px) {
    font-size: 24px;
    line-height: 40px;
  }
`

export const Divider = styled.div`
  border-top: 1px solid #EBE3FF;
  width: 287px;
  height: 0px;
  margin-left: -15px;
  margin-top: 21px;
  margin-bottom: 2px;

  @media (max-width: 610px) {
    margin-top: 8px;
    margin-bottom: 4px;
  }

  @media (max-width: 610px) {
    width: 480px;
  }

  @media (max-width: 515px) {
    width: 400px;
  }

  @media (max-width: 430px) {
    width: 287px;
  }

`

export const Infos = styled.div`
  width: 100%;
  display: flex;
`

export const Info = styled.div`
  display: grid;
  margin-left: 4px;
  margin-top: 6px;

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    color: #ABABAB;

    @media (max-width: 610px) {
      font-size: 10px;
      line-height: 15px;
    }
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #ABABAB;

    @media (max-width: 610px) {
      font-size: 15px;
      line-height: 20px;
    }
  }
`

interface IGroup {
  color: string
}

export const Group = styled.div<IGroup>`
  display: flex;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${props => props.color};
  align-items: flex-end;

  @media (max-width: 610px) {
    font-size: 13px;
    line-height: 20px;
  }

  p {
    margin-left: 9px;
    margin-right: 10px;
  }

  svg {
    margin-bottom: 2.5px;
  }
`

export const Text = styled.div`
  display: flex;
  margin-right: auto;
`

export const Point = styled.p`
  color: #FFD60A;
`