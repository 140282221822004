import React from 'react';

import { Container, Card } from './styles';

import estrelabet from '../../../assets/estrelabet.png';

interface RegulationProps {
    onClose: () => void;
}

const Regulation: React.FC<RegulationProps> = ({ onClose }) => {
  return (
        <>
            <Container>
                <Card>
                    <img src={estrelabet} alt="estrela-bet"/>
                    <h3>
                        REGULAMENTO PROMOÇÃO PARA AFILIADOS ESTRELABET
                    </h3>
                    <div className='content-text'>
                        <p>
                            <b>MOTOGRAU</b>
                        </p>
                        <p>
                            <b>1.    INTRODUÇÃO</b>
                        </p>
                        <p>
                            Bem-vindo(a) ao regulamento específico de promoção para Afiliados(as) da EstrelaBet, relativa ao lançamento do jogo MotoGrau!
                        </p>
                        <p>
                            O MotoGrau é um jogo adulto (para maiores de 18 anos), online, multijogador, do tipo "Crash", cujas regras você encontra no site www.estrelabet.com. 
                        </p>
                        <p>
                            No MotoGrau, cada jogador(a) realiza uma aposta com cota ou valor fixo, que poderá ser multiplicado pelo índice verificado na tela até o(a) jogador(a) definir o momento de descontar ou retirar o valor apostado ("CASH OUT").
                        </p>
                        <p>
                            Para ganhar, o(a) jogador(a) deve realizar o CASH OUT antes do motociclista sair da manobra sob uma roda e voltar com as duas rodas da motocicleta no chão (“Crash”).
                        </p>
                        <p>
                            Muita atenção, pois o Crash pode acontecer de modo aleatório, a qualquer momento, após o início da rodada.
                        </p>
                        <p>
                            Se o Crash acontecer antes do CASH OUT, o(a) jogador(a) perde 100% da cota ou valor fixo apostado e também 100% do valor até então determinado pelo multiplicador verificado imediatamente antes do Crash.
                        </p>
                        <p>
                            O seu objetivo, então, é ordenar o CASH OUT antes do Crash do motociclista, a fim de ganhar a cota ou valor fixo apostado, multiplicado pelo índice multiplicador verificado no momento exato em que você determinar o CASH OUT.
                        </p>
                        <p>
                            <b>2.    TERMOS E CONDIÇÕES GERAIS DA PROMOÇÃO PARA AFILIADOS DA ESTRELABET, RELATIVAMENTE AO JOGO MOTOGRAU </b>
                        </p>
                        <p>
                            Para que o MotoGrau seja um sucesso, a EstrelaBet resolveu criar uma promoção super especial para os(as) seus(uas) afilados(as), cujas regras são as seguintes
                        </p>
                        <p>
                            2.1.  Poderão participar desta promoção específica, apenas os(as) Afiliados(as) que possuam vínculo com a EstrelaBet no dia em que o MotoGrau for lançado oficialmente no site www.estrelabet.com.
                        </p>
                        <p>
                            2.2.  Para participar, o(a) Afiliado(a) precisa promover o jogo MotoGrau na sua base de jogadores, incentivando cada vez mais esses jogadores a jogarem MotoGrau. Quanto mais a sua base de jogadores jogar o MotoGrau, maior será a sua chance de alcançar os marcos esta promoção.
                        </p>
                        <p>
                            2.3  Esta promoção se inicia no dia 21 de junho de 2023 e se encerra no prazo determinado de 60 (sessenta) dias, contados da data de início.
                        </p>
                        <p>
                            2.4  Durante o prazo desta promoção, os(as) Afiliados(as) não qualificados como “Gerente de Afiliados”, terão direito a um CPA promocional, chamado de “SCPA”, em virtude do qual, para cada jogador vinculado ao(à) Afiliado(a) (especificamente com o link ou cupom deste(a) Afiliado(a)) que realizar um depósito mínimo e efetivamente apostar o valor depositado, o(a) Afiliado(a) receberá uma premiação em virtude de cada marco alcançado, seguindo as seguintes regras e metas estabelecidas:
                        </p>
                        <p>
                            2.4.1  O(A) Afiliado(a) que acumular 100 (cem) jogadores que depositarem e efetivamente apostarem R$100,00 (cem reais) por meio do link de ou cupom específico do(a) Afiliado(a) no jogo MotoGrau, dentro do site www.estrelabet.com, receberá, uma única vez, o valor de R$ 2.500,00 (dois mil e quinhentos reais).
                        </p>
                        <p>
                            2.4.2  O(A) Afiliado(a) que acumular 200 (duzentos) jogadores que depositarem e efetivamente apostarem R$100,00 (cem reais) por meio do link de ou cupom específico do(a) Afiliado(a) no jogo MotoGrau, dentro do site www.estrelabet.com, receberá, uma única vez, o valor de R$ 5.000,00 (cinco mil reais).
                        </p>
                        <p>
                            2.4.3  O(A) Afiliado(a) que acumular 300 (trezentos) jogadores que depositarem e efetivamente apostarem R$100,00 (cem reais) por meio do link de ou cupom específico do(a) Afiliado(a) no jogo MotoGrau, dentro do site www.estrelabet.com, receberá, uma única vez, o valor de R$ 7.500,00 (sete mil e quinhentos reais).
                        </p>
                        <p>
                            2.4.4  O(A) Afiliado(a) que acumular 400 (quatrocentos) jogadores que depositarem e efetivamente apostarem R$100,00 (cem reais) por meio do link de ou cupom específico do(a) Afiliado(a) no jogo MotoGrau, dentro do site www.estrelabet.com, receberá, uma única vez, o valor de R$ 10.000,00 (dez mil reais).
                        </p>
                        <p>
                            2.4.5  O(A) Afiliado(a) que acumular 500 (quinhentos) jogadores que depositarem e efetivamente apostarem R$100,00 (cem reais) por meio do link de ou cupom específico do(a) Afiliado(a) no jogo MotoGrau, dentro do site www.estrelabet.com, receberá, uma única vez, o valor de R$ 12.500,00 (doze mil e quinhentos reais).
                        </p>
                        <p>
                            2.4.6  O(A) Afiliado(a) que acumular 600 (seiscentos) jogadores que depositarem e efetivamente apostarem R$100,00 (cem reais) por meio do link de ou cupom específico do(a) Afiliado(a) no jogo MotoGrau, dentro do site www.estrelabet.com, receberá, uma única vez, o valor de R$ 15.000,00 (quinze mil reais).:
                        </p>
                        <p>
                            2.4.7  O(A) Afiliado(a) que acumular 700 (setecentos) jogadores que depositarem e efetivamente apostarem R$100,00 (cem reais) por meio do link de ou cupom específico do(a) Afiliado(a) no jogo MotoGrau, dentro do site www.estrelabet.com, receberá, uma única vez, o valor de R$ 17.500,00 (dezessete mil e quinhentos reais)
                        </p>
                        <p>
                            2.4.8  O(A) Afiliado(a) que acumular 800 (oitocentos) jogadores que depositarem e efetivamente apostarem R$100,00 (cem reais) por meio do link de ou cupom específico do(a) Afiliado(a) no jogo MotoGrau, dentro do site www.estrelabet.com, receberá, uma única vez, o valor de R$ 20.000,00 (vinte mil reais).
                        </p>
                        <p>
                            2.4.9  O(A) Afiliado(a) que acumular 900 (novecentos) jogadores que depositarem e efetivamente apostarem R$100,00 (cem reais) por meio do link de ou cupom específico do(a) Afiliado(a) no jogo MotoGrau, dentro do site www.estrelabet.com, receberá, uma única vez, o valor de R$ 22.500,00 (vinte e dois mil e quinhentos reais).
                        </p>
                        <p>
                            2.4.10  O(A) Afiliado(a) que acumular 1.000 (mil) jogadores que depositarem e efetivamente apostarem R$100,00 (cem reais) por meio do link de ou cupom específico do(a) Afiliado(a) no jogo MotoGrau, dentro do site www.estrelabet.com, receberá, uma única vez, o valor de R$ 25.000,00 (vinte e cinco mil reais).
                        </p>
                        <p>
                            2.5  Durante o prazo desta promoção, os(as) Gerentes de Afiliados, assim qualificados pela Estrelabet, terão direito a um CPA promocional, chamado de “SCPA Gerentes”, em virtude do qual, para cada jogador vinculado ao(à) Afiliado(a) que realizar o depósito mínimo de R$ 100,00 (cem reais) e efetivamente apostar, no mínimo, R$ 100,00 (cem reais) no jogo MotoGrau, a Estrelabet pagará ao(à) Gerente de Afiliados o valor de R$ 5,00 (cinco reais) por jogador que preencher as condições indicadas anteriormente. O “SCPA Gerentes” também será limitado ao marco de 1.000 (mil) jogadores que depositarem e efetivamente apostarem R$100,00 (cem reais) por meio do link de ou cupom específico do(a) Afiliado(a) no jogo MotoGrau.
                        </p>
                        <p>
                            2.6  Será considerado como “marco” o momento em que o(a) Afiliado(a) alcançar o número acumulado de jogadores que depositaram e efetivamente apostarem por meio do seu link ou cupom específico.
                        </p>
                        <p>
                            2.7  A apuração do marco atingido pelo(a) Afiliado(a), bem como o pagamento das premiações relativas ao marco alcançado, serão realizados após o término da promoção, ou seja, decorridos 60 (sessenta) dias de seu início.
                        </p>
                        <p>
                            2.8  As premiações não são cumulativas, portanto, o(a) Afiliado(a) receberá uma única premiação, de acordo com a posição do seu marco atingido no momento da apuração, sendo enquadrado em apenas um dos itens previstos no inciso 2.4.
                        </p>
                        <p>
                            2.9  Os prêmios ou valores que o(a) Afiliado(a) eventualmente fizer jus, em virtude desta promoção, se somam às condições específicas do seu vínculo de Afiliado(a) com a EstrelaBet.
                        </p>
                        <p>
                            2.10  Até 5 (cinco) dias úteis após o encerramento da promoção, a EstrelaBet irá divulgar ao(a) Afiliado(a) o seu marco final atingido, relativamente à promoção acima.
                        </p>
                        <p>
                            2.11  Até 5 (cinco) dias úteis após a divulgação dos resultados, a EstrelaBet irá definir os ganhadores, bem como a data e horários para transferência dos valores referentes aos prêmios, que ocorrerá mediante transferência bancária (TED).
                        </p>
                        <p>
                            2.12  Qualquer caso de dúvida, questionamento, dupla interpretação ou omissão serão resolvidos pela EstrelaBet, a seu critério exclusivo, visando a sustentabilidade da promoção.
                        </p>
                    </div>
                    <div className='button-term'>
                        <button onClick={() => onClose()}>
                            Fechar
                        </button>
                    </div>
                </Card>
            </Container>
        </>
    )
}

export default Regulation;