import React from 'react';

import { Input } from '@chakra-ui/react';

import { Container } from '../styles';

interface CustomInputProps {
  placeholder: string,
  type: string,
  name?: string,
  label?: string,
  change: (e: string) => void,
  value?: string,
  isError?: boolean,
  msgError?: string,
  readonly?: boolean,
}

const CustomInput: React.FC<CustomInputProps> = ({
  placeholder,
  type,
  name,
  label = '',
  change,
  value,
  isError,
  msgError,
  readonly,
}) => {
  return (
    <Container>
      {label !== '' && <label>{label}</label>}
      <Input
        name={name}
        onChange={e => change(e.target.value)}
        className={readonly ? 'custom-input-readonly' : 'custom-input'}
        placeholder={placeholder}
        type={type}
        value={value}
        errorBorderColor='red.500'
        isInvalid={isError}
        isReadOnly={readonly}
      />
      {isError && <p className="error-msg">{msgError}</p>}
    </Container>
  );
}

export default CustomInput;