import React, { ReactNode } from "react";

import { Container } from "./styles";

interface SubHeaderProps {
  children: ReactNode
}

const SubHeader: React.FC<SubHeaderProps> = ({
  children
}) => {
  return (
    <Container>
      <div className="info-content">
        { children }
      </div>
    </Container>
  );
};

export default SubHeader;
