import React from 'react';

import { MdContentCopy } from "react-icons/md";

import { Container } from './styles';

import { useToast } from '@chakra-ui/react';

const ApiStelarBox: React.FC = () => {
  const toast = useToast();

  function copyLink() {
    var copyText = document.getElementById("link-input") as HTMLInputElement;

    if (copyText) {
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      navigator.clipboard.writeText(copyText.value);
      toast({
        title: 'Copiado para área de transferência',
        status: 'success',
        position: 'top',
        isClosable: true,
      })
    }
  }

  return (
    <Container>
      <h1>API Stelar</h1>
      <p>Com a API do Stelar você terá acesso as informações dos rounds que acabaram de ocorrer no jogo.</p>
      <p>Para integrar na API do Stelar copie o domínio abaixo e siga a documentação.</p>
      <h2>Homologação</h2>
      <div className="link-row">
        <input id="link-input" className="link" readOnly placeholder="http://ec2-15-229-83-169.sa-east-1.compute.amazonaws.com" value="http://ec2-15-229-83-169.sa-east-1.compute.amazonaws.com" />
        <button onClick={copyLink} className="link-copy"><MdContentCopy /> Copiar Link</button>
      </div>
      <h2>Documentação para acessar a API</h2>
      <button onClick={() => window.open('https://github.com/EstrelaBet/estelar-afilliate-docs/blob/main/README.md', '_blank')} className="doc-button">Abrir documentação</button>
    </Container >
  );
}

export default ApiStelarBox;