import styled from 'styled-components';
import { colors } from '../../../styles/tokens/color';

export const Container = styled.div`
    justify-content: center;
    align-items: center;
`

export const Card = styled.div`
    width: 403px;
    height: 486px;
    padding: 24px 30px 24px 30px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.12);
    border-radius: 16px;

    @media (max-width: 500px) {
        width: 327px;
    }

    @media (max-width: 360px) {
        width: 280px;
        height: 505px;
    }

    img {
        width: 131px;
        margin-bottom: 17px;
    }

    h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #000000;
        margin-bottom: 12px;
    }

    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        color: #000000;
        margin-bottom: 12px;
        padding-right: 17PX;
    }

    .content-text {
        max-height: 295px;
        overflow-x: auto;
        margin-bottom: 18px;

        span {
            font-style: italic;
        }

        table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
        }

        td {
            border: 1px solid #dddddd;
            text-align: center;
            padding: 8px;
        }

        td:last-child {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
        }

        th {
            border: 1px solid #dddddd;
            text-align: center;
            padding: 8px;
        }

        ::-webkit-scrollbar {
            width: 4px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #FFFFFF; 
            border: 1px solid #D9D9D9;
            border-radius: 3px;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background: #D9D9D9; 
        }
    }

    .button-term {
        display: flex;
        justify-content: center;
    }

    button {
        width: 189px;
        height: 43px;
        background: #FFD60A;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        color: #011032;

        &:hover {
            background: ${colors.dark.yellow};
        }
    }
`