import styled from "styled-components";
// eslint-disable-next-line import/no-extraneous-dependencies
import Carousel from "react-elastic-carousel";

export const Container = styled(Carousel)`
  display: none;
  position: relative;
  min-width: 507px;
  height: 300px;
  margin: 0;
  padding: 0;
  height: fit-content;

  @media (max-width: 1320px) {
    display: flex;
  }

  @media (max-width: 1065px) {
    display: none;
  }

  @media (max-width: 1024px) {
    display: flex;
  }

  @media (max-width: 813px) {
    display: none;
  }

  .rec-slider-container {
    height: 230px;
    padding-bottom: 10px;
    margin: 0;
  }

  .rec-carousel {
    margin: 0;
    padding: 0;
    min-height: 230px;

    button {
      display: none;
    }
  }


  .rec-item-wrapper {
    padding: 0;
    margin: 0;
    justify-content: center;
    height: 230px;
    align-items: center;
  }

  .rec-carousel-item {
    padding: 0;
    margin: 0;
    width: fit-content;
  }

  .rec-pagination {
    position: absolute;
    bottom: 0;
    left: 235px;
    
    button {
      &:hover {
        box-shadow: 0 0 1px 3px rgba(26, 39, 75,.5)
      }

      &.rec-dot_active {
        box-shadow: 0 0 1px 3px rgba(26, 39, 75,1);
        background-color: rgba(26, 39, 75,.5);
      }
    }
  }
`

export const ContainerMob = styled(Carousel)`
  display: none;
  position: relative;
  min-width: unset;
  width: 255px;
  height: 300px;
  margin: 0;
  padding: 0;
  height: fit-content;

  @media (max-width: 1065px) {
    display: flex;
  }

  @media (max-width: 1024px) {
    display: none;
  }

  @media (max-width: 813px) {
    display: flex;
  }

  @media (max-width: 525px) {
    width: 240px;
  }

  @media (max-width: 565px) {
    display: none;
  }

  .rec-slider-container {
    height: 230px;
    padding-bottom: 10px;
    margin: 0;
  }

  .rec-carousel {
    margin: 0;
    padding: 0;

    min-height: 230px;

    button {
      display: none;
    }
  }


  .rec-item-wrapper {
    padding: 0;
    margin: 0;
    justify-content: center;
    height: 230px;
    align-items: center;
  }

  .rec-carousel-item {
    padding: 0;
    margin: 0;
    width: fit-content;
  }

  .rec-pagination {
    position: absolute;
    bottom: 0;
    left: 95px;
    
    button {
      &:hover {
        box-shadow: 0 0 1px 3px rgba(26, 39, 75,.5)
      }

      &.rec-dot_active {
        box-shadow: 0 0 1px 3px rgba(26, 39, 75,1);
        background-color: rgba(26, 39, 75,.5);
      }
    }
  }
`