import styled from 'styled-components';
import { colors } from '../../../styles/tokens/color';

export const Container = styled.div`
  width: 514px;
  height: 282px;
  margin: 18px 0px 18px 0px;
  background-color: ${colors.white.background};
  border-radius: 16px;
  padding: 15px 18px 90px 18px;
  flex-direction: column;
  align-items: center;
  place-content: center;
  box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.12);

  @media (max-width: 1500px) {
    /* width: 510px; */
  }

  @media (max-width: 1360px) {
    width: 550px;
    padding: 20px 24px 24px 24px;
    height: auto;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 20px 16px 24px 16px;
  }

  @media (max-width: 400px) {
    padding: 20px 8px 24px 8px;
  }


  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: ${colors.primary.blue};
  }

  /* CSS TABLE ??? */
  table {
    font-family: 'Inter';
    font-style: normal;
    width: 100%;
    margin-top: 24px;
    color: ${colors.primary.blue};

    @media (max-width: 1360px) {
      margin-top: 15px;
    }
  }

  th {
    font-weight: 700;
    text-align: left;
    padding-bottom: 15px;
  }

  th:nth-child(2) {
    @media (max-width: 600px) {
      text-align: right;
    }
  }

  th:last-child {
    text-align: center;
  }

  tr {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  td {
    padding: 3px 0px;
    
    @media (max-width: 600px) {
      margin: 20px 0;
    }
  }

  td:first-child {
    width: 140px;

    @media (max-width: 600px) {
      width: auto;
    }

    @media (max-width: 400px) {
      max-width: 70px;
    }
  }

  td:nth-child(2) {
    margin: 0px 12px;

    @media (max-width: 600px) {
      text-align: right;
    }

    @media (max-width: 400px) {
      max-width: 100px;
    }
  }

  td:last-child {
    justify-content: center;
    display: flex;

    @media (max-width: 600px) {
      width: 40px;
      margin-left: auto;
    }
    
    @media (max-width: 350px) {
      width: 35px;
    }
  }

  svg {
    font-size: 18px;
    color: #D3D3D3;
    transition: 0.2s all; 
    cursor: pointer;

    &:hover {
      color: ${colors.primary.blue};
    }
  }

  .conditional-th {
    @media (max-width: 600px) {
      display: none;
    }
  }

  .rectangle {
    width: 90px;
    height: 1px;
    background: #FFD60A;
    margin-bottom: 15px;

    @media (max-width: 1360px) {
      margin-bottom: 25px;
      margin-top: 4px;
    }
  }
`;
