import React, { ReactNode } from 'react';
import { CircularLgC } from '../styles';

interface CircularProps {
    enable: boolean;
    color: string;
    content: ReactNode;
}

const CircularLg: React.FC<CircularProps> = ({ enable, color, content }) => {
    return (
        <CircularLgC color={enable ?  color : '#C4BEB6' }>
            {
                content
            }
        </CircularLgC>
    );
};

export default CircularLg;