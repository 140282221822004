import React from "react";
 
import { Container } from "./styles";

interface TableAffiliatesReportProps {
  affiliates: Affiliates[];
}

export interface Affiliates {
  AffiliateId: number;
  AffiliateName: string;
  VisitCount: number;
  RegistrationCount: number;
  RevShare: string;
  Cpa: number;
  Ftds: number;
  FtdsAmount: number;
  QftdsCpa: number;
  ComissionsTotal: number;
}

const TableAffiliatesReport: React.FC<TableAffiliatesReportProps> = ({ affiliates }) => {
  const getPercentage = (value: any) => {
    if (value) {
      return value + '%';
    }
    return "N/A";
  };

  const getValue = (value: any) => {
    if (value) {
      return value;
    }
    return "N/A";
  };

  const getMoney = (value: number) => {
    if (value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    }
    return "N/A";
  };

  return (
    <Container>
      <table>
        <thead>
          <tr className="headers">
            <th className="id">ID Afiliado</th>
            <th className="nickname">Nickname</th>
            <th className="visits">Visitas</th>
            <th className="register">Registros</th>
            <th className="revshare">RevShare</th>
            <th className="cpa">CPA</th>
            <th className="ftds">FTDs</th>
            <th className="ftds-amount">FTDs Amount</th>
            <th className="qftds-cpa">QFTDs, CPA</th>
            <th className="comission">Comissão</th>
          </tr>
        </thead>
        <tbody>
          {affiliates.map((affiliate) => (
            <tr key={affiliate.AffiliateId}>
              <td className="id">{getValue(affiliate.AffiliateId)}</td>
              <td className="nickname">{getValue(affiliate.AffiliateName)}</td>
              <td className="visits">{getValue(affiliate.VisitCount)}</td>
              <td className="register">{getValue(affiliate.RegistrationCount)}</td>
              <td className="revshare">{getPercentage(affiliate.RevShare)}</td>
              <td className="cpa">{getValue(affiliate.Cpa)}</td>
              <td className="ftds">{getValue(affiliate.Ftds)}</td>
              <td className="ftds-amount">{getValue(affiliate.FtdsAmount)}</td>
              <td className="qftds-cpa">{getValue(affiliate.QftdsCpa)}</td>
              <td className="comission">{getMoney(affiliate.ComissionsTotal)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default TableAffiliatesReport;
