import axios from "axios";
import { createContext, ReactNode, useEffect, useState } from "react";
import endPoints from "../utils/endPoints";
import { useAuth0 } from '@auth0/auth0-react';
// import runOneSignal from "appIntegration";

interface UserProps {
  name: string,
  email: string,
  balance: number,
  id: number,
  type: number,
  person_type: boolean,
  managerId: number,
  smartico_id: number,
  cpf: string,
  auth0_token: string,
  user_terms: boolean,
}

interface UserProviderProps {
  children: ReactNode
}

interface UserContextData {
  userData: UserProps,
  getUser: () => Promise<void>,
  isAuth: boolean,
  setExpandMenu: (value: boolean) => void;
  expandMenu: boolean;
}

export const UserContext = createContext<UserContextData>({} as UserContextData);

export function UserProvider({ children }: UserProviderProps) {
  const [userData, setUserData] = useState({} as UserProps);
  const [isAuth, setIsAuth] = useState(false);
  const [expandMenu, setExpandMenu] = useState(false);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const getUser = async () => {
    let users = endPoints.users;

    const url = `${users}/user`;

    if (isAuthenticated) {
      const auth0_token = await getAccessTokenSilently();
      axios.get(url).then((response) => {
        const userData = {
          ...response.data,
          auth0_token: auth0_token
        }
        setUserData(userData);
        setIsAuth(true);
      }).catch((e) => {
        console.log(e);
        setIsAuth(false);
      })
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      getUser();
    }
  }, [isAuthenticated]);

  return (
    <UserContext.Provider value={{ userData, getUser, isAuth, setExpandMenu, expandMenu }}>
      {children}
    </UserContext.Provider>
  )
}
