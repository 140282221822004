export const cashPayIcon =
  <svg className="pay-icon" width="152" height="152" viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M133.633 100.383C131.942 98.8513 129.915 97.7378 127.715 97.1328C125.515 96.5277 123.203 96.448 120.967 96.9001L89.6167 102.283C89.5775 98.3512 87.9836 94.5943 85.1833 91.8334L62.7 69.6667H15.8333C14.1536 69.6667 12.5427 70.334 11.355 71.5217C10.1673 72.7095 9.5 74.3204 9.5 76.0001C9.5 77.6798 10.1673 79.2907 11.355 80.4784C12.5427 81.6662 14.1536 82.3334 15.8333 82.3334H57.6333L76.3167 101.017C76.7621 101.487 77.0103 102.111 77.0103 102.758C77.0103 103.406 76.7621 104.03 76.3167 104.5C75.7942 104.967 75.1177 105.226 74.4167 105.226C73.7156 105.226 73.0392 104.967 72.5167 104.5L61.4333 93.4167C60.2141 92.3262 58.6358 91.7234 57 91.7234C55.3643 91.7234 53.7859 92.3262 52.5667 93.4167C51.3858 94.6538 50.727 96.2982 50.727 98.0084C50.727 99.7186 51.3858 101.363 52.5667 102.6L63.65 113.367C65.0314 114.822 66.7045 115.969 68.5599 116.733C70.4153 117.497 72.4111 117.861 74.4167 117.8C76.3554 117.792 78.28 117.471 80.1167 116.85L123.183 109.25C123.626 109.169 124.081 109.184 124.518 109.293C124.955 109.403 125.364 109.604 125.717 109.883C126.287 110.486 126.624 111.272 126.667 112.1C126.682 112.841 126.436 113.564 125.973 114.142C125.511 114.721 124.859 115.119 124.133 115.267L65.2333 126.033L30.7167 97.8501H15.8333C14.1536 97.8501 12.5427 98.5173 11.355 99.7051C10.1673 100.893 9.5 102.504 9.5 104.183C9.5 105.863 10.1673 107.474 11.355 108.662C12.5427 109.849 14.1536 110.517 15.8333 110.517H26.2833L61.4333 139.333L126.35 127.617C130.012 127.011 133.336 125.112 135.718 122.265C138.101 119.418 139.383 115.812 139.333 112.1C139.358 109.839 138.856 107.603 137.866 105.569C136.877 103.535 135.428 101.76 133.633 100.383Z" fill="#E6E6E6" />
      <path d="M108.617 63.65C103.954 63.742 99.391 62.2952 95.6333 59.5334C94.9296 59.0655 94.1394 58.7431 93.3093 58.5853C92.4791 58.4275 91.6257 58.4374 90.7995 58.6145C89.9732 58.7915 89.1907 59.1322 88.4981 59.6163C87.8055 60.1004 87.2168 60.7182 86.7666 61.4334C86.2673 62.0273 85.8945 62.7169 85.671 63.46C85.4476 64.2031 85.3782 64.984 85.4671 65.7548C85.5561 66.5256 85.8015 67.2702 86.1883 67.9428C86.5751 68.6155 87.0951 69.2021 87.7166 69.6667C91.7914 72.5264 96.4365 74.4709 101.333 75.3667V82.3334C101.333 84.0131 102.001 85.624 103.188 86.8117C104.376 87.9995 105.987 88.6667 107.667 88.6667C109.346 88.6667 110.957 87.9995 112.145 86.8117C113.333 85.624 114 84.0131 114 82.3334V75.3667C117.474 74.8544 120.751 73.4344 123.5 71.25C125.485 69.7928 127.095 67.8839 128.196 65.6814C129.297 63.4788 129.858 61.0458 129.833 58.5834C129.833 46.8667 119.067 43.0667 109.883 41.1667C100.7 39.2667 98.7999 37.05 98.7999 35.4667C98.7999 33.8834 99.4333 33.5667 100.383 32.6167C102.13 31.6775 104.103 31.2392 106.083 31.35C109.763 31.3181 113.379 32.3045 116.533 34.2C117.174 34.7232 117.922 35.1005 118.723 35.3057C119.525 35.511 120.361 35.5392 121.175 35.3886C121.989 35.2379 122.76 34.9119 123.435 34.4332C124.109 33.9546 124.672 33.3347 125.083 32.6167C125.626 31.9692 126.026 31.2145 126.258 30.402C126.49 29.5894 126.548 28.7372 126.428 27.9007C126.309 27.0643 126.015 26.2623 125.565 25.5472C125.115 24.832 124.519 24.2197 123.817 23.75C120.805 21.8914 117.478 20.6032 114 19.95V12.6667C114 10.987 113.333 9.37609 112.145 8.18836C110.957 7.00063 109.346 6.33337 107.667 6.33337C105.987 6.33337 104.376 7.00063 103.188 8.18836C102.001 9.37609 101.333 10.987 101.333 12.6667V19.6334C91.8333 21.2167 85.4999 27.55 85.4999 36.1C85.4999 44.65 95.9499 51.6167 106.083 53.5167C116.217 55.4167 116.533 57.6334 116.533 59.2167C116.533 60.8 113.05 63.65 108.617 63.65Z" fill="#E6E6E6" />
    </g>
    <defs>
      <clipPath id="clip0_2345_4376">
        <rect width="152" height="152" fill="white" />
      </clipPath>
    </defs>
  </svg>
