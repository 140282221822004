import React from 'react';

import { BiCalendar } from 'react-icons/bi';

import { Container } from './styles';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import br from 'date-fns/locale/pt-BR';

interface DateInputProps {
  label: string,
  change: (e: Date | null) => void,
  value?: Date | null,
  isError?: boolean,
  msgError?: string,
  setClearFaster?: (e: any) => void,
}

const DateInput: React.FC<DateInputProps> = ({ label = '', change, value, isError, msgError, setClearFaster }) => {

  const handleSetDate = (date: Date | null) => {
    change(date);
    if (setClearFaster) {
      setClearFaster(null);
    }
  }

  return (
    <Container isError={isError}>
      <label>
        {label}
        <BiCalendar />
      </label>
      <DatePicker 
        locale={br}
        onChange={date => handleSetDate(date)}
        selected={value}
        dateFormat="dd/MM/yyyy"
      />
      {isError && <p className="error-msg">{msgError}</p>}
    </Container>
  );
}

export default DateInput;