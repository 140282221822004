import styled from 'styled-components';
import { colors } from '../../../styles/tokens/color';

export const FormGroup = styled.form`
  min-width: 328px;
  width: 320px;
  margin: 20px 20px 0px 0px;
  background-color: ${colors.white.background};
  border-radius: 16px;
  padding: 24px 20px;
  flex-direction: column;
  align-items: center;
  place-content: center;
  height: fit-content;
  box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.12);

  @media (max-width: 1360px) {
    margin: 24px 0px 0px 0px;
    width: 550px;
    min-height: unset;
    min-width: unset;
  }

  @media (max-width: 600px) {
    padding: 20px 12px 14px 12px;
    width: 100%;
  }

  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: ${colors.primary.blue};
  }

  .block {
    color: ${colors.grey.block}
  }

  h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: ${colors.primary.blue};
    margin-bottom: 21px;
  }

  .submit-button {
    width: 100%;
    height: 48px;
    margin: 2px 0;
    background: #FFD60A;
    border: 1px solid #FFD60A;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.primary.blue};
    transition: all 0.2s;

    &:hover {
      background: ${colors.dark.yellow};
    }
  }

  .block-button {
    width: 100%;
    height: 48px;
    margin: 2px 0;
    background: ${colors.grey.block};
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    transition: all 0.2s;
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: ${colors.primary.blue};
    margin: 7px 0px 16px 0px;
  }
`;
