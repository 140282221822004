interface AffiliateId {
  smarticoId: number;
  managerId: number;
  path: string;
}

export const affiliateIds: Record<string, AffiliateId> = {
  // "igor.souza.f": {
  //   smarticoId: 38611,
  //   managerId: 1001198,
  //   path: "/671dee26f3e66a06a43ca8f9adef35ba",
  // },
  // nivaldobarbosa: {
  //   smarticoId: 37234,
  //   managerId: 1001344,
  //   path: "/5c0800b5fbdfd8fc16b6d51f4fa83b63",
  // },
  // ortegatips: {
  //   smarticoId: 71800,
  //   managerId: 1006674,
  //   path: "/22425d0c05047f33df2e8c71621b6b70",
  // },
  // rudisonsantos: {
  //   smarticoId: 79072,
  //   managerId: 1007071,
  //   path: "/f67e88967db42203f5db93ab16b650e3",
  // },
  // "gabriel-morais98": {
  //   smarticoId: 101084,
  //   managerId: 1008807,
  //   path: "/eb160e52e20fde313adf3a9a6d7b06b4",
  // },
  magoaviator: {
    smarticoId: 37119,
    managerId: 1001407,
    path: "/2b49fab860db7d976912ecd21b49c2b4",
  },
  // marcosmoreira: {
  //   smarticoId: 51350,
  //   managerId: 1003320,
  //   path: "/ddd20c750358c6d9b9940a6b37e772cf",
  // },
  cervantesedvan: {
    smarticoId: 252214,
    managerId: 1014225,
    path: "/bc4590845e6c3c120f7c1e8809c88402",
  },
  kaiofelipe: {
    smarticoId: 90842,
    managerId: 1008116,
    path: "/843d2da8b9fbf0417433a3f9aad34089",
  },
  daviolliveira: {
    smarticoId: 308315,
    managerId: 1016226,
    path: "/0fd7065da4f854148c21eafc6e31a751",
  },
  tarcisiosoares: {
    smarticoId: 37351,
    managerId: 1001455,
    path: "/35479660e507911ad0ede9335212fa35",
  },
};

// Variáveis smartico_ é a oferta que irá para o sub afiliado
// As demais são variáveis à serem enviadas para o sistema interno
// Path is randomly generated MD5

const defaultDeal = {
  rev_share_percentage: 20,
  cpa_amount: 0,
  qua_cpa_deposit: 0,
  qua_cpa_volume: 0,
  smartico_cpa_amount: 0,
  smartico_rev_share_percentage: 20,
  smartico_qua_cpa_deposit: 0,
  smartico_qua_cpa_volume: 0,
};

export const buildDeals = (userId: number): typeof defaultDeal => {
  switch (userId) {
    case 1008730:
      return {
        ...defaultDeal,
        rev_share_percentage: 20,
        qua_cpa_deposit: 30,
        qua_cpa_volume: 30,
        smartico_cpa_amount: 30,
        smartico_qua_cpa_deposit: 30,
        smartico_qua_cpa_volume: 30,
        smartico_rev_share_percentage: 15,
      };
    case 1001290:
      return {
        rev_share_percentage: 10,
        cpa_amount: 0,
        qua_cpa_deposit: 50,
        qua_cpa_volume: 50,
        smartico_cpa_amount: 50,
        smartico_rev_share_percentage: 25,
        smartico_qua_cpa_deposit: 50,
        smartico_qua_cpa_volume: 50,
      };
    case 1015731:
      return {
        rev_share_percentage: 10,
        cpa_amount: 0,
        qua_cpa_deposit: 50,
        qua_cpa_volume: 50,
        smartico_cpa_amount: 50,
        smartico_rev_share_percentage: 25,
        smartico_qua_cpa_deposit: 50,
        smartico_qua_cpa_volume: 50,
      };
    case 1004895:
      return {
        rev_share_percentage: 10,
        cpa_amount: 0,
        qua_cpa_deposit: 50,
        qua_cpa_volume: 50,
        smartico_cpa_amount: 50,
        smartico_rev_share_percentage: 25,
        smartico_qua_cpa_deposit: 50,
        smartico_qua_cpa_volume: 50,
      };
    case affiliateIds.cervantesedvan.managerId:
      return {
        smartico_cpa_amount: 0,
        smartico_qua_cpa_deposit: 50,
        smartico_qua_cpa_volume: 50,
        smartico_rev_share_percentage: 30,
        cpa_amount: 50,
        qua_cpa_deposit: 50,
        qua_cpa_volume: 50,
        rev_share_percentage: 0,
      };
    case affiliateIds.kaiofelipe.managerId:
      return {
        smartico_cpa_amount: 30,
        smartico_qua_cpa_deposit: 50,
        smartico_qua_cpa_volume: 50,
        smartico_rev_share_percentage: 20,
        cpa_amount: 20,
        qua_cpa_deposit: 50,
        qua_cpa_volume: 50,
        rev_share_percentage: 10,
      };
    case affiliateIds.magoaviator.managerId:
      return {
        smartico_cpa_amount: 25,
        smartico_qua_cpa_deposit: 50,
        smartico_qua_cpa_volume: 50,
        smartico_rev_share_percentage: 15,
        cpa_amount: 25,
        qua_cpa_deposit: 50,
        qua_cpa_volume: 50,
        rev_share_percentage: 5,
      };
    case affiliateIds.daviolliveira.managerId:
      return {
        smartico_cpa_amount: 50,
        smartico_qua_cpa_deposit: 50,
        smartico_qua_cpa_volume: 50,
        smartico_rev_share_percentage: 15,
        cpa_amount: 0,
        qua_cpa_deposit: 50,
        qua_cpa_volume: 50,
        rev_share_percentage: 5,
      };
    case affiliateIds.tarcisiosoares.managerId:
      return {
        rev_share_percentage: 25,
        cpa_amount: 0,
        qua_cpa_deposit: 50,
        qua_cpa_volume: 50,
        smartico_cpa_amount: 50,
        smartico_rev_share_percentage: 5,
        smartico_qua_cpa_deposit: 50,
        smartico_qua_cpa_volume: 50,
      };
    default:
      return defaultDeal;
  }
};
