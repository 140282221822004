import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Auth0LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const scope = process.env.REACT_APP_AUTH0_SCOPE;

  return <button className="submit-button" onClick={() => loginWithRedirect({ authorizationParams: { scope: scope, audience: audience } })}>Entrar</button>;
};

export default Auth0LoginButton;
