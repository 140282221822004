import styled from "styled-components";

export const Container = styled.div`
  background: #FFFFFF;
  box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 6px 6px 30px rgba(174, 195, 214, 0.29);
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  margin-top: 19px;

  p {
    margin: 5px 0;
    font: 400 12px/15px 'Inter';

    @media (max-width: 515px) {
      font-size: 11px;
    }
  }

  .info-content {
    background: #F7F7F7;
    border-radius: 16px;
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;

    @media (max-width: 1450px) {
      display: grid;
    }

    @media (max-width: 610px) {
      padding: 15px;
    }

    @media (max-width: 515px) {
      padding: 10px;
    }
  }

  .periods-div {
    display: grid;
    margin-right: 60px;
  }

  .filter-div {
    display: flex;
    align-items: center;

    @media (max-width: 1450px) {
      align-items: flex-start;
      margin-top: 10px;
    }

    .icon-container {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      margin-right: 22.5px;

      @media (max-width: 1450px) {
        width: fit-content;
        height: fit-content;
        background: unset;
        margin-right: 5px;
      }

      svg {
        min-height: 22px;
        min-width: 22px;

        @media (max-width: 1450px) {
          min-height: unset;
          min-width: unset;
          margin-top: 3px;
        }
      }
    }
  }

  .period {
    display: flex;
    align-items: center;

    &:last-child {
      margin-top: 10px;
    }

    svg {
      margin-right: 12px;

      @media (max-width: 515px) {
        min-height: 15px;
        min-width: 15px;
      }
    }
  }
`;