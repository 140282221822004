import React from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import { Container, MobileOrganization, WebOrganization, Pack, SubContainer } from './styles';

import BoxSimple from '../../components/Scpa/BoxSimple';
import BoxInfo from '../../components/Scpa/BoxInfo';
import BoxWatch from '../../components/Scpa/BoxWatch';
import Regulation from '../../components/Scpa/Regulation';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react'

const Scpa: React.FC = () => {

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Container translate="no">
      <SubContainer>
        <div className='header-comp'>
          <HeaderComponent
            title="PROMOÇÕES | SCPA - Motograu"
          />
        </div>
        <WebOrganization>
          <div style={{ display: 'flex'}}>
            <BoxSimple />
            <BoxInfo onOpen={onOpen} />
          </div>
          <Pack>
            <BoxWatch />
          </Pack>
        </WebOrganization>

        <MobileOrganization>
          <BoxSimple />
          <BoxWatch />
          <BoxInfo onOpen={onOpen} />
        </MobileOrganization>
      </SubContainer>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bgColor='transparent' boxShadow='unset' justifyContent='center' display='grid' >
          <Regulation onClose={onClose} />
        </ModalContent>
      </Modal>
    </Container>
  );
}

export default Scpa;