import React, { useState, useEffect } from 'react';
import { Container, Result, Order, Bar, FilledBar, ResultBar, StatusContainer } from './styles';

import awardImg from '../../assets/Award/award.png';
import awardHitImg from '../../assets/Award/award-hit.png';
import fire from '../../assets/fire.png';
import conqueredImg from '../../assets/Award/conquered.png';
import failedImg from '../../assets/Award/award-failed.png';

interface IOrder {
    currentValue: number;
    goalValue: number;
    showAward: string;
    classConditional: string;
    conquered: boolean;
    claimed: boolean;
    status: string;
    showModal: () => void;
    message?: string;
    reasonId?: number;
}

const ResultContainer: React.FC<IOrder> = ({ currentValue, goalValue, showAward, classConditional, conquered = false, showModal, claimed, status, message, reasonId }) => {

    const [color, setColor] = useState('#011032');

    const getFilled = () => {
        const intFilled = getIntFilled()
    
        if (intFilled > 100) {
            return `100%`
        } else {
            return `${intFilled}%`
        }
    }

    const getMoney = (value?: number ) => {
        if (value === undefined || value === null) {
            return 'R$ 0,00'
        }
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    const getMargin = () => {
        const intFilled = getIntFilled()
    
        if (intFilled  > 100) {
            return `99%`
        } else if (intFilled < 1) {
            return `0.01%`
        } else {
            return `${ intFilled - 1}%`
        }
    }

    const getIntFilled = () => {
        if (conquered || claimed) {
            return 100
        }

        let percent = 0

        if (showAward === 'kit') {
            percent = currentValue / 20
        } else if (showAward === 'son') {
            percent = currentValue / 100
        } else if (showAward === 'sirius') {
            percent = currentValue / 500
        } else if (showAward === 'pollux') {
            percent = currentValue / 1000
        }
    
        return Math.trunc(percent)
    }

    const getStatus = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'Pendente';
            case 'CLAIMED':
                return 'Pendente';
            case 'DONE':
                return 'Entregue';
            case 'SENT':
                return 'Enviado';
            case 'FAILED':
                return 'Falha';
            default:
                return 'Sem Status';
        }
    }

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'PENDING':
                return 'rgba(241, 198, 63, 0.5)';
            case 'CLAIMED':
                return 'rgba(241, 198, 63, 0.5)';
            case 'DONE':
                return 'rgba(46, 125, 50, 0.5)';
            case 'SENT':
                return 'rgba(234, 143, 93, 0.5)';
            case 'FAILED':
                return 'rgba(225, 64, 64, 0.5)';
            default:
                return 'grey';
        }
    }

    const getReason = (value: number) => {
        switch (value) {
            case 1:
                return 'Endereço incorreto';
            case 2:
                return 'Premiação voltando';
            case 3:
                return 'Correios não localizou o endereço';
            default:
                return 'Erro'
        }
    }

    useEffect(() => {
        switch (showAward) {
            case 'kit':
                setColor('#011032');
                break;
            case 'son':
                setColor('#E89D58');
                break;
            case 'sirius':
                setColor('#409CEF');
                break;
            case 'pollux':
                setColor('#FFD700');
                break;
            default:
                setColor('#FFAF37');
                break;
        }
    }, [showAward])

    return (
        <Container className={classConditional} >
            <div className='mobile-title-result'>
                <h3>
                    Acompanhe seu resultado
                </h3>
            </div>
            <Result>
                <div className='web-title-result'>
                    <h3>
                        Acompanhe seu resultado
                    </h3>
                    {
                        status !== '' && status !== 'NOT_ACHIEVED' && status !== 'ACHIEVED' && (
                            <StatusContainer color={getStatusColor(status)}>
                                <h3>Status do Pedido:</h3>
                                <div className='status-content'>
                                    <h3>
                                        {getStatus(status)}
                                    </h3>
                                </div>
                            </StatusContainer>
                        )
                    }
                </div>
                <p>
                    Comissão da última semana: {getMoney(currentValue)}
                </p>
                <ResultBar width={getFilled()} margin={getMargin()} color={color}> 
                    {
                        currentValue < goalValue ? (
                            <p>
                                {getMoney(currentValue)}
                            </p>
                        ) : (
                            <></>
                        )
                    }
                    <Bar />
                    <FilledBar width={getFilled()} color={color}/>       
                    <img src={fire} alt='fire' />
                    <p className={currentValue >= goalValue ? '' : 'goal' }>
                        {getMoney(goalValue)}
                    </p>
                </ResultBar>
            </Result>
            <Order>
                {
                    status === 'CLAIMED' || status === 'PENDING' || status === 'SENT' || status === 'DONE' ? (
                        <>
                            <img src={conqueredImg} alt='award' className='conquered'/>
                            <h1>
                                Você já solicitou o prêmio.
                            </h1>
                        </>
                    ) : status === 'FAILED' ? (
                        <>
                            <img src={failedImg} alt='award' className='conquered'/>
                            <h1 className='failed'>
                                Título: <span>{getReason(reasonId || 0)}</span>
                            </h1>
                            <h1 className='failed'>
                                Motivo: <span>{message === '' || !message ? 'Algo deu errado' : message }. <br/> Favor, verificar.</span>
                            </h1>
                        </>
                    ) : (
                        <>
                            <img src={conquered ? awardHitImg : awardImg } alt='award' className={conquered ? 'awardHit' : 'award' }/>
                            <h1>
                                {
                                    conquered ? (
                                        'Parabéns! Você conseguiu alcançar a meta com sucesso.'
                                    ) : (
                                        'Você ainda não conseguiu alcançar a meta dessa premiação.'
                                    )
                                }
                            </h1>
                            <button onClick={showModal} disabled={conquered !== true} className={conquered !== true ? 'disabled' : ''}>
                                SOLICITAR PRÊMIO 
                            </button>
                        </>
                    )
                }
            </Order>
        </Container>
    )
}

export default ResultContainer;