import React from 'react';
import { Container, Header, Divider } from './styles';
import Detail from './Detail';
import { DetailsProps } from '../../../pages/Reports/Performance/utils';

interface DetailsCardProps {
  details: DetailsProps;
  lastText: string;
}

const DetailsCard: React.FC<DetailsCardProps> = ({ details, lastText }) => {
  return (
    <Container>
      <Header>
        <h1>FTDs</h1>
        <p><span>•</span>{lastText}</p>
      </Header>
      <Divider />
      <Detail title='FTDs' detail={details.FtdCount} tooltipText='Quantidade de primeiros depósitos dos usuários associados ao seu perfil'/>
      <Detail title='FTDs Amount' detail={details.FtdTotal} tooltipText='Valor em real dos primeiros depósitos dos usuários associados ao seu perfil'/>
      <Detail title='QFTDs, CPA' detail={details.QftdCount} tooltipText='Valor de depósitos e registros qualificados para comissionamento'/>
    </Container>
    )
}

export default DetailsCard;