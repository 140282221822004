import React, { ReactNode } from 'react';
import { StyledTooltip } from "./styles";

interface CustomTooltipProps {
    label: string;
    children: ReactNode;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ label, children }) => {
  return (
    <StyledTooltip
        label={label}
        aria-label={label}
        hasArrow
        placement='top'
    >
      <span>{children}</span>
    </StyledTooltip>
  );
};

export default CustomTooltip;