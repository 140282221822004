import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  flex: 1 1;
  z-index: 2;

  .header {
    @media (max-width: 1024px) {
      margin: -20px -14px 0 -14px;
    }
  }

  @media (max-width: 1024px) {
    padding-top: 0;
  }

  @media (max-width: 1024px) {
    width: 100vw;
  }

  .content {
    width: 100%;
    display: flex;

    @media (max-width: 1360px) {
      display: grid;
      padding-bottom: 35px;
      justify-content: center;
    }

    @media (max-width: 575px) {
      padding: 0 10px;
    }
  }

  .conditional-display {
    height: 329px;
    display: grid;
  }

  .center {
    width: 100%;
    justify-content: center;
    display: flex;
  }
`;


export const SubContainer = styled.div`
  max-width: 1320px;
  flex: 1 1;
  width: 100%;
  padding: 20px 14px 0 14px;
`