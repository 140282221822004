import React from 'react';

import { Container } from './styles';
interface BoxWatchProps {
  onOpen: () => void;
}

const BoxWatch: React.FC<BoxWatchProps> = ({ onOpen }) => {
  return (
    <Container>
      <h3>Informações</h3>
      <div className='content'>
        <p><span>Data inicio</span>: 21/06/2023.</p>
        <p><span>Duração da Campanha 60 dias.</span></p>
        <p><span>O Pagamento das comissões ocorrerá ao final da campanha.</span></p>
      </div>
      <div className='button-div'>
        <button onClick={() => onOpen()}>Leitura do Regulamento da Campanha Motograu</button>
      </div>
    </Container>
  )
}

export default BoxWatch;