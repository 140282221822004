import styled from 'styled-components';
import { colors } from '../../../styles/tokens/color';

export const Container = styled.div`
  display: flex;
  width: calc(100vw - 252px);
  justify-content: center;
  overflow-y: auto;

  @media (max-width: 1024px) {
      width: 100%;
  }

  .desktop-buttons {
    display: flex;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .mobile-buttons {
    display: none;

    @media (max-width: 1024px) {
      display: block;
    }

    .mobile-button {
      background-color: ${colors.primary.blue};

      .pay-icon {
        width: 40px;
        path {
          fill: white;
        }
      }

      .mobile-text {
        display: block;
        color: #fff;
      }
    }
  }
`;

export const SubContainer = styled.div`
  max-width: 1320px;
  width: 100%;
  padding: 22px 14px 0px 14px;

  .header {
    @media (max-width: 1024px) {
      margin: -22px -14px 0px -14px;
    }
  }
`

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 0px 0px 0px;

  .no-requests {
    width: 100%;
    padding: 30px 0px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;

    @media (max-width: 575px) {
      margin-top: 20px;
      border-radius: 16px 16px 0px 0px;
    }

    svg {
      height: 50%;
    }

    .empty-text {
      margin-top: 20px;
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      color: ${colors.primary.blue};
    }
  }
`

export const Subheader = styled.div`
  display: none; 

  @media (max-width: 1024px) {
    display: flex;
  }

  p {
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
  }
`

export const PaginationBar = styled.div`
  padding: 15px 24px 11px 15px;
  display: flex;
  align-items: end;
  place-content: end;
  background: #FFFFFF;
  height: 60px;
  box-shadow: 0px 18px 30px -15px rgba(0, 0, 0, 0.12), -15px 0px 10px -15px rgba(0, 0, 0, 0.12), 15px 0px 10px -15px rgba(0, 0, 0, 0.12);
  border-radius: 0px 0px 16px 16px;
  z-index: 0;

  @media (max-width: 575px) {
    place-content: center;
  }

  .pagination-info {
    display: flex;
    align-items: center;

    span {
      font-size: 13px;
      background: #D9D9D9;
      border-radius: 2px;
      padding: 4px 8px;
      margin-right: 10px;
    }

    svg {
      font-size: 24px;
    }
  }
`

export const ModalButtons = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 3;
  display: flex;
  align-items: center;
  place-content: center;

  .close-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(1, 16, 50, 0.3);
  }

  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    z-index: 4;

    .button-mobile {
      background: #fff;
      width: 90vw;
      height: 63px;
      max-width: 320px;
      display: flex;
      align-items: center;
      place-content: center;
      color: ${colors.primary.blue};
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: ${colors.primary.blue};

      &.pix {
        background: #EEEAEA;
      }

      svg {
        margin-right: 10px;

        path {
          fill: ${colors.primary.blue}
        }
      }
    }
  }
`
