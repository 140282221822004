import React, { useState, useRef, useEffect, useCallback } from "react";

import InfoHeader from "components/Headers/InfoHeader";
import HeaderComponent from "components/HeaderComponent";
import Filter from "components/Manager/Filter";
import TableAffiliatesReport from "components/Tables/TableAffiliatesReport";
import MobileTableAffiliatesReport from "components/Tables/MobileTableAffiliatesReport";
import Pagination from "components/Pagination";
import LoadingScreen from "components/LoadingScreen";

import {
  Container,
  SubContainer,
  OverlayLoading,
  Wrapper,
  PaginationBar,
  ButtonExport,
  TableHeader,
} from "./styles";

import { FiUsers } from "react-icons/fi";
import { FaFilter } from "react-icons/fa";
import { TbChevronDown } from "react-icons/tb";
import { RiFileExcel2Fill } from 'react-icons/ri';
import { Tooltip } from '@chakra-ui/react'

import {
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  CircularProgress,
} from "@chakra-ui/react";

import endPoints from "utils/endPoints";
import axios from "axios";
import { Affiliates } from "components/Tables/TableAffiliatesReport";
import { formatDate } from "utils/date";

const AffiliatesReport: React.FC = () => {
  const [tool, setTool] = useState(false);
  const [affiliates, setAffiliates] = useState<Affiliates[]>([]);

  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate] = useState<Date | null>(new Date());

  const [qtdTime, setQtdTime] = useState<number | null>(30);
  const [qtdTimeError, setQtdTimeError] = useState<boolean>(false);
  const [qtdTimeMsgError, setQtdTimeMsgError] = useState<string>("");

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [nPages, setNPages] = useState<number>(0);

  const [filterNick, setFilterNick] = useState<string>("");

  const { subaffiliatesreport } = endPoints;

  const myRef = useRef<any>();
  const myRef2 = useRef<any>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const getAffiliates = useCallback(async (page: number = 1) => {
    setShowLoading(true);
    let url = subaffiliatesreport + `?page=${page}&itemsPerPage=10`;

    if (qtdTime) {
      url += `&time=${qtdTime}`;
    } 

    if (filterNick && filterNick !== "") {
      url += `&nick=${filterNick}`;
    }

    axios
      .get(url)
      .then((response) => {
        setAffiliates(response.data.sub_reports);
        calcPages(response.data.total, page);
        setPage(page);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setShowLoading(false);
      });
  }, [filterNick, qtdTime, subaffiliatesreport]);
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    const calculateNewDate = () => {
      const newDate = new Date();
      newDate.setDate(newDate.getDate() - (qtdTime ?? 30));
      return newDate;
    };
    
    setStartDate(calculateNewDate);
    setQtdTimeError(false);
    setQtdTimeMsgError("");
  }, [qtdTime]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      getAffiliates();
    }, 1500);
    return () => clearTimeout(debounce);
  }, [filterNick, getAffiliates]);

  useEffect(() => {
      getAffiliates();
  }, [getAffiliates]);

  const handleClickOutside = (e: any) => {
    if (
      myRef.current &&
      !myRef.current.contains(e.target) &&
      myRef2.current &&
      !myRef2.current.contains(e.target) &&
      !showLoading &&
      showFilter
    ) {
      setShowFilter(false);
    }
  };

  const handleFilter = async () => {
    if (qtdTime === null) {
      setQtdTimeError(true);
      setQtdTimeMsgError("Campo obrigatório");
      return;
    }

    getAffiliates();
  };

  const downloadFile = async () => {
    const date = new Date()
    setShowLoading(true);
    let url = subaffiliatesreport + `/download?page=${page}&itemsPerPage=10`;

    if (qtdTime) {
      url += `&time=${qtdTime}`;
    } 

    if (filterNick && filterNick !== "") {
      url += `&nick=${filterNick}`;
    }
    axios
      .get(url, {responseType: 'blob'})
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `relatorio-performance-${formatDate(date)}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const calcPages = (totalItems: number, currentPage: number) => {
    let holder = totalItems / 10;
    let nOfPages = Math.ceil(holder);
    setNPages(nOfPages);
  };

  return (
    <Container>
      <LoadingScreen show={showLoading} />
      <SubContainer>
        <div className="header">
          <HeaderComponent
            title="GERENTE | Relatório de Performance"
            buttons={
              <>
                <button className="mb-filter" onClick={onOpen}>
                  <FaFilter className="icon-filter" />
                </button>
                <Menu isOpen={showFilter}>
                  <MenuButton
                    className="filter"
                    onClick={() => setShowFilter(!showFilter)}
                    ref={myRef2}
                  >
                    <FaFilter className="icon-filter" />
                    <span className="conditional">
                      Filtros
                      <TbChevronDown
                        className={
                          showFilter ? "icon-arrow active" : "icon-arrow"
                        }
                      />
                    </span>
                  </MenuButton>
                  <MenuList zIndex={99}>
                    <Filter
                      myRef={myRef}
                      setQtdTime={setQtdTime}
                      qtdTime={qtdTime}
                      qtdTimeError={qtdTimeError}
                      qtdTimeMsgError={qtdTimeMsgError}
                      onClose={() => setShowFilter(false)}
                      handleFilter={handleFilter}
                    />
                  </MenuList>
                </Menu>
              </>
            }
          />
        </div>
        <InfoHeader
          startDate={startDate}
          endDate={endDate}
          periodInfoDiv={
            <div className="period">
              <FaFilter size={20} />
              <p>
                Através do Filtro, você poderá alterar o período da consulta
                informando Data Início e Data Fim.
              </p>
            </div>
          }
        />
        <TableHeader>
        <div className="header-table">
          <h1>Filtrar por Nickname ou ID</h1>
          <input
            type="text"
            placeholder="Inserir Nickname ou ID"
            value={filterNick}
            onChange={(e) => setFilterNick(e.target.value)}
          />
        </div>
        <ButtonExport onClick={downloadFile}>
          <Tooltip
            isOpen={tool}
            hasArrow
            placement='top-start'
            bg="#011032"
            px="12px"
            mb="2px"
            borderRadius='4px'
            maxW='150px'
            label={
              <p
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '10px',
                  lineHeight: '16px',
                  textAlign: 'center'
                }}
              >
                Exportar XLS
              </p>
            }
            >
              <span onMouseEnter={() => setTool(true)} onMouseLeave={() => setTool(false)}>
                <RiFileExcel2Fill size={35} />
              </span>
          </Tooltip>
        </ButtonExport>
        </TableHeader>
        <Wrapper>
          {affiliates.length > 0 && <TableAffiliatesReport affiliates={affiliates} />}
          {affiliates.length > 0 && <MobileTableAffiliatesReport affiliates={affiliates} />}
          {!(affiliates.length > 0) && (
            <div className="no-requests">
              <FiUsers size={100} />
              <span className="empty-text">Sem dados.</span>
            </div>
          )}
          <PaginationBar>
            <Pagination
              total={nPages}
              currentPage={page}
              getSaques={getAffiliates}
            />
          </PaginationBar>
        </Wrapper>

        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay className="performance" />
          <ModalContent
            bgColor="transparent"
            boxShadow="unset"
            justifyContent="center"
            display="grid"
          >
            <OverlayLoading className={showLoading ? "show" : ""}>
              <CircularProgress
                isIndeterminate
                color="#FFD60A"
                trackColor="rgba(1, 16, 50, 0.3)"
              />
            </OverlayLoading>
            <Filter
              myRef={myRef}
              setQtdTime={setQtdTime}
              qtdTime={qtdTime}
              qtdTimeError={qtdTimeError}
              qtdTimeMsgError={qtdTimeMsgError}
              onClose={() => setShowFilter(false)}
              handleFilter={handleFilter}
            />
          </ModalContent>
        </Modal>
      </SubContainer>
    </Container>
  );
};

export default AffiliatesReport;
