import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
      height: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      border-radius: 16px;
      background: #888; 
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    -webkit-font-smoothing: antialiased;
    position: fixed !important;
    height: 100% !important;
    width: 100% !important;

    #root {
      height: 100%;
    }

    &.chakra-ui-light {
      position: fixed !important;
    }
  }

  .chakra-modal__overlay {
    &.performance {
      background: rgba(1, 16, 50, 0.3);
      margin-top: 70px;

      @media (max-width: 768px) {
        margin-top: 50px;
      }
    }
  }

  body, input, textarea, button {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  button {
    cursor: pointer;
  }
`