import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: calc(100vw - 252px);
  justify-content: center;
  overflow-y: auto;

  @media (max-width: 1024px) {
      width: 100%;
  }
`;

export const SubContainer = styled.div`
  max-width: 1320px;
  width: 100%;
  padding: 22px 14px 0px 14px;

  .header {
    @media (max-width: 1024px) {
      margin: -22px -14px 0px -14px;
    }
  }
`