import styled from 'styled-components';
import { colors } from '../../../styles/tokens/color';

export const Container = styled.div`
  min-width: 558px;
  width: 100%;
  margin: 20px 0px 0px 0px;
  background-color: ${colors.white.background};
  border-radius: 16px;
  padding: 12px 18px 16px 18px;
  flex-direction: column;
  box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.12);
  height: fit-content;

  @media (max-width: 1360px) {
    width: 550px;
    min-width: unset;
  }

  @media (max-width: 600px) {
    padding: 16px 16px 12px 16px;
    width: 100%;
  }

  svg {
      height: 23px;
      margin-right: 8px;
      
      path {
        fill: ${colors.primary.blue};
      }
    }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    /* font-size: 14px;
    line-height: 22px; */
    color: #011032;
  }

  .block-p {
    color: ${colors.grey.block};
  }

  .title {
    display: grid;
  }

  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    /* font-size: 14px; */
    line-height: 18px;
    color: ${colors.primary.blue};
    margin-bottom: 6px;
  }

  .block-h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    color: ${colors.grey.block};
    margin: 3px 3px 9px 3px;

    @media (max-width: 600px) {
      margin-bottom: 8px;
    }
  }

  .rectangle {
    width: 90px;
    height: 1px;
    background: ${colors.primary.yellow};
    margin-bottom: 9px;
  }

  .block-rectangle {
    width: 90px;
    height: 1px;
    color: ${colors.grey.block};
    margin-bottom: 9px;

    @media (max-width: 600px) {
      margin-bottom: 9px;
    }
  }

  .point {
    font-size: 10px;
    margin-right: 5px;
  }
`;
