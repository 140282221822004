import styled from "styled-components";

export const Container = styled.div`
  width: 250px;
  min-width: 250px;
  min-height: calc(100vh - 75px);
  border-right: 2px solid #EDEFF2;
  padding: 3px 0px;
  display: flex;
  position: relative;
  z-index: 2;
  flex-direction: column;
  place-content: space-between;
  overflow: hidden;
  transition: width 0.2s;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Buttons = styled.div`
  overflow-y: auto;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  place-content: space-between;
`;