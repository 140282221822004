import styled from 'styled-components'

interface CircularProps {
    color: string;
}

export const CircularC = styled.div<CircularProps>`
    box-sizing: border-box;
    border-radius: 50%;
    width: 39px;
    height: 39px;
    border: 0.75px solid ${props => props.color};
    align-items: center;
    justify-content: center;
    display: flex;
`

export const CircularLgC = styled.div<CircularProps>`
    box-sizing: border-box;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    border: 0.75px solid ${props => props.color};
    align-items: center;
    justify-content: center;
    display: flex;
`

export const CircularSmC = styled.div<CircularProps>`
    box-sizing: border-box;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    border: 0.75px solid ${props => props.color};
    align-items: center;
    justify-content: center;
    display: flex;

    .star {
        min-width: 45px;
    }

    .disabled {
        max-width: 25px;
        margin-right: -1px;
        margin-top: -1px;
    }
`