import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 15px 15px 0 0;
  padding: 10px 10px;
  border-radius: 10px;
  width: 300px;
  height: 350px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 4px rgba(85,102,153,0.11),
    6px 6px 30px rgba(174,195,214,0.29);
`

export const Image = styled.div`
`

export const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: #011032;
`

export const Description = styled.p`
  font-size: 14px;
  text-align: center;
  color: #9EA9B4;
`

export const Button = styled.button`
  background-color: #FFD60A;
  color: #011032;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 6px;
`

export const Modal = styled.div`
`
export const ModalOverlay = styled.div`
`
export const ModalContent = styled.div`
`