import { Container, Text } from './styles';
import React from 'react';
import Plate from '../Plate';
import PlateKit from '../PlateKit';
import Circular from '../Circular/Circular';
import CircularLg from '../Circular/CircularLg';
import CircularSm from '../Circular/CircularSm';

import sonImg from '../../assets/star-plate-son.png';
import siriusImg from '../../assets/star-plate-sirius.png';
import polluxImg from '../../assets/star-plate-pollux.png';
import disabledIMg from '../../assets/star-plate-disabled.png';

interface MyAchievementsProps {
    kit: boolean;
    son: boolean;
    sirius: boolean;
    pollux: boolean;
}

const MyAchievements: React.FC<MyAchievementsProps> = ({ kit, son, sirius, pollux }) => {
    return (
        <Container>
            <h1>
                Minhas Conquistas
            </h1>
            <div className='plates'>
                <PlateKit 
                    size={kit ? 'sm' : 'sm disabled'} 
                    disabled={!kit}
                    content = {
                        <>
                            <p>Kit Estrela</p>
                            <p>R$ 2.000,00</p>
                        </>
                    }
                />
                <Plate 
                    size={son ? 'sm' : 'sm disabled'}
                    content = {
                        <>
                            <CircularSm 
                                color='#E89D58' 
                                enable={son}
                                content={
                                    <img src={son ? sonImg : disabledIMg} alt="son" className={son ? 'star' : 'disabled'}/>
                                }
                            />
                            <Text>
                                <p>PLACA SOL</p>
                                <p>R$ 10.000,00</p>
                            </Text>
                        </>
                    }
                    color={son ? '#E89D58' : '#C4BEB6'}
                />
                <Plate 
                    size={sirius ? 'sm' : 'sm disabled'} 
                    content = {
                        <>
                            <Circular 
                                color='#409CEF' 
                                enable={sirius}
                                content={
                                    <CircularSm 
                                        color='#409CEF'
                                        enable={sirius}
                                        content={
                                            <img src={sirius ? siriusImg : disabledIMg} alt="sirius" className={sirius ? 'star' : 'disabled'}/>
                                        }
                                    />
                                }
                            />
                            <Text className='sirius'>
                                <p>PLACA SÍRIUS</p>
                                <p>R$ 50.000,00</p>
                            </Text>
                        </>
                    }
                    color={sirius ? '#409CEF': '#C4BEB6'}
                />
                <Plate 
                    size={pollux ? 'sm' : 'sm disabled'}
                    content = {
                        <>
                            <CircularLg 
                                color='#FFD60A' 
                                enable={pollux}
                                content={
                                    <Circular 
                                    color='#FFD60A' 
                                    enable={pollux}
                                    content={
                                        <CircularSm 
                                            color='#FFD60A'
                                            enable={pollux}
                                            content={
                                                <img src={pollux ? polluxImg : disabledIMg} alt="pollux" className={pollux ? 'star' : 'disabled'}/>
                                            }
                                        />
                                    }
                                />
                                }
                            />
                            <Text className='pollux'>
                                <p>PLACA POLLUX</p>
                                <p>R$ 100.000,00</p>
                            </Text>
                        </>
                    }
                    color={pollux ? '#FFD60A': '#C4BEB6'}
                />
            </div>
        </Container>
    );
};

export default MyAchievements;