import styled from 'styled-components';

export const WrapScroll = styled.div`
  position: relative;
  border-radius: 16px 16px 0px 0px;
  background-color: #FFF;
  margin-top: 15px;
  flex: 1 1 auto;
  display: none;
  box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.12);
  height: 600px;

  @media (max-width: 575px) {
    display: flex;
  }
`
export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 30px 0;
  flex-direction: column;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Item = styled.div`
  padding: 0 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid #CFCBCB;

  &:last-child {
    margin-bottom: 0;
  }

  .row {
    display: flex;
    place-content: space-between;
    margin-bottom: 40px;
    font-size: 13px;

    .left {
      width: 40%;
    }
    
    .right {
      width: 60%;
      display: flex;
      place-content: end;
      text-align: end;
      
      svg { 
        font-size: 24px;
      }
    }

    .pending {
      color: #FFF;
      border-radius: 12px;
      padding: 0px 20px;
      background: #EFBE24;
    }

    .accepted {
      color: #FFF;
      border-radius: 12px;
      padding: 0px 20px;
      background: #2E7D32;
    }

    .refused {
      color: #FFF;
      border-radius: 12px;
      padding: 0px 20px;
      background: #DE5E5E;
    }
  }
`
