export const colors = {
  primary: {
    yellow: '#FFD60A',
    blue: '#011032'
  },
  dark: {
    purple: '#8E73DC',
    green: '#5EDE99',
    red: '#F26666',
    yellow: '#EFBE24',
    blue: '#0066FF'
  },
  light: {
    purple: '#DBD7F7',
    green: '#E0FFF0',
    red: '#FFE0E0',
    yellow: '#FFFCE0',
    grey: '#F6F7F9',
  },
  white: {
    icon: '#F7F7F7',
    background: '#FFFFFF',
  },
  grey: {
    block: '#E3E3E3',
    disabled: '#C4BEB6',
    grey666: '#666666'
  }
}