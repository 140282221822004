import React, { useContext, FC } from "react";

import { Container } from "./styles";

import { HiOutlineArrowRight } from "react-icons/hi";
import { UserContext } from "context/userContext";
import mixPanelTrack from "mixpanel";

interface BoxWatchProps {}

const BoxWatch: FC<BoxWatchProps> = () => {
  const { userData } = useContext(UserContext);

  const openMotograu = () => {
    window.open("https://stelar.net.br/motograu-estrela-bet-o-jogo-do-momento/", "_blank");
    mixPanelTrack("SCPA - Motograu", {
      user_smartico: userData.smartico_id,
    });
  };

  return (
    <Container>
      <div>
        <h3>Prepare-se para a Campanha do Jogo MOTOGRAU!</h3>
        <div>
          <p>Divulgue e traga novos jogadores para o jogo.</p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <HiOutlineArrowRight />
            <p>
              A campanha de SCPA vai durar 60 dias a partir do dia 21 de Junho de
              2023, então não perca tempo de faturar com o lançamento do Motograu!
            </p>
          </div>
        </div>
      </div>
      <div className="button-div">
        <button onClick={openMotograu}>Quero conhecer o MOTOGRAU</button>
      </div>
    </Container>
  );
};

export default BoxWatch;
