const login = "https://" + process.env.REACT_APP_LOGIN_BACKEND_API_URL + "/api";
const financial = "https://" + process.env.REACT_APP_FINANCIAL_BACKEND_API_URL + "/api";

const endPoints = {
  achievements: financial + "/achievements",
  affiliate_accounts: financial + "/affiliate_accounts",
  affiliates_register: login + "/affiliates_register",
  closures: financial + "/closures",
  invoice: financial + "/invoice",
  login: login + "/oauth/access_token",
  menus: login + "/menus",
  promo_links: financial + "/promo_links",
  starsplayers: login + "/players",
  users: login + "/users",
  withdrawal: financial + "/withdrawal",
  walletlog: financial + "/walletlog",
  vouchers: financial + "/vouchers",
  performance: financial + "/performance_report",
  stelar: financial + "/stelar",
  subaffiliatesreport: financial + "/subaffiliatesreport",
  approval_user: login + "/users/approval_user",
  approval_user_manager: login + "/users/approval_user_manager",
  rake_deals: login + "/users/rake_deals",
  subscribe_integration: financial + "/subscriptions/create_subscription",
  subscription_types: financial + "/subscriptions/get_subscription_types",
  subscription_statuses: financial + "/subscriptions/get_subscription_statuses",
  active_integrations: financial + "/subscriptions/get_active_subscriptions",
  available_integrations:
    financial + "/subscriptions/get_subscriptions_user_didnt_subscribed_yet",
};

export default endPoints;
