import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 145px;
  background: linear-gradient(180deg, #011032 0%, #014C8F 100%);
  box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 6px 6px 30px rgba(174, 195, 214, 0.29);
  border-radius: 14px;
  margin-right: 10px;
  padding: 10px 11px 11px 15px;

  @media (max-width: 1450px) {
    margin-bottom: 15px;
  }

  @media (max-width: 610px) {
    height: fit-content;
    margin-right: 0px;
  }
`

interface ITitle {
  index: boolean;
}

export const Title = styled.p<ITitle>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: #FFFFFF;
  position: relative;
  margin-bottom: 10px;

  @media (max-width: 610px) {
    font-size: 11px;
    line-height: 12px;
  }

  span {
    position: absolute;
    left: 542px;
    z-index: ${props => props.index ? 1 : 0};

    @media (max-width: 610px) {
      left: 438px;
    }

    @media (max-width: 515px) {
      left: 358px;
    }

    @media (max-width: 430px) {
      left: 245px;
    }
  }
`

export const Number = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 46px;
  color: #FFFFFF;
  margin-top: -10px;

  @media (max-width: 610px) {
    font-size: 20px;
    line-height: 40px;
  }
`

export const Divider = styled.div`
  border-top: 1px solid #202D4B;
  width: 585px;
  height: 0px;
  margin-left: -15px;
  margin-top: 20px;
  margin-bottom: 2px;

  @media (max-width: 610px) {
    width: 480px;
    margin-top: 8px;
    margin-bottom: 8px;
    height: fit-content;
  }

  @media (max-width: 515px) {
    width: 400px;
  }

  @media (max-width: 430px) {
    width: 287px;
  }
`

export const Infos = styled.div`
  width: 100%;
  display: flex;
`

export const Info = styled.div`
  display: grid;
  margin-left: 4px;
  margin-top: 6px;

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    color: #ABABAB;

    @media (max-width: 610px) {
      font-size: 10px;
      line-height: 14px;
    }
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #FFFFFF;

    @media (max-width: 610px) {
      font-size: 15px;
      line-height: 19px;
    }
  }
`

interface IGroup {
  color: string
}

export const Group = styled.div<IGroup>`
  display: flex;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${props => props.color};
  align-items: flex-end;

  p {
    margin-left: 9px;
    margin-right: 10px;
  }

  svg {
    margin-bottom: 2.5px;
  }
`

export const Text = styled.div`
  display: flex;
  margin-right: auto;
`

export const Point = styled.p`
  color: #FFD60A;
`

export const Flex = styled.div`
  display: flex;
  position: relative;

  img {
    position: absolute;
    margin-left: auto;
    height: 85px;
    right: -13px;
    top: -10px;

    @media (max-width: 610px) {
      display: none;
    }
  }
`

export const Grid = styled.div`
  display: grid;
`