import React, { ReactNode } from 'react';

import { Container } from './styles';

interface WithdrawBoxInfoProps {
  title: string,
  content: ReactNode,
  block?: boolean
}

const WithdrawBoxInfo: React.FC<WithdrawBoxInfoProps> = ({ title, content, block }) => {
  return (
    <Container>
      <div className='title'>
        <h1 className={block ? 'block-h1' : ''}>{title}</h1>
        <div className={block ? 'block-rectangle' : 'rectangle'} />
      </div>
      {content}
    </Container>
  );
}

export default WithdrawBoxInfo;