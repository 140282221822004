import React from 'react';

import { PaginationItem } from './styles';

interface PaginationProps {
    currentPage: number;
    total: number;
    getSaques: (e: number ) => void;
}

const Pagination: React.FC<PaginationProps> = ({ total, currentPage, getSaques }) => {

    if (total < 8) {
        return (
            <>  
                {
                    Array.from({length: total}, (_, index) => (
                        <PaginationItem onClick={() => { getSaques(index + 1)}} className={currentPage === (index + 1) ? 'active' : ''} key={index}>
                            {index + 1}
                        </PaginationItem>
                    ))
                }
            </>
        ); 
    } else if (currentPage < 5) {
        return (
            <>
                <PaginationItem onClick={() => { getSaques(1)}} className={currentPage === 1 ? 'active' : ''}> 
                    1
                </PaginationItem>
                <PaginationItem onClick={() => { getSaques(2)}} className={currentPage === 2 ? 'active' : ''}> 
                    2
                </PaginationItem>
                <PaginationItem onClick={() => { getSaques(3)}} className={currentPage === 3 ? 'active' : ''}> 
                    3
                </PaginationItem>
                <PaginationItem onClick={() => { getSaques(4)}} className={currentPage === 4 ? 'active' : ''}> 
                    4
                </PaginationItem>
                <PaginationItem onClick={() => { getSaques(5)}}> 
                    5
                </PaginationItem>
                <PaginationItem className='disabled'> 
                    •••
                </PaginationItem> 
                <PaginationItem onClick={() => { getSaques(total)}}> 
                    {total}
                </PaginationItem>
            </>
        ); 
    } else if (currentPage > total - 4) {
        return (
            <>
                <PaginationItem onClick={() => { getSaques(1)}}> 
                    1
                </PaginationItem>
                <PaginationItem className='disabled'> 
                    •••
                </PaginationItem>
                <PaginationItem onClick={() => { getSaques(total - 4)}} className={currentPage === (total - 4) ? 'active' : ''}> 
                    {total - 4}
                </PaginationItem>
                <PaginationItem onClick={() => { getSaques(total - 3)}} className={currentPage === (total - 3) ? 'active' : ''}> 
                    {total - 3}
                </PaginationItem>
                <PaginationItem onClick={() => { getSaques(total - 2)}} className={currentPage === (total - 2) ? 'active' : ''}> 
                    {total - 2}
                </PaginationItem>
                <PaginationItem onClick={() => { getSaques(total - 1)}} className={currentPage === (total - 1) ? 'active' : ''}> 
                    {total - 1}
                </PaginationItem>
                <PaginationItem onClick={() => { getSaques(total)}} className={currentPage === total ? 'active' : ''}> 
                    {total}
                </PaginationItem>
            </>
        ); 
    } else {
        return (
            <>
                <PaginationItem onClick={() => { getSaques(1)}}> 
                    1
                </PaginationItem>
                <PaginationItem className='disabled'> 
                    •••
                </PaginationItem>
                <PaginationItem onClick={() => { getSaques(currentPage - 1)}}> 
                    {currentPage - 1}
                </PaginationItem>
                <PaginationItem className='active'> 
                    {currentPage}
                </PaginationItem>
                <PaginationItem onClick={() => { getSaques(currentPage + 1)}}> 
                    {currentPage + 1}
                </PaginationItem>
                <PaginationItem className='disabled'> 
                    •••
                </PaginationItem>
                <PaginationItem onClick={() => { getSaques(total)}}> 
                    {total}
                </PaginationItem>
            </>
        ); 
    }
}

export default Pagination;