import styled from 'styled-components';
import { colors } from '../../styles/tokens/color';

export const Container = styled.div`
  width: 384px;
  margin: 38px;
  background-color: #FFFFFF;
  border-radius: 16px;
  padding: 35px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;

  @media (max-width: 500px) {
    margin: 30px;
  }

  @media (max-width: 400px) {
    padding: 30px 20px;
  }

  @media (max-width: 350px) {
    margin: 20px;
  }

  h1 {
    margin-bottom: 33px;
    font-weight: 700;
    font-size: 15px;
    /* font-size: 16px; */
    line-height: 24px;
    color: ${colors.primary.blue};

    @media (max-width: 350px) {
      margin-bottom: 28px;
      font-size: 18px;
    }
  }

  img {
    width: 195px;
    margin-bottom: 10px;
  }

  .submit-button {
    width: 100%;
    height: 40px;
    margin: 23px 0;
    background: #FFD60A;
    border: 1px solid #FFD60A;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.primary.blue};
    transition: all 0.2s;
    text-align: center;
    
    &.redeem {
      text-align: center;
    }

    &:hover {
      background: ${colors.dark.yellow};
    }

    @media (max-width: 400px) {
      margin-top: 10px;
      margin-bottom: 15px;
      height: 50px;
    }
  }

  .links-text {
    display: flex;
    align-items: center;

    svg {
      cursor: pointer;
      font-size: 20px;
      margin-right: 4px;
    }

    p {
      margin-bottom: 0;
    }
  }

  p {
    color: ${colors.primary.blue};
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 6px;

    @media (max-width: 350px) {
      font-size: 14px;
    }

    a {
      cursor: pointer;
      font-weight: 700;
      transition: all 0.2s;

      &:hover {
        color: #4465b3;
      }

      @media (max-width: 350px) {
        font-size: 14px;
      }
    }
  }
`;
