import React, { useState, useEffect } from 'react';
import { Container, AwardContent, Info, PlateContent, Lists, List, Item, Icon, Circular, CircularLg, CircularSm, Overlay, SubContainer } from './styles';
import HeaderComponent from '../../components/HeaderComponent';
import MyAchievements from '../../components/MyAchievements';
import Plate from '../../components/Plate';
import PlateKit from '../../components/PlateKit';
import ResultContainer from '../../components/ResultContainer';
import RequestModal from '../../components/RequestModal'

import { TbTargetArrow } from 'react-icons/tb';
import { GiTakeMyMoney } from 'react-icons/gi';
import { BsCalendar } from 'react-icons/bs';
import { MdListAlt } from 'react-icons/md';

import noReload from '../../assets/no-reload.png';
import sendCancel from '../../assets/send-cancel.png';
import starPollux from '../../assets/star-plate-pollux-award.png';
import starSirius from '../../assets/star-plate-sirius-award.png';
import starSon from '../../assets/star-plate-son-award.png';

import { Modal, ModalContent, useToast } from '@chakra-ui/react'

import axios from 'axios';
import endPoints from '../../utils/endPoints';

interface AchievementProps {
    id: number;
    type: string;
    amount: number;
    status: string;
    commission_reached: number;
    message: string;
    reason_id: number;
}

interface AchievementResponse {
    affiliate_id: number;
    nick: string;
    achievements: AchievementProps[];
    last_week_comission: number;
}


const Award: React.FC = () => {
    const [kit, setKit] = useState(false);
    const [son, setSon] = useState(false);
    const [sirius, setSirius] = useState(false);
    const [pollux, setPollux] = useState(false);
    const [showAward, setShowAward] = useState<string>('');
    const [goalValue, setGoalValue] = useState<number>(0);
    const [currentValue, setCurrentValue] = useState<number>(0);
    const [color, setColor] = useState<string>('#F5F5F5');
    const [conquered, setConquered] = useState<boolean>(false);
    const [claimed, setClaimed] = useState<boolean>(false);
    const [showRequest, setShowRequest] = useState<boolean>(false);
    const [achievement, setAchievement] = useState<AchievementResponse>({} as AchievementResponse);
    const [requesterId , setRequesterId] = useState<number>(0);
    const [statusItem, setStatusItem] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [reasonId, setReasonId] = useState<number>(0);
    const { achievements } = endPoints;
    const toast = useToast();

    const achievementTypes = [
        { name: "Kit Estrela", stateSetter: setKit, param: 'kit' },
        { name: "Placa Sol", stateSetter: setSon, param: 'son', color: '#E89D58' },
        { name: "Placa Sirius", stateSetter: setSirius, param: 'sirius', color: '#409CEF' },
        { name: "Placa Pollux", stateSetter: setPollux, param: 'pollux', color: '#FFD60A' },
    ];

    const getTitle = () => {
        switch(showAward) {
            case 'kit':
                return 'Kit Estrela';
            case 'son':
                return 'PLACA SOL';
            case 'sirius':
                return 'PLACA SÍRIUS';
            case 'pollux':
                return 'PLACA POLLUX';
            default:
                return '';
        }
    }

    const getValue = () => {
        switch(showAward) {
            case 'kit':
                return 'R$ 2.000,00';
            case 'son':
                return 'R$ 10.000,00';
            case 'sirius':
                return 'R$ 50.000,00';
            case 'pollux':
                return 'R$ 100.000,00';
            default:
                return '';
        }
    }

    const getAchievements = async () => {
        const url = `${achievements}`;

        axios.get(url).then(response => {
            setAchievement(response.data)
        }).catch(error => {
            toast({
                title: 'Erro ao buscar os dados',
                status: 'error',
                position: 'top',
                isClosable: true,
            })
            console.log(error);
        });
    }

    useEffect(() => {
        getAchievements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!achievement.achievements) {
            return;
        }
      
        achievementTypes.forEach(({ name, stateSetter, param, color }) => {
            const achievementItem = achievement.achievements.find(a => a.type === name);
            if (param === showAward) {
                setGoalValue(achievementItem?.amount || 0)
                setColor(color || '#F5F5F5')
                setRequesterId(achievementItem?.id || 0)
                setStatusItem(achievementItem?.status || 'NOT_ACHIEVED');
                setMessage(achievementItem?.message || '');
                setReasonId(achievementItem?.reason_id || 0);
            }

            if (achievementItem?.status !== 'NOT_ACHIEVED') {
                stateSetter(true);
            } 
            
            if (achievementItem?.status === "CLAIMED") {
                if (param === showAward) {
                    setCurrentValue(achievementItem.commission_reached);
                    setClaimed(true);
                }
            } else if (achievementItem?.status === "ACHIEVED" && param === showAward) {
                setCurrentValue(achievementItem.commission_reached);
                setConquered(true);
            } else if (param === showAward) {
                setCurrentValue(achievement.last_week_comission || 0);
                setConquered(false);
                setClaimed(false);
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [achievement]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setShowAward( urlParams.get('plate') || '' );
    }, []);

    return (
        <Container>
            <SubContainer>
                <Overlay className={showRequest ? 'show' : ''}/>
                <div className='header'>
                    <HeaderComponent
                        title="PREMIAÇÕES"
                    />
                </div>
                <MyAchievements 
                    kit={kit}
                    son={son}
                    sirius={sirius}
                    pollux={pollux}
                />
                <AwardContent>
                    <h1>
                        { getTitle() }
                    </h1>
                    <PlateContent>
                        <p className='mobile-title'>
                            Para alcançar a premiação desejada, veja as informações abaixo:
                        </p>
                        {
                            // if showAward === 'kit' platekit else plate 
                            showAward === 'kit' ? (
                                <PlateKit
                                    size='award'
                                    content={
                                        <h4>KIT ESTRELA</h4>
                                    }
                                />
                            ) : (
                                <Plate 
                                    size='award'
                                    content={
                                        <>
                                            <CircularLg color={showAward === 'pollux' ? color : 'transparent'}>
                                                <Circular color={showAward === 'sirius' || showAward === 'pollux' ? color : 'transparent'}>
                                                    <CircularSm color={color}>
                                                        {
                                                            showAward === 'son' ? (
                                                                <img src={starSon} alt="star-plate"/>
                                                            ) : showAward === 'sirius' ? (
                                                                <img src={starSirius} alt="star-plate"/>
                                                            ) : showAward === 'pollux' ? (
                                                                <img src={starPollux} alt="star-plate"/>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                    </CircularSm>
                                                </Circular>
                                            </CircularLg>
                                            <h2>{getTitle()}</h2>
                                        </>
                                    }
                                    color='#E89D58'
                                />
                            )
                        }
                        <Info>
                            <p className='web-title'>
                                Para alcançar a premiação desejada, veja as informações abaixo:
                            </p>
                            <Lists>
                                <List>
                                    <Item>
                                        <Icon>
                                            <TbTargetArrow size={30}/>
                                        </Icon>
                                        <p>Para conquistar a premiação desejada basta <span>alcançar a meta</span></p>
                                    </Item>
                                    <Item>
                                        <Icon>
                                            <GiTakeMyMoney size={30}/>
                                        </Icon>
                                        <p>A meta da premição é de <span className='value'>{getValue()}</span><span> em comissão semanal.</span></p>
                                    </Item>
                                    <Item>
                                        <Icon>
                                            <BsCalendar size={25}/>
                                        </Icon>
                                        <p>O resultado do Afiliado é a comissão da última semana, que será atualizada <span>toda segunda-feira.</span></p>
                                    </Item>
                                </List>
                                <List>
                                    <Item>
                                        <Icon>
                                            <img src={sendCancel} alt='send-cancel' className='img-send'/>
                                        </Icon>
                                        <p>Uma vez recebido o prêmio, <span>não</span> é possível ser enviada a premiação novamente para o Afiliado.</p>
                                    </Item>
                                    <Item>
                                        <Icon>
                                            <img src={noReload} alt='no-reload' className='img-reload'/>
                                        </Icon>
                                        <p>Essa é uma <span>premiação não acumulativa</span>, ou seja, caso o Afiliado não alcance a meta da premiação seus valores são zerados para a próxima semana.</p>
                                    </Item>
                                    <Item>
                                        <Icon>
                                            <MdListAlt size={30}/>
                                        </Icon>
                                        <p>Na hipótese do Afiliado ter alcançado a meta, ele estará apto a <span>solicitar seu prêmio através de um formulário</span>.</p>
                                    </Item>
                                </List>
                            </Lists>
                        </Info>
                    </PlateContent>
                    <ResultContainer showAward={showAward} currentValue={currentValue} goalValue={goalValue} conquered={conquered}  claimed={claimed} classConditional='web' showModal={() => setShowRequest(true)} status={statusItem} reasonId={reasonId} message={message}/>
                </AwardContent>
                <ResultContainer showAward={showAward} currentValue={currentValue} goalValue={goalValue} conquered={conquered} claimed={claimed} classConditional='mobile' showModal={() => setShowRequest(true)} status={statusItem} reasonId={reasonId} message={message}/>
                <Modal isOpen={showRequest} onClose={() => setShowRequest(false)}  isCentered>
                    <ModalContent bgColor='transparent' boxShadow='unset' justifyContent='center' display='grid' alignContent='center'>
                        <RequestModal onClose={() => setShowRequest(false)} requesterId={requesterId} refresh={() => getAchievements()}/>
                    </ModalContent>
                </Modal>
            </SubContainer>
        </Container>
    );
}
  
export default Award;