import React from 'react';

import HeaderComponent from '../../components/HeaderComponent';
import ApiStelarBox from '../../components/ApiStelarBox';

import { Container, SubContainer } from './styles';

const ApiStelar: React.FC = () => {
  return (
    <Container>
      <SubContainer>
        <div className='header'>
          <HeaderComponent
            title="INTEGRAÇÕES | API Stelar"
          />
        </div>
        <ApiStelarBox />
      </SubContainer>
    </Container>
  )
}

export default ApiStelar;