import styled from 'styled-components';
import { colors } from '../../styles/tokens/color'

export const Container = styled.div`
  min-height: 100vh;
  background-color: ${colors.primary.blue};
  display: flex;
  align-items: center;
  place-content: center;
`;
