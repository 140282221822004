import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: calc(100vw - 252px);
  justify-content: center;
  overflow-y: auto;

  @media (max-width: 1024px) {
      width: 100%;
  }
`

export const SubContainer = styled.div`
  max-width: 1320px;
  width: 100%;
  padding: 22px 14px 0px 14px;
`

export const Content = styled.div`
  @media (max-width: 1450px) {
    display: grid;
    justify-content: center;
  }
`

export const Header = styled.div`
  justify-content: space-between;
  display: flex;
  position: relative;

  @media (max-width: 1024px) {
    margin: -22px -14px 0px -14px;
  }

  .chakra-menu__menu-list {
    @media (max-width: 1024px) {
      position: absolute;
      left: 35vw;
      top: 20vh;
    }

    @media (max-width: 924px) {
      left: 30vw;
    }

    @media (max-width: 824px) {
      left: 29vw;
    }

    @media (max-width: 724px) {
      left: 26vw;
    }

    @media (max-width: 624px) {
      left: 22vw;
    }

    @media (max-width: 560px) {
      left: 19vw;
    }

    @media (max-width: 500px) {
      left: 15vw;
    }

    @media (max-width: 440px) {
      left: 9.5vw;
    }
  }
`

export const Cards = styled.div`
  display: flex;
  margin-top: 19px;

  @media (max-width: 1450px) {
    flex-direction: column;
  }

  @media (max-width: 610px) {
    align-items: center;

    &:nth-child(2) {
      margin-top: 0;
    }
  }
`

export const Overlay = styled.div`
  &.show {
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 16, 50, 0.3);
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

    transition: color 0.2s;
  }
`

export const OverlayLoading = styled.div`
  display: none;

  &.show {
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 16, 50, 0.3);
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    transition: color 0.2s;
  }
`

export const Double = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 768px) {
    width: 100%;
    grid-template-columns: 1fr;
  }
`

export const InfoCard = styled.div`
  background: #FFFFFF;
  box-shadow: 2px 2px 4px rgba(85, 102, 153, 0.11), 6px 6px 30px rgba(174, 195, 214, 0.29);
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 15px;
  width: 100%;
  margin-top: 19px;

  @media (max-width: 610px) {
    margin-bottom: 15px;
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    @media (max-width: 515px) {
      font-size: 11px;
    }
  }

  .info-content {
    background: #F7F7F7;
    border-radius: 16px;
    width: 100%;
    padding: 20px 34px;
    display: flex;
    align-items: center;

    @media (max-width: 1450px) {
      display: grid;
    }

    @media (max-width: 610px) {
      padding: 15px;
    }

    @media (max-width: 515px) {
      padding: 10px;
    }
  }

  .periods-div {
    display: grid;
    margin-right: 60px;
  }

  .filter-div {
    display: flex;
    align-items: center;

    @media (max-width: 1450px) {
      align-items: flex-start;
      margin-top: 10px;
    }

    .icon-container {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      margin-right: 22.5px;

      @media (max-width: 1450px) {
        width: fit-content;
        height: fit-content;
        background: unset;
        margin-right: 5px;
      }

      svg {
        min-height: 22px;
        min-width: 22px;

        @media (max-width: 1450px) {
          min-height: unset;
          min-width: unset;
          margin-top: 3px;
        }
      }
    }
  }

  .period {
    display: flex;
    align-items: center;

    &:last-child {
      margin-top: 7px;
    }

    svg {
      margin-right: 7px;

      @media (max-width: 515px) {
        min-height: 15px;
        min-width: 15px;
      }
    }
  }
`
