import styled from 'styled-components';

export const Container = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  margin-top: 10px;
  padding: 15px 22px 20px 22px;
  min-width: 260px;
  @media (max-width: 1500px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .watchs {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    @media (max-width: 500px) {
      margin-top: 15px;
    }
  }
  h3 {
    @media (max-width: 500px) {
      margin-left: 16px;
    }
  }
`

export const Watch = styled.div`
  width: 116px;
  height: 116px;
  border: 6px solid #FFD60A;
  margin-right: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:last-child {
    margin-right: 0px;
  }
  &.seconds {
    @media (max-width: 500px) {
      display: none;
    }
  }
  @media (max-width: 768px) {
    width: 110px;
    height: 110px;
  }
  @media (max-width: 600px) {
    width: 90px;
    height: 90px;
    border: 4px solid #FFD60A;
    margin-top: 10px;
    margin-right: 15px;
  }
  
  @media (max-width: 500px) {
    width: 80px;
    height: 80px;
    margin-right: 8px;
    margin-top: 0px;
  }
  @media (max-width: 360px) {
    width: 65px;
    height: 65px;
    border: 3px solid #FFD60A;
    margin-right: 4px;
  }
  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 37px;
    color: #011032;
    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 33px;
    }
    @media (max-width: 600px) {
      font-size: 26px;
      line-height: 28px;
    }
    @media (max-width: 500px) {
      font-size: 20px;
      line-height: 22px;
    }
    @media (max-width: 360px) {
      font-size: 17px;
      line-height: 17px;
    }
  }
  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #6B6B72;
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 14px;
    }
    @media (max-width: 600px) {
      font-size: 12px;
      line-height: 11px;
    }
    @media (max-width: 500px) {
      font-size: 12px;
      line-height: 14px;
    }
    @media (max-width: 360px) {
      font-size: 10px;
      line-height: 12px;
    }
  }
`