import React from "react";

import { Affiliates } from "../TableAffiliatesManager";

import { WrapScroll, Container, Item } from "./styles";

interface MobileTableAffiliatesReportProps {
  affiliates: Affiliates[];
}

const MobileTableAffiliatesReport: React.FC<MobileTableAffiliatesReportProps> = ({
  affiliates,
}) => {
  const getPercentage = (value: any) => {
    if (value) {
      return value + '%';
    }
    return "N/A";
  };

  const getValue = (value: any) => {
    if (value) {
      return value;
    }
    return "N/A";
  };

  const getMoney = (value: number) => {
    if (value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    }
    return "N/A";
  };

  return (
    <WrapScroll>
      <Container>
        {affiliates.map((affiliate) => (
          <Item key={affiliate.AffiliateId}>
            <div className="row">
              <span className="left">ID Afiliado</span>
              <span className="right">{getValue(affiliate.AffiliateId)}</span>
            </div>
            <div className="row">
              <span className="left">Nickname</span>
              <span className="right">{getValue(affiliate.AffiliateName)}</span>
            </div>
            <div className="row">
              <span className="left">Visitas</span>
              <span className="right">{getValue(affiliate.VisitCount)}</span>
            </div>
            <div className="row">
              <span className="left">Registros</span>
              <span className="right">{getValue(affiliate.RegistrationCount)}</span>
            </div>
            <div className="row">
              <span className="left">RevShare</span>
              <span className="right">{getPercentage(affiliate.RevShare)}</span>
            </div>
            <div className="row">
              <span className="left">CPA</span>
              <span className="right">{getValue(affiliate.Cpa)}</span>
            </div>
            <div className="row">
              <span className="left">FTDs</span>
              <span className="right">{getValue(affiliate.Ftds)}</span>
            </div>
            <div className="row">
              <span className="left">FTDs Amount</span>
              <span className="right">{getValue(affiliate.FtdsAmount)}</span>
            </div>
            <div className="row">
              <span className="left">QFTDs, CPA</span>
              <span className="right">{getValue(affiliate.QftdsCpa)}</span>
            </div>
            <div className="row">
              <span className="left">Comissão</span>
              <span className="right">{getMoney(affiliate.ComissionsTotal)}</span>
            </div>
          </Item>
        ))}
      </Container>
    </WrapScroll>
  );
};

export default MobileTableAffiliatesReport;
